import { Typography, styled } from "@mui/material";
import Colors from "../../utils/ColorConstants";

export const Value = styled(Typography)`
  color: ${Colors.DEFAULT};
  font-size: 14px;
  font-family: Manrope;
  font-weight: 400;
  text-decoration: none;
  line-height: 19.2px;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: break-spaces;
`;
export const PreviousValue = styled(Value)`
  color: ${Colors.LIGHT2};
  text-decoration-line: line-through;
  background-position: 0 6px;
`;

export const AfterValue = styled(Value)``;
