import { useEffect, useState } from "react";
import { ConvertedMetadata } from "../gql/graphql";
const IMAGE_TIMEOUT_ATTEMPTS = 5 * 20;

export const useImage = (
  obj:
    | {
        content_type?: string | null;
        presigned_url?: string | null;
        image_presigned_url?: string | null;
        converted_metadata?: ConvertedMetadata;
      }
    | null
    | undefined,
) => {
  const [loadingThumbnail, setLoadingThumbnail] = useState<boolean>(false);
  const [thumbnail, setThumbnail] = useState<string | undefined>(undefined);

  useEffect(() => {
    async function run() {
      const url = obj?.image_presigned_url ?? obj?.presigned_url;
      if (obj?.converted_metadata?.toImage === false) {
        setLoadingThumbnail(false);
        return;
      }
      if (
        obj?.content_type &&
        !obj.content_type.startsWith("image/") &&
        !obj.content_type.startsWith("application/")
      ) {
        setLoadingThumbnail(false);
        return;
      }
      if (url === null && thumbnail !== undefined) {
        setThumbnail(undefined);
        setLoadingThumbnail(false);
        return;
      }
      if (!url || url === thumbnail) {
        setLoadingThumbnail(false);
        return;
      }
      setThumbnail(undefined);
      setLoadingThumbnail(true);
      let validUrl = false;
      let attempts = 0;
      do {
        if (attempts >= IMAGE_TIMEOUT_ATTEMPTS) {
          setLoadingThumbnail(false);
          return;
        }
        await new Promise((r) => setTimeout(r, 200));
        try {
          const response = await fetch(url, {
            referrerPolicy: "origin-when-cross-origin",
          });
          attempts++;
          if (response.ok) {
            validUrl = true;
          } else if (response.status === 0) {
            setLoadingThumbnail(false);
            return;
          }
        } catch (e) {
          attempts++;
          continue;
        }
      } while (!validUrl);

      setThumbnail(url);
      setLoadingThumbnail(false);
    }
    const timer = setTimeout(run, 20);
    return () => clearTimeout(timer);
  }, [obj, thumbnail]);

  return {
    loadingThumbnail,
    thumbnail,
  };
};
