import { styled } from "@mui/material";

export const ContainerEmpty = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
`;
