import { Link } from "react-router-dom";
import { useSidebarDesktop } from "../../../context/SidebarDesktopContext";
import {
  ContainerStyle,
  SidebarDesktopStyledImage,
  SidebarDesktopToggleButton,
} from "./styles";
import { useState } from "react";

type HeaderDesktopProps = {
  isCollapsed: boolean;
};

function Header({ isCollapsed }: HeaderDesktopProps) {
  const { handleToggleSidebar } = useSidebarDesktop();
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={{
        marginTop: "1rem",
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isCollapsed ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isHovered ? (
            <SidebarDesktopToggleButton
              sx={{
                height: "30px",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => handleToggleSidebar()}
            >
              <img
                style={{
                  fontSize: "1rem",
                  color: "white",
                  width: "20px",
                  height: "20px",
                }}
                src="/collapse-sidebar.svg"
              />
            </SidebarDesktopToggleButton>
          ) : (
            <Link
              to="/home"
              reloadDocument={false}
              style={{
                width: "100%",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <SidebarDesktopStyledImage
                src={"/logo/icon-logo-white.png"}
                sx={{
                  width: "20px",
                  height: "10px",
                  cursor: "pointer",
                }}
                alt="Wrapt logo"
              />
            </Link>
          )}
        </div>
      ) : (
        <ContainerStyle>
          <div
            style={{
              width: "100%",
              height: "30px",
              minWidth: "90px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Link
              to="/home"
              reloadDocument={false}
              style={{
                width: "80%",
                height: "30px",
                minWidth: "90px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <SidebarDesktopStyledImage
                src={"/logo/logo-negative-white.png"}
                sx={{
                  width: "100px",
                  height: "20px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                alt="Wrapt logo"
              />
            </Link>
            {isHovered && (
              <SidebarDesktopToggleButton
                sx={{
                  marginRight: "10px",
                  height: "30px",
                }}
                onClick={() => handleToggleSidebar()}
              >
                <img
                  style={{
                    fontSize: "1rem",
                    color: "white",
                    width: "20px",
                    height: "20px",
                  }}
                  src="/collapse-sidebar.svg"
                />
              </SidebarDesktopToggleButton>
            )}
          </div>
        </ContainerStyle>
      )}
    </div>
  );
}

export default Header;
