import i18n from "./i18n";
import { AuthenticatedRoutes } from "./Router";
import { ExportProvider } from "./context/Exports";
import { SidebarDesktopProvider } from "./context/SidebarDesktopContext";
import { MuiThemeProvider } from "./context/ThemeProvider";

import { I18nextProvider } from "react-i18next";

function App() {
  return (
    <MuiThemeProvider>
      <ExportProvider>
        <SidebarDesktopProvider>
          <I18nextProvider i18n={i18n}>
            <AuthenticatedRoutes />
          </I18nextProvider>
        </SidebarDesktopProvider>
      </ExportProvider>
    </MuiThemeProvider>
  );
}

export default App;
