import React, { useCallback, useEffect, useMemo, useState } from "react";

import { useAutocomplete } from "@mui/base/useAutocomplete";

import {
  InputWrapper,
  Listbox,
  ListItemAction,
  Root,
  StyledInput,
} from "./styles";
import { setRef, SxProps, Theme } from "@mui/material";
import { TagsContainerWrapper } from "./tag-container";
import { TagType } from "./tag";

export type AutocompleteNewValue = {
  label: string;
};

type AutocompleteBaseProps<T, V, D> = {
  value: D;
  defaultValue?: V;
  options: Array<T>;
  iconMap?: Record<string, React.ReactNode>;
  getOptionLabel: (option: T) => string;
  placeholder?: string;
  handleOnChange: React.Dispatch<D>;
  onCreateNewValue?: (
    value: string,
  ) => Promise<T | undefined | null> | T | undefined | null;
  newValuesLabel?: string;
  isLoading?: boolean;
  noBorder?: boolean;
  inputWrapper?: boolean;
  tagType?: TagType;
  thumbnail_key?: T extends Record<string, unknown> ? keyof T : never;
  vertical?: boolean;
  isDisabled?: boolean;
  disableBlurOnSelect?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  disableRender?: boolean;
  listBoxRef?: React.Ref<HTMLUListElement>;
  maxSize?: number;
  tagSx?: SxProps<Theme>;
  tagsContainerSx?: SxProps<Theme>;
  className?: string;
  readOnly?: boolean;
};
export type AutocompleteProps<T> =
  | (AutocompleteBaseProps<T, Array<T>, Array<T>> & {
      onlyOne?: false;
    })
  | (AutocompleteBaseProps<T, T, T | null> & {
      onlyOne: true;
    });
const toArray = <T,>(value: T | T[] | null | undefined): T[] =>
  !!value && !Array.isArray(value) ? [value] : (value as T[] | null) ?? [];

export const AutocompleteMultiple = React.forwardRef(
  <T,>(
    { tagType = "tag", ...props }: AutocompleteProps<T>,
    ref: React.Ref<HTMLElement> | null,
  ) => {
    const [options, setOptions] = useState(props.options);
    useEffect(() => {
      setOptions(props.options);
    }, [props.options]);
    const [propValue, defaultValue] = useMemo(
      () => [toArray(props.value), toArray(props.defaultValue)],
      [props.value, props.defaultValue],
    );

    const {
      getRootProps,
      getInputProps,
      getTagProps,
      getListboxProps,
      getOptionProps,
      groupedOptions,
      value,
      focused,
      setAnchorEl,
      inputValue,
    } = useAutocomplete({
      multiple: true,
      openOnFocus: true,
      selectOnFocus: true,
      handleHomeEndKeys: true,
      autoHighlight: true,
      blurOnSelect: !props.disableBlurOnSelect,
      ...props,
      value: propValue,
      defaultValue,
      onChange: (_, newValue) => {
        if (props.onlyOne) {
          (props.handleOnChange as React.Dispatch<T | null>)(
            ((newValue ?? []) as T[]).at(-1) ?? null,
          );
        } else {
          (props.handleOnChange as React.Dispatch<T[]>)(newValue as T[]);
        }
      },
      options,
    });

    const handleKeyDown = async (
      event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
      if (event.key === "Enter") {
        event.preventDefault();
        if (
          props.onCreateNewValue &&
          inputValue.trim().length > 0 &&
          groupedOptions.length === 0
        ) {
          if (value.find((v) => props.getOptionLabel(v) === inputValue)) {
            event.currentTarget.value = "";
            return;
          }
          const newValue = await props.onCreateNewValue(inputValue);
          if (!newValue) return;
          if (props.onlyOne) {
            (
              props.handleOnChange as React.Dispatch<
                AutocompleteProps<T>["value"]
              >
            )(newValue);
          } else {
            (
              props.handleOnChange as React.Dispatch<
                AutocompleteProps<T>["value"]
              >
            )([...value, newValue]);
          }
          if (event.currentTarget) event.currentTarget.value = "";
        }
      }
    };
    const maxSize = props.maxSize ?? 3;

    const getLabel = useCallback(
      (option: T | undefined | null) => {
        if (!option) return null;
        if (typeof option === "string" && !option.length) return null;
        const label = props.getOptionLabel(option);
        const icon = props.iconMap?.[label] ?? null;

        return {
          value: option,
          label,
          icon,
        };
      },
      [props],
    );
    const inputProps = getInputProps();
    const lbProps = getListboxProps();
    const listBoxProps = {
      ...lbProps,
      ref: (el: HTMLUListElement | null) => {
        if (props.listBoxRef) setRef(props.listBoxRef, el);
        if ("ref" in lbProps)
          setRef(lbProps.ref as React.Ref<HTMLUListElement>, el);
      },
    };
    const height = props.disableRender ? "0px" : undefined;

    // TODO: use the loading prop to show a loading indicator
    return (
      <Root
        sx={{
          height,
        }}
      >
        <div
          {...getRootProps()}
          style={{
            height,
          }}
        >
          <InputWrapper
            removePadding={props.inputWrapper}
            ref={setAnchorEl}
            className={
              (focused ? "focused" : "") + " " + (props.className ?? "")
            }
            noBorder={props.noBorder}
            sx={props.tagsContainerSx}
            height={height}
          >
            {value.length > 0 && (
              <TagsContainerWrapper
                height={height}
                inputWrapper={props.inputWrapper}
                tagType={tagType}
                getLabel={getLabel}
                getTagProps={getTagProps}
                thumbnail_key={props.thumbnail_key}
                focused={focused}
                maxSize={maxSize}
                value={value}
                tagSx={props.tagSx}
                tagsContainerSx={props.tagsContainerSx}
              />
            )}
            <StyledInput
              readOnly={props.readOnly || props.isLoading}
              onKeyDown={handleKeyDown}
              {...inputProps}
              sx={{
                height,
              }}
              ref={(el) => {
                setRef(ref, el);
                setRef(inputProps.ref, el);
              }}
              onBlur={(e) => {
                inputProps.onBlur?.(e);
                props.onBlur?.();
              }}
              onFocus={(e) => {
                inputProps.onFocus?.(e);
                props.onFocus?.();
              }}
              placeholder={
                props.isLoading
                  ? "Loading..."
                  : value.length === 0
                    ? props.placeholder
                    : ""
              }
              disabled={props.isDisabled}
            />
          </InputWrapper>
        </div>

        {props.isDisabled ? null : groupedOptions.length > 0 ? (
          <Listbox {...listBoxProps}>
            {(groupedOptions as Array<T>).map((option, index) => {
              const label = getLabel(option);
              if (!label) return null;

              return (
                <li
                  {...getOptionProps({ option, index })}
                  style={{
                    columnGap: "0.25rem",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {label.icon ?? null}
                  <span>{label.label}</span>
                </li>
              );
            })}
          </Listbox>
        ) : props.onCreateNewValue &&
          focused &&
          inputValue.trim().length > 0 ? (
          <Listbox {...listBoxProps}>
            <ListItemAction>
              <span>{props.newValuesLabel}</span>
            </ListItemAction>
          </Listbox>
        ) : focused && inputValue.trim().length > 0 ? (
          <Listbox {...listBoxProps}>
            <ListItemAction>
              <span>No options</span>
            </ListItemAction>
          </Listbox>
        ) : null}
      </Root>
    );
  },
) as <T>(
  p: AutocompleteProps<T> & { ref?: React.LegacyRef<HTMLElement> },
) => React.ReactElement;
