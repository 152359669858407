import { styled } from "@mui/material";
import { TypographyLabel } from "../../Typography";
import { Spacing } from "../../../utils/ColorConstants";

export const Version = styled(TypographyLabel)`
  width: 100%;
  padding: 0 0 0 ${Spacing.XLARGE};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
