import { Button, styled } from "@mui/material";
import Colors, {
  BorderRadii,
  FontSizes,
  FontWeights,
  Spacing,
} from "../../../utils/ColorConstants";

export const ActionButton = styled(Button)<{
  disabled?: boolean;
  selected?: boolean;
  shouldFocus?: boolean;
  isTab?: boolean;
}>`
  ${({ isTab }) => isTab && "width: 100%;"}
  min-width: 1.5rem !important;
  min-height: 1.5rem !important;
  display: flex;
  gap: ${Spacing.MEDIUM};
  padding: ${Spacing.MEDIUM};
  align-items: center;
  align-self: stretch;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  color: ${({ selected }) => (selected ? Colors.HIGHLIGHTED : Colors.LIGHT2)};
  background: ${({ selected, isTab, disabled }) =>
    disabled ? "initial" : selected && !isTab ? Colors.PURPLE_WHITE : "none"};
  pointer-events: ${({ disabled }) => (disabled ? "none" : "initial")};
  border-radius: ${({ isTab }) => (isTab ? "0" : BorderRadii.SMALL)};
  font-weight: ${({ selected, isTab }) =>
    selected || !isTab ? FontWeights.BOLD : FontWeights.REGULAR};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  border-bottom: ${({ isTab, selected }) =>
    selected && isTab ? `2px solid ${Colors.PURPLE_DARK}` : "none"};

  :hover {
    color: ${({ selected }) => (selected ? Colors.HIGHLIGHTED : Colors.LIGHT)};
    background: ${({ selected }) => (selected ? Colors.PURPLE_WHITE : "none")};
  }

  ${({ shouldFocus, isTab }) =>
    shouldFocus && !isTab
      ? `&:focus {
    border: 1px solid ${Colors.PURPLE_DARK}
    box-shadow: 0px 0px 0px 4px ${Colors.PURPLE_WHITE}, 0px 0px 0px 2px ${Colors.WHITE};
  }`
      : ""}
  span {
    font-size: ${FontSizes.SMALL};
    font-style: normal;
    line-height: normal;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
`;
