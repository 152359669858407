import "./styles.css";

export function LoadingSpinner(props: { style?: React.CSSProperties }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        ...(props.style ?? {}),
      }}
    >
      <div className="spinner" style={props.style}></div>
    </div>
  );
}

export default LoadingSpinner;
