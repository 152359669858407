import { useState } from "react";
import Toast from "../components/Toast";
import { useNavigate } from "react-router-dom";

export type ToastState = {
  title: string;
  description: string;
  type: "Notification" | "Information" | "Warning" | "Error" | "Success";
  path?: string;
};

export const useToast = (opts?: { timeout?: number }) => {
  const navigate = useNavigate();
  const { timeout = 5000 } = opts ?? {};
  const [toast, setToast] = useState<ToastState | undefined>(undefined);
  return {
    show: (state: ToastState, onClose?: () => unknown) => {
      setToast(state);
      setTimeout(() => {
        setToast(undefined);
        onClose?.();
        if (state.path) {
          navigate(state.path);
        }
      }, timeout);
    },
    toast: toast ? (
      <Toast
        title={toast.title}
        description={toast.description}
        type={toast.type}
      />
    ) : null,
  };
};
