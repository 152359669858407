import { styled } from "@mui/material";
import Colors from "../../../utils/ColorConstants";

export const ContainerTitle = styled("div")`
  display: flex;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  gap: 0.5rem;

  span,
  input {
    align-items: center;
    overflow: hidden;
    color: ${Colors.DEFAULT};
    white-space: nowrap;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 0;
  }
`;
