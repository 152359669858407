import type { ChartComponent } from "chart.js";
import { useEffect, useState } from "react";

export const importOrReload = async <T>(importFunc: () => Promise<T>) => {
  try {
    return await importFunc();
  } catch (e) {
    window.location.reload();
    console.error("Error while importing module, reloading page", e);
    await new Promise((resolve) => setTimeout(resolve, 500));
    throw e;
  }
};

export const createLazyModule = <T>(importFunc: () => Promise<T>) => {
  let mod: T | undefined;
  return async () => {
    if (!mod) {
      mod = await importOrReload(importFunc);
    }
    return mod;
  };
};

const getChartJS = createLazyModule(() => import("chart.js"));
const getReactChartJS = createLazyModule(() => import("react-chartjs-2"));

export type ChartJSComponentNames = keyof {
  [K in keyof typeof import("chart.js")]: (typeof import("chart.js"))[K] extends ChartComponent
    ? K
    : never;
};

export const useChartJS = (itemsNames: ChartJSComponentNames[]) => {
  const [chartJS, setChartJS] = useState<typeof import("react-chartjs-2")>();
  useEffect(() => {
    async function loadChartJS() {
      const chartJS = await getChartJS();
      const reactChartJS = await getReactChartJS();
      chartJS.Chart.register(
        ...itemsNames.map((name) => chartJS[name] as ChartComponent),
      );
      setChartJS(reactChartJS);
    }
    loadChartJS();
  }, [itemsNames]);
  return chartJS;
};
