import { SvgIcon } from "@mui/material";
import { CustomIconProps } from "..";

export function Primary(props: CustomIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path d="M14 12.6667H2L8 2.66675" fill="#F9F5FF" />
        <path d="M13.7433 10.1141H13.7533H13.7433Z" fill="#F9F5FF" />
        <path d="M13.0099 7.84741H13.0199H13.0099Z" fill="#F9F5FF" />
        <path d="M11.8101 5.78076H11.8201H11.8101Z" fill="#F9F5FF" />
        <path d="M10.2767 3.98071H10.2867H10.2767Z" fill="#F9F5FF" />
        <path
          d="M14 12.6667H2L8 2.66675M13.7433 10.1141H13.7533M13.0099 7.84741H13.0199M11.8101 5.78076H11.8201M10.2767 3.98071H10.2867"
          stroke="#6905FF"
          stroke-width="1.25"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
