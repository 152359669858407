import LoadingSpinner from "../loading-spinner";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { graphql } from "../gql";
import {
  Company,
  ImageSize,
  StatusType,
  TaskStatsType,
  UpdateCompanyInfoInput,
  UpdateCompanyInput,
} from "../gql/graphql";
import useCompany, {
  CompanyFirstContext,
  CompanySecondContext,
  CompanySecondContextValue,
  GET_GLOBAL_TASK_STATS,
  useCompanyStaticId,
} from "./contexts/company";
import useUser from "./contexts/user";
import { applyUpdate } from "../utils/update";
import { getFileUpload } from "../utils/file";
import { useLazyQueryRequest } from "../gql-hooks/queries";

const UPDATE_COMPANY_INFO = graphql(`
  mutation UpdateCompanyInfo(
    $input: UpdateCompanyInfoInput!
    $scope: ScopeInput!
  ) {
    updateCompanyInfo(input: $input, scope: $scope) {
      company_id
      ein
      zip_code
      state
      city
      primary_address
      secondary_address
      website_url
      country_id
      owner_id
      created_at
      updated_at
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
  }
`);

const UPDATE_COMPANY = graphql(`
  mutation UpdateCompany($input: UpdateCompanyInput!, $scope: ScopeInput!) {
    updateCompany(input: $input, scope: $scope) {
      parent_company_id
      company_id
      name
      owner_id
      created_at
      updated_at
      has_profile_picture
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
      presigned_url
      put_presigned_url
    }
  }
`);

const LIST_MY_COMPANIES = graphql(`
  query ListMyCompanies($imageSize: ImageSize) {
    myCompanies(image_size: $imageSize) {
      parent_company_id
      company_id
      name
      owner_id
      created_at
      updated_at
      has_profile_picture
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
      presigned_url
      put_presigned_url
    }
  }
`);

const LIST_COMPANY_ITEMS = graphql(`
  query ListCompanyItems($companyId: UUID!) {
    companyMembers(scope: { Company: $companyId }) {
      company_id
      user_id
      user_email
      role_id
      owner_id
      created_at
      updated_at
      ttl
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
    companyInfo(scope: { Company: $companyId }) {
      company_id
      ein
      zip_code
      state
      city
      primary_address
      secondary_address
      website_url
      country_id
      owner_id
      created_at
      updated_at
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
    statusStages(scope: { Company: $companyId }) {
      status_stage_id
      company_id
      status_id
      name
      status_type
      color
      ord
      visible
      owner_id
      created_at
      updated_at
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
    customFields(
      scope: { Company: $companyId }
      filter: { only_global_level: true }
    ) {
      company_id
      custom_field_id
      task_id
      name
      description
      icon_name
      required
      select_options
      unit_of_measurement
      displayed_type
      type
      owner_id
      created_at
      updated_at
      is_default
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
    roles(scope: { Company: $companyId }) {
      company_id
      role_id
      name
      owner_id
      is_project_role
      created_at
      updated_at
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
    tags(scope: { Company: $companyId }) {
      company_id
      tag_id
      name
      color
      owner_id
      created_at
      type
      updated_at
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
  }
`);

function FirstCompanyRoute({ children }: { children: React.ReactNode }) {
  const [isUpdatingCompanyImage, setIsUpdatingCompanyImage] = useState(false);
  const { companyId, setCompanyId, user } = useCompanyStaticId();

  const [companies, setCompanies] = useState<Map<string, Company> | undefined>(
    undefined,
  );
  const [updateCompanyMutation] = useMutation(UPDATE_COMPANY);
  const [updateInput, setUpdateInput] = useState<UpdateCompanyInput>({});
  const { data } = useQuery(LIST_MY_COMPANIES, {
    variables: {
      imageSize: ImageSize.S32,
    },
  });
  const company = useMemo(() => {
    if (!companyId) return undefined;
    return companies?.get(companyId);
  }, [companyId, companies]);

  const updateCompanyImage = useCallback(
    (file: File) => {
      if (!companyId) return;
      setIsUpdatingCompanyImage(true);
      const fileUpload = getFileUpload(file);
      updateCompanyMutation({
        variables: {
          input: {
            profile_picture_content_type: fileUpload.contentType,
          },
          scope: {
            Company: companyId,
          },
        },
      }).then(async (res) => {
        await fileUpload.uploadFile(res.data?.updateCompany.put_presigned_url);
        setCompanies((prev) => {
          const newMap = new Map(prev?.entries());

          const c = prev?.get(companyId);
          if (!c) return prev;
          newMap.set(companyId, {
            ...c,
            presigned_url: res.data?.updateCompany.presigned_url,
          });

          setIsUpdatingCompanyImage(false);
          return newMap;
        });
      });
    },
    [companyId, updateCompanyMutation],
  );

  useEffect(() => {
    if (!data) return;
    setCompanies(
      new Map<string, Company>(
        data.myCompanies.map((companyData) => [
          companyData.company_id,
          companyData,
        ]),
      ),
    );
  }, [data]);

  useEffect(() => {
    if (!data) return;
    if (data.myCompanies.find((company) => company.company_id === companyId))
      return;

    const cId = data.myCompanies.at(0)?.company_id;
    if (!cId) {
      window.location.href = "/new-company";
      setCompanyId(undefined);
      return;
    }
    setCompanyId(cId);
  }, [data, companyId, setCompanyId]);

  useEffect(() => {
    if (
      Object.values(updateInput).every((value) => value === undefined) ||
      !companyId
    ) {
      return;
    }
    setCompanies((prev) => {
      const newMap = new Map(prev?.entries());

      const c = prev?.get(companyId);
      if (!c) return prev;
      newMap.set(
        companyId,
        applyUpdate(c, { ...updateInput, unset: undefined }),
      );
      return newMap;
    });
    const newTimer = setTimeout(() => {
      updateCompanyMutation({
        variables: {
          input: updateInput,
          scope: {
            Company: companyId,
          },
        },
      }).then(() => {
        setUpdateInput({});
      });
    }, 700);

    return () => clearTimeout(newTimer);
  }, [companyId, updateCompanyMutation, updateInput]);

  if (!user) {
    return <></>;
  }

  if (!companyId) {
    return <LoadingSpinner />;
  }
  return (
    <CompanyFirstContext.Provider
      value={{
        companyId: companyId!,
        setCompanyId,
        companies,
        company,
        setCompany: setUpdateInput,
        updateCompanyImage,
        isUpdatingCompanyImage,
      }}
    >
      {children}
    </CompanyFirstContext.Provider>
  );
}

function SecondCompanyRoute({ children }: { children: React.ReactNode }) {
  const { setRole, user: useContext } = useUser();
  const [runItemsQuery, itemsQuery] = useLazyQuery(LIST_COMPANY_ITEMS);
  const { companyId } = useCompany();
  const { setInput: globalStatsSetInput, ...globalStats } = useLazyQueryRequest(
    {
      query: GET_GLOBAL_TASK_STATS,
    },
  );

  const [updateCompanyInfoMutation] = useMutation(UPDATE_COMPANY_INFO);
  const [updateInfoInput, setUpdateInfoInput] =
    React.useState<UpdateCompanyInfoInput>({});
  const [items, setItems] = React.useState<CompanySecondContextValue["items"]>(
    {},
  );

  useEffect(() => {
    if (!items.companyMembers) return;
    const member = items.companyMembers.get(useContext?.user_id ?? "");
    if (!member) return;
    const role = items.roles?.get(member.role_id ?? "");
    if (!role) return;
    setRole(role);
  }, [items.companyMembers, items.roles, setRole, useContext?.user_id]);

  useEffect(() => {
    if (
      Object.values(updateInfoInput).every((value) => value === undefined) ||
      !companyId
    ) {
      return;
    }
    setItems((prev) => {
      if (!prev.companyInfo) return prev;

      return {
        ...prev,
        companyInfo: applyUpdate(prev.companyInfo, updateInfoInput),
      };
    });
    const newTimer = setTimeout(() => {
      updateCompanyInfoMutation({
        variables: {
          input: updateInfoInput,
          scope: {
            Company: companyId,
          },
        },
      }).then(() => {
        setUpdateInfoInput({});
      });
    }, 700);

    return () => clearTimeout(newTimer);
  }, [companyId, updateCompanyInfoMutation, updateInfoInput]);

  useEffect(() => {
    if (!itemsQuery.data) return;
    const newItems = {
      statusStages: new Map(
        itemsQuery.data.statusStages.map((statusStage) => [
          statusStage.status_stage_id,
          statusStage,
        ]),
      ),
      statusStagesByName: new Map(
        itemsQuery.data.statusStages.map((statusStage) => [
          statusStage.name,
          statusStage,
        ]),
      ),
      customFields: new Map(
        itemsQuery.data.customFields.map((customField) => [
          customField.custom_field_id,
          customField,
        ]),
      ),
      roles: new Map(itemsQuery.data.roles.map((role) => [role.role_id, role])),
      rolesByName: new Map(
        itemsQuery.data.roles.map((role) => [role.name, role]),
      ),
      tags: new Map(itemsQuery.data.tags.map((tag) => [tag.tag_id, tag])),
      companyInfo: itemsQuery.data.companyInfo,
      companyMembers: new Map(
        itemsQuery.data.companyMembers.map((member) => [
          member.user_id ?? member.user_email!,
          member,
        ]),
      ),
      statusStagesOpenIds: itemsQuery.data.statusStages
        .filter((s) => s.status_type !== StatusType.Closed)
        .map((s) => s.status_stage_id),
    };
    setItems(newItems);
  }, [companyId, itemsQuery]);

  useEffect(() => {
    if (!items.statusStagesOpenIds) return;
    globalStatsSetInput({
      scope: {
        Company: companyId,
      },
      type: TaskStatsType.Task,
      filters: {
        status_stage_ids: items.statusStagesOpenIds,
      },
    });
  }, [companyId, globalStatsSetInput, items.statusStagesOpenIds]);

  async function refetchItems() {
    await itemsQuery.refetch();
  }

  useEffect(() => {
    runItemsQuery({ variables: { companyId } });
  }, [companyId, runItemsQuery]);

  return (
    <CompanySecondContext.Provider
      value={{
        items,
        refetchItems,
        setCompanyInfo: setUpdateInfoInput,
        globalTasksStats: globalStats.value?.globalTasksStats,
        refetchGlobalTasksStats: globalStats.refetch,
      }}
    >
      {children}
    </CompanySecondContext.Provider>
  );
}
export function CompanyRoute({ children }: { children: React.ReactNode }) {
  return (
    <FirstCompanyRoute>
      <SecondCompanyRoute>{children}</SecondCompanyRoute>
    </FirstCompanyRoute>
  );
}
export default CompanyRoute;
