import { styled } from "@mui/material";
import {
  BorderRadii,
  FontWeights,
  Spacing,
  border,
} from "../../../utils/ColorConstants";

export const ContainerAction = styled("div")<{ isTab?: boolean }>`
  display: flex;
  height: ${({ isTab }) => (isTab ? "1.5rem" : "2rem")};
  padding: ${({ isTab }) => (isTab ? "0" : Spacing.SMALL)};
  justify-content: center;
  align-items: center;
  gap: ${Spacing.SMALL};
  border${({ isTab }) => (isTab ? "-bottom" : "")}: ${border};
  border-radius: ${({ isTab }) => (isTab ? "0" : BorderRadii.SMALL)};
  font-weight: ${FontWeights.BOLD};
  ${({ isTab }) => isTab && "width: 100%;"}
`;
