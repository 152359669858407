import { i18n } from "i18next";

export const availableLanguages = [
  {
    languageCode: "en",
    countryCode: "us",
    label: "English",
  },
  {
    languageCode: "es",
    countryCode: "es",
    label: "Español",
  },
] as const;

export const defaultLanguage = availableLanguages[0].languageCode;
export type LanguageCode = (typeof availableLanguages)[number]["languageCode"];
const isValidLanguage = (
  language: string | undefined | null,
): language is LanguageCode =>
  !!language &&
  availableLanguages.some((lang) => lang.languageCode === language);

export const getLanguageFromUsr = (
  i: i18n,
  usrLang: LanguageCode | null | undefined,
): LanguageCode => {
  let language = getLanguage();
  if (usrLang && usrLang !== language) {
    language = usrLang;
    setLanguage(i, language);
  }
  return language;
};

export const getLanguage = (): LanguageCode => {
  const language = localStorage.getItem("i18nextLng");
  return isValidLanguage(language)
    ? (language as LanguageCode)
    : defaultLanguage;
};

export const updateLanguage = (i: i18n) => {
  setLanguage(i, getLanguage());
};

export const setLanguage = (i: i18n, language: LanguageCode) => {
  if (!isValidLanguage(language)) {
    language = defaultLanguage;
  }
  localStorage.setItem("i18nextLng", language);
  if (i.language !== language) {
    i.changeLanguage(language);
  }
};
