import { AutocompleteGetTagProps, SxProps, Theme } from "@mui/material";
import { StyledTag, TagsContainer } from "./styles";
import { TagType } from "./tag";

export type TagsContainerWrapperProps<T> = {
  height?: string;
  inputWrapper?: boolean;
  tagsContainerSx?: SxProps<Theme>;
  tagSx?: SxProps<Theme>;
  tagType: TagType;
  getLabel: (option: T) => {
    value: T;
    label: string;
    icon: React.ReactNode;
  } | null;
  getTagProps?: AutocompleteGetTagProps;
  thumbnail_key?: keyof T;
  focused: boolean;
  maxSize: number;
  value: T[];
};

export const TagsContainerWrapper = <T,>(
  props: TagsContainerWrapperProps<T>,
) => {
  return (
    <TagsContainer
      removePadding={props.inputWrapper}
      sx={{
        ...props.tagsContainerSx,
        height: props.height,
      }}
    >
      {props.value
        .slice(0, props.focused ? undefined : props.maxSize)
        .map((option, index: number) => {
          const label = props.getLabel(option);
          if (!label) return null;
          if (index === props.maxSize - 1 && !props.focused)
            return (
              <StyledTag
                tagType="other"
                sx={props.tagSx}
                opt={{ label: `+${props.value.length - props.maxSize + 1}` }}
              />
            );
          return (
            <StyledTag
              tagType={props.tagType}
              opt={label}
              {...props.getTagProps?.({ index })}
              shouldDisableDelete={!props.focused}
              sx={props.tagSx}
              src={
                props.thumbnail_key &&
                option &&
                typeof option === "object" &&
                props.thumbnail_key in option
                  ? (option[props.thumbnail_key] as string)
                  : ""
              }
            />
          );
        })}
    </TagsContainer>
  );
};
