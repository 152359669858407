import { Skeleton, styled } from "@mui/material";
import { Image, AvatarFallback } from "@radix-ui/react-avatar";

export const LoadingProfileImage = styled(Skeleton)`
  border-radius: 6.25rem;
  width: 1rem;
  height: 1rem;
  margin-top: 2px;
`;

export const ProfileImage = styled(Image)`
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  background-color: lightgray;
  width: 1rem;
  height: 1rem;
`;
export const ProfileFallback = styled(AvatarFallback)`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: 6.25rem;
  width: 1rem;
  height: 1rem;
  margin-top: 2px;

  font-size: 0.625rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
