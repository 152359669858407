import { styled } from "@mui/material";
import Colors from "../../utils/ColorConstants";

export const Container = styled("div")`
  z-index: 200;
  position: fixed;
  top: 16px;
  right: 16px;
  width: 308px;
  min-height: 58px;
  background-color: ${Colors.WHITE};
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  overflow: hidden;
  border-radius: 8px;
`;

export const ToastHeader = styled("div")`
  /* height: 15px;
  background-color: ${(props) => props.theme.palette.primary.main};
  color: ${Colors.WHITE}; */
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: row;
  padding: 4px 8px;
  gap: 0.5rem;
`;

export const ToastBody = styled("div")`
  height: 30px;
  padding: 16px;
`;

export const ContainerHeader = styled("div")`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
