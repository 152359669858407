import { Skeleton, Typography, styled } from "@mui/material";
import Colors from "../../utils/ColorConstants";

export const SizedTypography = styled(Typography)<{
  sizelimit?: string | boolean;
  color?: string;
  green?: boolean;
  red?: boolean;
  dashed?: boolean;
}>`
  ${({ sizelimit: size }) =>
    size
      ? `
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  ${size !== true ? `max-width: ${size};` : ""}
`
      : ""}
  ${({ green }) => green && `color: ${Colors.GREEN}!important;`}
  ${({ red }) => red && `color: ${Colors.RED}!important;`}
  ${({ dashed, red, green }) =>
    dashed &&
    `text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-thickness: from-font;
  text-decoration-color: ${red ? Colors.RED : green ? Colors.GREEN : Colors.LIGHT2}!important;
  `}
`;

export const TypographyHeaderMedium = styled(SizedTypography)`
  font-size: 18px;
  font-weight: 700;
  line-height: 24.59px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ color }) => color ?? Colors.DEFAULT};
`;
export const TypographyHeaderSmall = styled(SizedTypography)`
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ color }) => color ?? Colors.DEFAULT};
`;

export const TypographyBody = styled(SizedTypography)`
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ color }) => color ?? Colors.BLUE_GRAY};
`;
export const TypograhpyBodyMedium = styled(TypographyBody)`
  font-weight: 500;
  color: ${({ color }) => color ?? Colors.LIGHT2};
`;

export const TypographyBodyDefault = styled(TypographyBody)`
  color: ${({ color }) => color ?? Colors.DEFAULT};
 }
`;

export const TypographyLabel = styled(SizedTypography)`
  font-size: 12px;
  font-weight: 600;
  line-height: 16.39px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: ${({ color }) => color ?? Colors.LIGHT2};
`;

export const TypographyBold = styled(SizedTypography)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ color }) => color ?? Colors.DEFAULT};
`;

export const TypographySemiBold = styled(SizedTypography)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: 0em;
  text-align: left;
  color: ${({ color }) => color ?? Colors.DEFAULT};
`;

export const TypographyBodySemiBold = styled(TypographySemiBold)`
  color: ${({ color }) => color ?? Colors.BLUE_GRAY};
`;

export const TypographyLoading = styled(Skeleton)<{
  xs?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  fullWidth?: boolean;
}>`
  display: inline-block;
  width: ${({ fullWidth, xs, sm, md, lg, xl }) =>
    fullWidth
      ? "100%"
      : `${xs ? 0.5 : sm ? 1.5 : md ? 3 : lg ? 5 : xl ? 7 : 7}rem`};
`;
