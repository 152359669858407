import React from "react";

export type GuardProps = { children: React.ReactNode };
export type Guard = React.ComponentType<GuardProps>;

export const ComposeGuards: React.FC<{
  guards: Guard[];
  children: React.ReactNode;
}> = ({ guards, children }): React.ReactNode => {
  if (guards.length === 0) return children as React.ReactNode;

  const renderGuards = (
    remainingGuards: Guard[],
    innerChildren: React.ReactNode,
  ): React.ReactNode => {
    if (remainingGuards.length === 0) return innerChildren as React.ReactNode;

    const [CurrentGuard, ...restGuards] = remainingGuards;

    return React.createElement(CurrentGuard, {
      children: renderGuards(restGuards, innerChildren),
    });
  };

  return renderGuards(guards, children);
};
