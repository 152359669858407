import { useEffect, useState } from "react";
import useCompany from "../../../guards/contexts/company";
import { useGetExportedTasks } from "../../../gql-hooks/useExport";
import { ScopeInput } from "../../../gql/graphql";
import { useExportContext } from "../../../context/Exports";
import Snackbar from "@mui/material/Snackbar";
import { PrimaryButton } from "../../UI";
import Slide, { SlideProps } from "@mui/material/Slide";
import { IconFileDownload } from "@tabler/icons-react";

export const ExportNotification = () => {
  const company = useCompany();
  const { exportId: contextExportId } = useExportContext();
  const [localExportId, setLocalExportId] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [presignedUrl, setPresignedUrl] = useState<string | null>(null);

  const effectiveExportId = contextExportId || localExportId;

  useEffect(() => {
    if (!contextExportId) {
      const storedExportData = localStorage.getItem("export_id");
      const exportData = storedExportData ? JSON.parse(storedExportData) : null;
      setLocalExportId(exportData?.export_id);
    }
  }, [contextExportId]);

  const { exportResult } = useGetExportedTasks(
    { Company: company.companyId } as ScopeInput,
    effectiveExportId,
  );

  useEffect(() => {
    if (effectiveExportId && exportResult && !snackbarOpen) {
      if (exportResult.status === "completed") {
        setPresignedUrl(exportResult.presigned_url || "");
      }
    }
  }, [effectiveExportId, exportResult, snackbarOpen]);

  useEffect(() => {
    if (presignedUrl) {
      setSnackbarOpen(true);
    } else {
      setSnackbarOpen(false);
    }
  }, [presignedUrl]);

  const handleDownloadClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (presignedUrl) {
      window.open(presignedUrl, "_blank");
      localStorage.removeItem("export_id");
      setSnackbarOpen(false);
    }
  };

  const SlideTransition = (props: SlideProps) => {
    return <Slide {...props} direction="left" />;
  };

  return (
    <Snackbar
      sx={{
        width: "100%",
        "& .MuiSnackbarContent-root": {
          backgroundColor: "white",
          color: "black",
        },
      }}
      open={snackbarOpen}
      message="Your file is ready to download!"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      TransitionComponent={SlideTransition}
      action={
        <PrimaryButton
          href={presignedUrl || ""}
          rel="noopener noreferrer"
          variant="contained"
          onClick={handleDownloadClick}
        >
          <IconFileDownload />
          Download
        </PrimaryButton>
      }
    ></Snackbar>
  );
};
