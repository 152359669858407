import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { Tag, TagProps } from "./tag";
import Colors from "../../utils/ColorConstants";

export const StyledInput = styled("input")`
  font: 14px/19.12px Manrope;
  color: ${Colors.DEFAULT};

  &::placeholder {
    color: ${Colors.LIGHT2};
    font: 14px/19.12px Manrope;
  }
`;

export const InputWrapper = styled("div")<{
  noBorder?: boolean;
  removePadding?: boolean;
  height?: string;
}>`
  border: ${(props) => (props.height === "0px" ? "0px" : "1px")} solid
    ${(props) => (props.noBorder ? "transparent" : "#edf1f8")};
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: ${(props) => (props.height ? props.height : "2rem")};

  &:hover {
    border-color: ${(props) => (props.noBorder ? "transparent" : "#d9d9d9")};
  }

  &.focused {
    border-color: ${(props) =>
      props.noBorder ? "transparent" : Colors.HIGHLIGHTED};
    box-shadow: ${(props) =>
      props.noBorder
        ? "none"
        : `0px 0px 0px 4px #f2e6ff,
           0px 0px 0px 2px ${Colors.WHITE}`};
  }

  & input {
    box-sizing: border-box;
    padding: ${(props) => (props.removePadding ? "0px" : "4px 6px")};
    width: 0;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
    background: transparent;
  }
`;

export const Root = styled("div")`
  font-size: 14px;
  position: relative;
  width: 100%;
`;

export const TagsContainer = styled("div")<{ removePadding?: boolean }>`
  display: flex;
  min-height: 2rem;
  padding: ${(props) => (props.removePadding ? "0px" : "0.5rem")};
  align-items: center;
  gap: 0.25rem;
  align-self: stretch;
  flex-wrap: wrap;
  overflow: hidden;
`;

export const StyledTag = styled(Tag)<TagProps>`
  display: flex;
  align-items: center;
  height: 1.25rem;
  min-height: 0.25rem;
  background-color: ${(props) =>
    props.tagType === "assignee" ? Colors.PURPLE_WHITE : Colors.BACKGROUND};
  border-radius: 0.25rem;
  box-sizing: content-box;
  padding: 0.25rem;
  overflow: hidden;
  gap: ${(props) => (props.tagType === "assignee" ? "0.25rem" : "0.125rem")};
  color: ${(props) =>
    props.tagType === "assignee" ? Colors.HIGHLIGHTED : Colors.DEFAULT};

  & span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: ${(props) => (props.tagType === "assignee" ? "600" : "400")};
    line-height: normal;
  }

  & svg {
    width: 0.75rem;
    height: 0.75rem;
  }
`;

export const Listbox = styled("ul")`
  width: 100%;
  position: absolute;
  margin: 2px 0 0;
  padding: 0;
  list-style: none;
  background-color: ${Colors.WHITE};
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 0.5rem 0.75rem;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected="true"] {
    background-color: ${Colors.PURPLE_WHITE};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${Colors.PURPLE_WHITE};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`;

export const ListItemAction = styled("div")<{ creation?: boolean }>`
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  line-height: 1.5;
`;
