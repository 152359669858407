import { ReactNode } from "react";

type MatchProps = {
  when: boolean;
  children: ReactNode | ReactNode[];
  fallback?: ReactNode;
};

export function Match(props: MatchProps) {
  if (!props.when && props.fallback) return props.fallback;

  if (!props.when) return undefined;

  return props.children;
}
