import { Box, Button } from "@mui/material";
import Colors from "../../../utils/ColorConstants";
import { IconSearch } from "@tabler/icons-react";
import { KeyBindWrapper } from "../../KeyBind";
import { SearchModal } from "./SearchModal";
import { useState } from "react";
import { TFunction } from "i18next";

export const SearchBar = ({
  isCollapsed,
  t,
}: {
  isCollapsed?: boolean;
  t: TFunction<"main", "sidebar">;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        sx={{
          display: "flex",
          width: isCollapsed ? "2rem" : "11rem",
          minWidth: "0",
          height: "2rem",
          border: `1px solid ${Colors.DEFAULT}`,
          borderRadius: "0.5rem",
          alignItems: "center",
          alignContent: "center",
          justifyContent: "space-between",
          padding: "0.5rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "flex-start",
            gap: "0.5rem",
            color: Colors.LIGHT3,
            fontWeight: 500,
            fontSize: "14",
          }}
        >
          <IconSearch color={Colors.LIGHT3} size="1rem" />
          {!isCollapsed && t("search.title")}
        </Box>
        {!isCollapsed && (
          <KeyBindWrapper
            ctrlKey
            keyLetter="k"
            styleType="dark"
            callback={() => {
              setOpen((prev) => !prev);
            }}
          />
        )}
      </Button>
      <SearchModal open={open} setOpen={setOpen} />
    </>
  );
};
