import {
  Box,
  Button,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SearchInput } from "../../../Search";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Colors from "../../../../utils/ColorConstants";
import { useQueryRequest } from "../../../../gql-hooks/queries";
import useCompany from "../../../../guards/contexts/company";
import { KeyBindWrapper } from "../../../KeyBind";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import { mountTaskPath } from "../../../../utils/task";
import { useNavigate } from "react-router-dom";
import { AssetImage } from "../../../AssetImage";
import { ImageSize, Tag, Task, User } from "../../../../gql/graphql";
import LoadingSpinner from "../../../../loading-spinner";
import { IconSearchOff } from "@tabler/icons-react";
import { useTaskParent } from "../../../../gql-hooks/useTaskParent";
import { TaskParent } from "../../../TaskParent";
import { AutocompleteMultiple } from "../../../Autocomplete";
import { useTags } from "../../../../gql-hooks/useTags";
import { SEARCH_QUERY } from "./gql";
import { LineDivider } from "./line-divider";
import { SECTIONS, SectionKey } from "./types";
import { Section } from "./section";
import Mark from "mark.js";
import { Parser } from "html-to-react";
const htmlToReactParser = Parser();

const BOTTOM_COMMAND_SX: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row",
  alignContent: "center",
  alignItems: "center",
  justifyContent: "center",
  color: Colors.BLUE_GRAY,
  fontWeight: 400,
  fontSize: "14px",
  gap: "0.375rem",
};
const KEY_BIND_BUTTON_SX: SxProps<Theme> = {
  display: "flex",
  alignItems: "center",
  alignContent: "center",
  justifyContent: "flex-end",
  gap: "0.5rem",
  color: Colors.BLUE_GRAY,
  fontWeight: 500,
  fontSize: "14",
  whiteSpace: "nowrap",
  padding: "0",
};

const getSearchItemId = (item: Task | User) => {
  return "task_id" in item ? item.task_id : item.user_id;
};

const filterInItems = <T extends Task | User>(
  arr: T[],
  items: (Task | User)[] | undefined,
): T[] => {
  if (!items) return arr;
  return arr.filter((item) => {
    const id = getSearchItemId(item);
    return items.every((i) => getSearchItemId(i) !== id);
  });
};
export const SearchModal = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const { t, i18n } = useTranslation("main", {
    keyPrefix: "sidebar",
  });
  const taskT = i18n.getFixedT(null, "main", "task");
  const listRef = useRef<VariableSizeList>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);
  const tagsInputRef = useRef<HTMLInputElement>(null);
  const tagsListBoxRef = useRef<HTMLUListElement>(null);
  const [searchStr, setSearchStr] = useState<string>("");
  const [section, setSection] = useState<SectionKey>("all");
  const company = useCompany();
  const { tags } = useTags(company.companyId);
  const [selectedTags, setSelectedTags] = useState<Array<Tag>>([]);
  const [addingTags, setAddingTags] = useState<boolean>(false);
  const { refetch, ...search } = useQueryRequest({
    query: SEARCH_QUERY,
    input: {
      filter: {
        query_string: "",
      },
      scope: {
        Company: company.companyId,
      },
      imageSize: ImageSize.S32,
    },
  });
  const { listTaskParent, tasks } = useTaskParent();
  const [selected, setSelected] = useState<number>();
  const [searchedStr, setSearchedStr] = useState<string>("");
  const getItems = useCallback(
    (section: SectionKey) => {
      if (!search.value) return undefined;
      if (section === "all") {
        return (
          Object.keys(SECTIONS).filter((k) => k !== "all") as Exclude<
            SectionKey,
            "all"
          >[]
        )
          .map((k) => search.value!.searchInCompany[SECTIONS[k]])
          .flat() as (Task | User)[];
      }

      return search.value.searchInCompany[SECTIONS[section]];
    },
    [search.value],
  );
  const getItemsFromParent = useCallback(
    (section: SectionKey, items: (Task | User)[] | undefined) => {
      if (!search.value) return undefined;
      if (section === "all")
        return filterInItems(
          search.value.searchInCompany.project_children.concat(
            search.value.searchInCompany.sku_children,
          ),
          items,
        );
      if ((section !== "skus" && section !== "parts") || searchedStr === "")
        return [];
      const key = section === "skus" ? "project_children" : "sku_children";
      return filterInItems(search.value?.searchInCompany[key], items);
    },
    [search.value, searchedStr],
  );

  const sectionsResult = useMemo(() => {
    return Object.fromEntries(
      (Object.keys(SECTIONS) as SectionKey[]).map((k) => {
        const items = getItems(k);
        const itemsFromParent = getItemsFromParent(k, items);
        return [k, { items, itemsFromParent }];
      }),
    ) as Record<
      SectionKey,
      {
        items: (Task | User)[] | undefined;
        itemsFromParent: Task[] | undefined;
      }
    >;
  }, [getItems, getItemsFromParent]);

  const items = useMemo(() => {
    return sectionsResult[section].items;
  }, [section, sectionsResult]);
  const itemsFromParent = useMemo(() => {
    return sectionsResult[section].itemsFromParent;
  }, [section, sectionsResult]);

  const sectionsCount = useMemo(() => {
    return Object.fromEntries(
      (Object.keys(sectionsResult) as SectionKey[]).map((k) => {
        const { items, itemsFromParent } = sectionsResult[k];
        return [
          k,
          items && itemsFromParent && items.length + itemsFromParent.length,
        ];
      }),
    ) as Record<SectionKey, number | undefined>;
  }, [sectionsResult]);

  const clickTimeout = useRef<{ timeout: NodeJS.Timeout; hadRun: boolean }>();
  const navigate = useNavigate();
  const getPath = useCallback((item: Task | User) => {
    if (!item) return undefined;
    if (item.__typename === "Task") return mountTaskPath(item.scopes);
    if (item.__typename === "User")
      return `/settings/people/?${new URLSearchParams({ id: item.user_id }).toString()}`;
    return undefined;
  }, []);

  const markRows = useCallback(
    <T extends Record<string, string>>(
      items: T[] | undefined,
    ):
      | {
          [K in keyof T]: React.ReactElement;
        }[]
      | undefined => {
      const elements = items?.map((item) => {
        return Object.fromEntries(
          Object.entries(item).map(([key, value]) => {
            const el = document.createElement("div");
            el.innerHTML = value;
            return [key, el] as const;
          }),
        );
      });
      if (!elements) return undefined;
      const mark = new Mark(
        elements.map((v) => Object.values(v) as HTMLDivElement[]).flat(),
      );
      mark.mark(searchedStr, {
        ignoreJoiners: true,
        ignorePunctuation: ":;.,-–—‒_(){}[]!'\"+=@#$%^&*".split(""),
      });
      return elements.map((obj) => {
        return Object.fromEntries(
          Object.entries(obj).map(([key, value]) => {
            return [
              key,
              htmlToReactParser.parse(value.innerHTML) as React.ReactElement,
            ] as const;
          }),
        );
      }) as { [K in keyof T]: React.ReactElement }[];
    },
    [searchedStr],
  );

  const rowContent = useMemo(() => {
    if (!tasks) return undefined;
    return markRows(
      items?.map((item) => {
        const name =
          "name" in item ? item.name : item.first_name + " " + item.last_name;
        const description =
          "description" in item ? item.description : item.email;
        return { name, description };
      }),
    )?.map((data, index) => {
      const item = items![index];
      if (!("parent_task_id" in item))
        return {
          ...data,
          parent: undefined,
        };
      const parent = tasks.find((t) => t.task_id === item.parent_task_id);
      return {
        parent,
        ...data,
      };
    });
  }, [items, markRows, tasks]);

  const rowFromParentContent = useMemo(() => {
    if (!tasks) return undefined;
    return markRows(
      itemsFromParent?.map((item) => {
        const parent = tasks.find((t) => t.task_id === item.parent_task_id);
        return {
          name: parent?.name ?? "",
          description: parent?.description ?? "",
        };
      }),
    )?.map((data, index) => {
      const item = itemsFromParent![index];
      const parent = tasks.find((t) => t.task_id === item.parent_task_id);
      return {
        name: item.name,
        description: item.description,
        parent: parent && {
          ...parent,
          ...data,
        },
      };
    });
  }, [itemsFromParent, markRows, tasks]);

  const rowSize = useCallback(
    (index: number) => {
      if (index === items?.length) return 56;
      return 40;
    },
    [items?.length],
  );

  const path = useMemo(() => {
    if (selected == null) return undefined;
    if (!items || !itemsFromParent) return undefined;
    const item =
      selected < items.length
        ? items?.[selected]
        : itemsFromParent?.[selected - items.length];
    if (!item) return undefined;
    return getPath(item);
  }, [getPath, items, itemsFromParent, selected]);
  const onClose = useCallback(() => {
    props.setOpen(false);
    tagsInputRef.current?.blur();
    tagsListBoxRef.current?.blur();
    setSearchStr("");
    setSelectedTags([]);
    setSection("all");
  }, [props]);

  const goToItem = useCallback(() => {
    if (path) {
      navigate(path);
      onClose();
    }
  }, [navigate, onClose, path]);
  const changeSelection = useCallback(
    (delta: number) => {
      let previousValue = selected;
      if (selected == null && delta > 0) {
        previousValue = -1;
      } else if (selected == null && delta <= 0) {
        previousValue = 0;
      }
      if (!items || !itemsFromParent) return;
      const l = items.length + itemsFromParent.length;
      const newValue = (previousValue! + delta + l) % l;
      setSelected(newValue);
      listRef.current?.scrollToItem(newValue);
    },
    [items, itemsFromParent, selected],
  );

  const renderRow = useCallback(
    (childProps: ListChildComponentProps) => {
      const { index, style } = childProps;
      if (!items || !itemsFromParent || !rowContent || !rowFromParentContent)
        return null;
      const isFromParent = index >= items.length;
      const item = isFromParent
        ? itemsFromParent[index - items.length]
        : items[index];
      const content = isFromParent
        ? rowFromParentContent[index - rowContent.length]
        : rowContent[index];
      if (!item || !content) return null;
      const goToThisItem = () => {
        const p = getPath(item);
        if (!p) return;
        navigate(p);
        onClose();
      };

      return (
        <Box
          sx={{
            ...style,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            alignContent: "stretch",
            "mark + mark": {
              marginLeft: "0.25rem",
            },
            mark: {
              backgroundColor: Colors.PURPLE_HIGHLIGHTED_BG,
              color: Colors.PURPLE_DARK,
              borderRadius: "0.25rem",
              backgroundPosition: "left bottom",
              padding: "3px",
            },
          }}
        >
          {index === items.length && (
            <Typography
              color={Colors.BLUE_GRAY}
              fontSize="14px"
              height="1rem"
              fontWeight={500}
              children="In parent assets"
            />
          )}
          <ListItem
            style={{
              height: "40px",
              width: "100%",
            }}
            key={index}
            component="div"
            disablePadding
            sx={{
              paddingRight: "0.5rem",
            }}
          >
            <ListItemButton
              selected={selected === index}
              /*
               * This handle the click and double click events
               */
              onClick={(e) => {
                e.preventDefault();
                if (clickTimeout.current) {
                  clearTimeout(clickTimeout.current.timeout);
                  // If the clickTimeout not run, then we should go to the item
                  // because is a double click
                  if (
                    !clickTimeout.current.hadRun &&
                    (selected === index || selected == null)
                  ) {
                    goToThisItem();
                    clickTimeout.current = undefined;
                    return;
                  }
                  clickTimeout.current = undefined;
                }
                // first click
                setSelected((prev) => {
                  if (prev === index) return undefined;
                  return index;
                });
                // Set the timeout for the double click
                // if the timeout not run, then we should go to the item
                // because the two clicks are to close to each other
                clickTimeout.current = {
                  timeout: setTimeout(() => {
                    if (clickTimeout.current) {
                      clickTimeout.current.hadRun = true;
                    }
                  }, 500),
                  hadRun: false,
                };
              }}
              onDoubleClick={goToThisItem}
              sx={{
                borderRadius: "0.5rem",
                padding: "0.5rem",
                gap: "0.5rem",
                height: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                ".Mui-selected": {
                  backgroundColor: Colors.BACKGROUND,
                },
                "&:hover": {
                  backgroundColor: Colors.BACKGROUND,
                },
              }}
            >
              <Box
                sx={{
                  gap: "0.5rem",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  ".MuiTypography-root": {
                    minWidth: "auto",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "pre",
                    wordBreak: "break-all",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "flex-start",
                    width: "100%",
                  },
                  ".MuiTypography-root > *": {
                    transition: "all 5s ease",
                  },
                }}
              >
                <AssetImage
                  src={item.presigned_url}
                  radius="0.25rem"
                  avatarName={
                    item.__typename === "User" ? item.first_name : undefined
                  }
                />
                <ListItemText
                  primary={content.name}
                  primaryTypographyProps={{
                    color: Colors.DEFAULT,
                    fontWeight: 600,
                    fontSize: "14px",
                    className: "search-result",
                  }}
                  secondary={content.description}
                  secondaryTypographyProps={{
                    className: "search-result",
                    sx: {
                      ".highlight": {
                        padding: "1px",
                      },
                      color: Colors.BLUE_GRAY,
                      fontWeight: 400,
                      fontSize: "10px",
                    },
                  }}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    maxWidth: "22.5rem",
                  }}
                />
              </Box>
              <TaskParent parentTask={content.parent} t={taskT} />
            </ListItemButton>
          </ListItem>
        </Box>
      );
    },
    [
      getPath,
      items,
      itemsFromParent,
      navigate,
      onClose,
      rowContent,
      rowFromParentContent,
      selected,
      taskT,
    ],
  );

  useEffect(() => {
    if (!search.value) return;
    const v = search.value.searchInCompany;
    listTaskParent(
      {
        Company: company.companyId,
      },
      v.projects
        .concat(
          ...v.skus,
          ...v.parts,
          ...v.tasks,
          ...v.project_children,
          ...v.sku_children,
        )
        .map((t) => t.scopes),
    );
  }, [company.companyId, listTaskParent, search.value]);
  useEffect(() => {
    const timer = setTimeout(() => {
      refetch({
        filter: {
          query_string: searchStr,
          tag_ids: selectedTags.length
            ? selectedTags.map((t) => t.tag_id)
            : undefined,
        },
        scope: {
          Company: company.companyId,
        },
        imageSize: ImageSize.S32,
      });
      setSearchedStr(searchStr);
    }, 500);
    return () => clearTimeout(timer);
  }, [company.companyId, refetch, searchStr, selectedTags]);
  const setTagFocus = useCallback((v: boolean) => {
    if (v) {
      tagsInputRef.current?.blur();
      tagsListBoxRef.current?.blur();
      searchInputRef.current?.focus();
    } else {
      searchInputRef.current?.blur();
      tagsInputRef.current?.focus();
      tagsListBoxRef.current?.focus();
    }
  }, []);
  const closeTags = useMemo(() => {
    const v = !addingTags && !selectedTags.length;
    return v;
  }, [addingTags, selectedTags.length]);
  const searchOnBlur = useCallback(() => {
    if (!addingTags) {
      setTagFocus(true);
    } else {
      setTagFocus(false);
    }
  }, [addingTags, setTagFocus]);
  useEffect(() => {
    if (addingTags) {
      setTagFocus(false);
    } else {
      setTagFocus(true);
    }
  }, [addingTags, setTagFocus]);

  return (
    <Modal
      open={props.open}
      onClose={onClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "1rem",
          padding: "0.75rem",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          alignContent: "stretch",
          gap: "0.75rem",
          width: "36rem",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            alignContent: "stretch",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <SearchInput
              value={searchStr}
              onChange={setSearchStr}
              placeholder={t("search.searchPlaceholder")}
              removeBorder
              ref={searchInputRef}
              onBlur={searchOnBlur}
              autoFocus
            />
            <Button
              sx={KEY_BIND_BUTTON_SX}
              onClick={() =>
                setAddingTags((prev) => {
                  return !prev;
                })
              }
            >
              {addingTags
                ? t("search.name")
                : selectedTags.length
                  ? t("search.addTags")
                  : t("search.editTags")}
              <KeyBindWrapper
                keyLetter="Tab"
                callback={() => {
                  setAddingTags((prev) => {
                    return !prev;
                  });
                }}
              />
            </Button>
          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: "35rem",
              paddingRight: "0.5rem",
              paddingLeft: "2.25rem",
            }}
          >
            <AutocompleteMultiple
              ref={tagsInputRef}
              listBoxRef={tagsListBoxRef}
              value={selectedTags}
              options={tags ?? []}
              placeholder="Select tags..."
              getOptionLabel={(option) => option.name}
              handleOnChange={setSelectedTags}
              isLoading={!!tags}
              noBorder={true}
              inputWrapper={true}
              disableBlurOnSelect
              onBlur={() => {
                setAddingTags(false);
              }}
              onFocus={() => {
                setAddingTags(true);
              }}
              disableRender={closeTags}
              tagsContainerSx={{
                minHeight: "1.25rem",
              }}
              tagSx={{
                maxHeight: "1.25rem",
                minHeight: "1.25rem",
                paddingY: "0",
                span: {
                  lineHeight: "1",
                },
              }}
            />
          </Box>
        </Box>

        <LineDivider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
              alignContent: "stretch",
              gap: "0.75rem",
            }}
          >
            {(Object.keys(SECTIONS) as SectionKey[]).map((k) => (
              <Section
                t={t}
                count={sectionsCount[k]}
                key={k}
                sKey={k as SectionKey}
                selectedSection={section}
                callback={(k) => {
                  setSection(k);
                  setSelected(undefined);
                  listRef.current?.scrollTo(0);
                }}
              />
            ))}
          </Box>
          <LineDivider margin={0} />
        </Box>
        {items && itemsFromParent && search.value && !search.loading ? (
          items.length > 0 || itemsFromParent.length > 0 ? (
            <VariableSizeList
              height={268}
              width="100%"
              ref={listRef}
              itemSize={rowSize}
              itemCount={items.length + itemsFromParent.length}
              overscanCount={5}
              style={{
                maxWidth: "35rem",
              }}
            >
              {renderRow}
            </VariableSizeList>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: 268,
                gap: "1rem",
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <IconSearchOff size="2rem" color={Colors.BLUE_GRAY} />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "16px",
                  color: Colors.BLUE_GRAY,
                }}
              >
                {t("search.noSearchResults")}
              </Typography>
              <Typography
                sx={{
                  color: Colors.BLUE_GRAY,
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                {t("search.noSearchResultsDescription")}
              </Typography>
            </Box>
          )
        ) : (
          <Box sx={{ width: "100%", height: 268 }}>
            <LoadingSpinner />
          </Box>
        )}
        <LineDivider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "space-between",
            height: "1rem",
            padding: "0.5rem",
            width: "100%",
          }}
        >
          <img
            src="/logo/logo.png"
            style={{
              filter: "invert(1)",
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "0.75rem",
            }}
          >
            <Box sx={BOTTOM_COMMAND_SX}>
              {t("search.navigate")}
              <KeyBindWrapper
                keyLetter="ArrowDown"
                callback={() => {
                  if (!addingTags) {
                    changeSelection(1);
                  }
                }}
              />
              <KeyBindWrapper
                keyLetter="ArrowUp"
                callback={() => {
                  if (!addingTags) {
                    changeSelection(-1);
                  }
                }}
              />
            </Box>
            <Box
              sx={{
                borderRadius: "1px",
                height: "1rem",
                width: "1px",
                backgroundColor: Colors.BLUE_WHITE,
              }}
            />
            <Button
              sx={KEY_BIND_BUTTON_SX}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!addingTags) {
                  searchInputRef.current?.blur();
                  goToItem();
                }
              }}
            >
              {t(addingTags ? "search.addTags" : "search.launch")}
              <KeyBindWrapper
                keyLetter="Enter"
                callback={() => {
                  if (!addingTags) {
                    searchInputRef.current?.blur();
                    goToItem();
                  }
                }}
              />
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};
