import { SvgIcon } from "@mui/material";
import { CustomIconProps } from ".";

export function SelectIcon(props: CustomIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="8"
        height="8"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.33398 6.00065L8.00065 3.33398L10.6673 6.00065M10.6673 10.0007L8.00065 12.6673L5.33398 10.0007"
          stroke="currentColor"
          stroke-width="1.25"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
