import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { Container, ContainerContent } from "../UI";
import {
  Information,
  Success,
  Primary,
  Notification,
  Error,
  Warning,
} from "../icons";
import { ToastText } from "./styles";

type ToastType =
  | "info"
  | "notification"
  | "success"
  | "error"
  | "warning"
  | "primary";

type ToastProps = {
  title: string;
  description: string | React.ReactNode;
  type: ToastType;
  sx?: SxProps<Theme>;
};

const iconStyle = {
  width: "1rem",
  height: "1rem",
};

const toastIcon: Record<ToastType, React.ReactNode> = {
  info: <Information style={iconStyle} />,
  notification: <Notification style={iconStyle} />,
  success: <Success style={iconStyle} />,
  error: <Error style={iconStyle} />,
  warning: <Warning style={iconStyle} />,
  primary: <Primary style={iconStyle} />,
};

export function ToastItem({ title, description, type, sx }: ToastProps) {
  return (
    <Container style={{ borderRadius: "0.5rem" }} sx={sx}>
      <ContainerContent>
        {toastIcon[type]}
        <ContainerContent
          style={{ padding: 0, flexDirection: "column", gap: "0.5rem" }}
        >
          <ToastText style={{ fontWeight: 600 }}>{title}</ToastText>
          <ToastText>{description}</ToastText>
        </ContainerContent>
      </ContainerContent>
    </Container>
  );
}
