import { styled } from "@mui/material";
import Colors from "../../../utils/ColorConstants";

export const ContainerTitleAction = styled("div")`
  display: flex;
  height: 1.25rem;
  padding: 0.25rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    color: ${Colors.BLUE_GRAY};
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
