import React from "react";
import { setContext } from "@apollo/client/link/context";

import { useNavigate } from "react-router-dom";
import { RetryLink } from "@apollo/client/link/retry";
import {
  getCognitoUser,
  getAccessToken,
  UserAttributes,
  getLocalUserAttributes,
} from "../auth/auth-cognito";
import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  from,
  split,
} from "@apollo/client";
import { CognitoUserContext } from "./contexts/cognito-user";
import { onError } from "@apollo/client/link/error";
import UserRoute from "./user-route";
import LoadingSpinner from "../loading-spinner";
import { useScopeInvite } from "../gql-hooks/useScopeInvite";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
interface PrivateRouteProps {
  children: React.ReactNode;
  withoutUser?: boolean;
}
const url = import.meta.env.VITE_aws_api_gateway_endpoint as string;
const HTTP_LINK = new HttpLink({
  uri: url,
});
const WS_LINK = new GraphQLWsLink(
  createClient({
    url: `ws${url.includes("https://") ? "s" : ""}://` + url.split("://")[1],
    connectionParams: async () => {
      const origin = window.location.origin;
      const token = await getAccessToken();
      return {
        Authorization: token ? `Bearer ${token}` : "",
        Origin: origin,
      };
    },
  }),
);

const AUTH_LINK = setContext(async (_, { headers }) => {
  const token = await getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const SPLIT_LINK = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  WS_LINK,
  HTTP_LINK,
);

const ERROR_LINK = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const { message, locations, path, extensions } of graphQLErrors) {
        if (extensions?.code === "UNAUTHORIZED") {
          return forward(operation);
        }
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      }
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      return forward(operation);
    }
  },
);

const RETRY_LINK = new RetryLink({
  delay: {
    initial: 300,
    max: 20000,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error) => !!error,
  },
});
const CLIENT = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([ERROR_LINK, RETRY_LINK, AUTH_LINK, SPLIT_LINK]),
});

export function PrivateRoute({ children, withoutUser }: PrivateRouteProps) {
  const navigate = useNavigate();
  const [cognitoUser, setCognitoUser] = React.useState<
    UserAttributes | undefined
  >(getLocalUserAttributes());
  const invite = useScopeInvite();

  React.useEffect(() => {
    if (!cognitoUser && !invite.invite) {
      navigate("/signin");
    }
  }, [navigate, cognitoUser, invite.invite]);

  React.useEffect(() => {
    async function fetchUser() {
      const user = await getCognitoUser(true);
      setCognitoUser(user ?? undefined);
    }
    fetchUser();
    getAccessToken();
  }, [navigate]);

  if (!cognitoUser && !invite.invite) {
    return <LoadingSpinner />;
  }

  return (
    <CognitoUserContext.Provider
      value={{ cognitoUser: cognitoUser!, setCognitoUser }}
    >
      <ApolloProvider client={CLIENT}>
        {withoutUser || (invite.invite && !cognitoUser) ? (
          children
        ) : (
          <UserRoute>{children}</UserRoute>
        )}
      </ApolloProvider>
    </CognitoUserContext.Provider>
  );
}

export default PrivateRoute;
