import { TaskType, TaskStatsType } from "../gql/graphql";

export function parseJson<T>(json: unknown): T | undefined {
  if (!json || typeof json !== "string") {
    return undefined;
  }

  try {
    return JSON.parse(json) as T;
  } catch (e) {
    return undefined;
  }
}

export function debounce(func: () => void, delay: number) {
  let timeoutId: NodeJS.Timeout;
  return function () {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(), delay);
  };
}

export function clearLocalStorage() {
  const scopeInvite = localStorage.getItem("scope_invite");
  const lang = localStorage.getItem("i18nextLng");
  localStorage.clear();
  if (scopeInvite) {
    localStorage.setItem("scope_invite", scopeInvite);
  }
  if (lang) {
    localStorage.setItem("i18nextLng", lang);
  }
}

export function taskTypeToTaskStatsType(type: TaskType): TaskStatsType;
export function taskTypeToTaskStatsType(
  type?: TaskType,
): TaskStatsType | undefined;
export function taskTypeToTaskStatsType(
  type?: TaskType,
): TaskStatsType | undefined {
  if (!type) return undefined;
  switch (type) {
    case TaskType.Project:
      return TaskStatsType.Project;
    case TaskType.Sku:
      return TaskStatsType.Sku;
    case TaskType.Part:
      return TaskStatsType.Part;
    default:
      return TaskStatsType.Task;
  }
}
