import { useSidebarDesktop } from "../../context/SidebarDesktopContext";
import { ExportNotification } from "./ExportNotification";
import Header from "./Header";
import Menu from "./Menu";
import { useLogRocket } from "../../gql-hooks/useRecordSession";
import { Container } from "./styles";
import { ReactNode } from "react";

export type SidebarSubmenu = {
  label: ReactNode;
  route: string;
  icon: ReactNode;
};

export type SidebarMenu = {
  label: ReactNode;
  icon: ReactNode;
  route?: string;
  submenus?: SidebarSubmenu[];
};

function SidaberV2() {
  const { isCollapsed } = useSidebarDesktop();
  const width = isCollapsed ? "3.5rem" : "12rem";
  useLogRocket();

  return (
    <Container sx={{ minWidth: width, maxWidth: width, padding: "0.5rem" }}>
      <Header isCollapsed={isCollapsed} />
      <Menu isCollapsed={isCollapsed} />
      <ExportNotification />
    </Container>
  );
}

export default SidaberV2;
