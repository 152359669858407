import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import { Amplify } from "aws-amplify";
import { datadogLogs } from "@datadog/browser-logs";
import LogRocket from "logrocket";

LogRocket.init("mqv8hv/wrapt");

Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: import.meta.env.VITE_aws_cognito_identity_pool_id,
      userPoolId: import.meta.env.VITE_aws_user_pools_id,
      userPoolClientId: import.meta.env.VITE_aws_user_pools_web_client_id,
      signUpVerificationMethod: "code",
      loginWith: {
        oauth: {
          domain: import.meta.env.VITE_aws_domain_cognito,
          scopes: [
            "email",
            "profile",
            "openid",
            "aws.cognito.signin.user.admin",
          ],
          redirectSignIn: [import.meta.env.VITE_aws_amplify_endpoint + "/home"],
          redirectSignOut: [
            import.meta.env.VITE_aws_amplify_endpoint + "/signin",
          ],
          responseType: "code",
        },
      },
    },
  },
  // region: import.meta.env.VITE_aws_project_region,
  // identityPoolRegion: import.meta.env.VITE_aws_cognito_region,
});
if (import.meta.env.VITE_datadog_client_token)
  datadogLogs.init({
    clientToken: import.meta.env.VITE_datadog_client_token,
    site: "datadoghq.com",
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });

ReactDOM.createRoot(document.getElementById("root")!).render(<App />);
