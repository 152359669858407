import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import useCognitoUser from "./contexts/cognito-user";
import { graphql } from "../gql";
import { ImageSize, Role, User } from "../gql/graphql";
import { UserContext } from "./contexts/user";
import LoadingSpinner from "../loading-spinner";
import { getAccessToken, getCognitoUser } from "../auth/auth-cognito";
import { CREATE_USER, UPDATE_USER } from "../gql-hooks";
import { useToast } from "../gql-hooks/useToast";
import { useMutationRequest } from "../gql-hooks/queries";
import { useTranslation } from "react-i18next";
import { LanguageCode, getLanguageFromUsr } from "../utils/language";

const GET_CURRENT_USER = graphql(`
  query Query($imageSize: ImageSize) {
    user(image_size: $imageSize) {
      user_id
      email
      first_name
      last_name
      user_role_id
      phone_number
      created_at
      updated_at
      has_profile_picture
      settings {
        timezone_offset_in_minutes
        language
        reminders {
          tasks_time_before_deadline_in_days
          attachment_approvals_frequency_in_days
        }
      }
      presigned_url
      put_presigned_url
    }
  }
`);

export function UserRoute({ children }: { children: React.ReactNode }) {
  const { i18n } = useTranslation();
  const { cognitoUser, setCognitoUser } = useCognitoUser();
  const [role, setRole] = useState<Role | null | undefined>(undefined);
  const [hasRun, setHasRun] = useState(false);
  const { show, toast } = useToast({ timeout: 5000 });
  const key = useMemo(() => {
    return `user:${cognitoUser["custom:id"]}`;
  }, [cognitoUser]);
  const userId = cognitoUser["custom:id"];
  const [user, rawSetUser] = useState<User | undefined>(
    JSON.parse(localStorage.getItem(key) || "null") ||
      (undefined as User | undefined),
  );
  const { setInput: setUpdateUserInput } = useMutationRequest({
    query: UPDATE_USER,
  });
  const setUser: React.Dispatch<React.SetStateAction<User | undefined | null>> =
    React.useCallback(
      (user: React.SetStateAction<User | undefined | null>) => {
        if (!user) return;
        rawSetUser((prev) => {
          if (typeof user === "function") {
            user = user(prev);
          }
          if (!user) return prev;
          localStorage.setItem(key, JSON.stringify(user));
          return user;
        });
      },
      [rawSetUser, key],
    );

  const [run] = useLazyQuery(GET_CURRENT_USER);

  const [isCreatingUser, setIsCreatingUser] = React.useState(false);

  const { run: createUserCallback } = useMutationRequest({
    query: CREATE_USER,
    onError: (err) => {
      const error = err.graphQLErrors.at(0);
      if (
        error?.extensions?.code === "BAD_USER_INPUT" &&
        error.message === "Invalid invitation code"
      ) {
        show(
          {
            title: "Invalid Invitation Code",
            description: "Please contact your administrator and try again.",
            type: "Error",
          },
          () => {
            window.location.href = "/invitation-code";
          },
        );
      }
    },
  });

  const refetchCognitoUser = useCallback(async () => {
    setCognitoUser((await getCognitoUser(true)) ?? undefined);
  }, [setCognitoUser]);

  useEffect(() => {
    async function fetchUser() {
      if (userId) {
        return;
      }
      if (isCreatingUser || !hasRun) {
        return;
      }

      setIsCreatingUser(true);

      await createUserCallback({
        input: {
          first_name: cognitoUser.given_name || "",
          last_name: cognitoUser.family_name || "",
          user_role_id:
            cognitoUser["custom:role_id"] &&
            cognitoUser["custom:role_id"] != "999"
              ? Number(cognitoUser["custom:role_id"])
              : undefined,
          phone_number: cognitoUser.phone_number,
          invitation_code: cognitoUser["custom:invitation_code"],
          settings: {
            language: cognitoUser["custom:lang_code"] ?? "en",
          },
        },
      });
      await refetchCognitoUser();
      setIsCreatingUser(false);
    }
    fetchUser();
  }, [
    cognitoUser,
    createUserCallback,
    hasRun,
    isCreatingUser,
    refetchCognitoUser,
    show,
    userId,
  ]);

  const fetchUser = useCallback(async () => {
    const token = await getAccessToken();
    if (!token) return { data: null };
    const [first, ..._rest] = await Promise.all([
      run({ variables: { imageSize: ImageSize.S32 } }),
      refetchCognitoUser(),
    ]);
    setUser(first.data?.user);
    refetchCognitoUser().then(() => {
      setHasRun(true);
    });
    return first;
  }, [refetchCognitoUser, run, setUser]);

  useEffect(() => {
    const timezoneOffset = new Date().getTimezoneOffset();
    if (user)
      getLanguageFromUsr(
        i18n,
        user.settings?.language as LanguageCode | undefined | null,
      );
    if (
      hasRun &&
      user &&
      (user.settings?.timezone_offset_in_minutes == null ||
        user.settings?.timezone_offset_in_minutes != timezoneOffset)
    ) {
      setUpdateUserInput({
        input: {
          settings: {
            ...user.settings,
            timezone_offset_in_minutes: timezoneOffset,
          },
        },
      });
    }
    if (hasRun) return;
    fetchUser();
  }, [fetchUser, hasRun, setUpdateUserInput, user, userId]);

  if (!userId) {
    return (
      <>
        {toast}
        <LoadingSpinner />
      </>
    );
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        role,
        setRole,
        isAdmin: role?.name === "Admin",
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default UserRoute;
