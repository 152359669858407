import { Button, styled } from "@mui/material";

export const ContainerStyle = styled("div")`
  display: "flex";
  width: "100%";
  display: flex;
  flex-direction: row;
`;

export const SidebarDesktopStyledImage = styled("img")`
  width: 186.14px;
  user-select: none;
`;

export const SidebarDesktopToggleButton = styled(Button)`
  width: 2rem;
  min-width: 0;
  border: 1px solid rgba(39, 42, 62, 1);
  border-radius: 0.5rem;
  gap: 0.5rem;
  z-index: 3;
`;
