import { Button, Box, Skeleton, Grid, tooltipClasses } from "@mui/material";
import { useMemo } from "react";
import { KeyBindWrapper } from "../../../KeyBind";
import { LineDivider } from "./line-divider";
import { SectionKey, SECTIONS } from "./types";
import Colors from "../../../../utils/ColorConstants";
import { CustomTooltip } from "../../../ChangeTooltip";
import { TFunction } from "i18next";

export const Section = <K extends SectionKey>(props: {
  sKey: K;
  selectedSection: SectionKey;
  callback: (k: K) => void;
  count: number | undefined;
  t: TFunction<"main", "sidebar">;
}) => {
  const { t } = props;
  const isSelected = useMemo(
    () => props.sKey === props.selectedSection,
    [props.sKey, props.selectedSection],
  );
  const key = useMemo(
    () => (Object.keys(SECTIONS).indexOf(props.sKey) + 1).toString(),
    [props.sKey],
  );
  const color = isSelected ? Colors.PURPLE_DARK : Colors.BLUE_GRAY;
  return (
    <Button
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "space-between",
        alignContent: "space-between",
        height: "1.6125rem",
        padding: "0",
        minWidth: "0",
        borderRadius: "0.5rem",
        color,
        fontWeight: isSelected ? 700 : 500,
        "&:hover": {
          backgroundColor: Colors.PURPLE_WHITE,
        },
      }}
      onClick={() => {
        props.callback(props.sKey);
      }}
    >
      <Box
        sx={{
          height: "1rem",
          display: "flex",
          flexdDirection: "row",
          gap: "0.25rem",
          alignItems: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <CustomTooltip
          placement="top"
          title={
            <KeyBindWrapper
              ctrlKey
              keyLetter={key}
              callback={() => {
                props.callback(props.sKey);
              }}
            />
          }
          sx={{
            [`& .${tooltipClasses.tooltip}`]: {
              padding: "0.25rem",
            },
          }}
        >
          <Grid container direction="row" gap={1} alignItems="center">
            {t(`search.${props.sKey}` as "search.all")}
            <Grid
              item
              color={color}
              sx={{
                color: isSelected ? Colors.PURPLE_WHITE : Colors.PURPLE_DARK,
                backgroundColor: isSelected
                  ? Colors.PURPLE_DARK
                  : Colors.PURPLE_WHITE,
                fontSize: "10px",
                fontWeight: 700,
                minWidth: "1rem",
                maxHeight: "1rem",
                paddingX: "0.25rem",
                minHeight: "1rem",
                lineHeight: "1rem",
                borderRadius: "0.25rem",
                zIndex: 0,
              }}
            >
              {props.count ?? (
                <Skeleton
                  variant="rounded"
                  width="0.5rem"
                  height="0.75rem"
                  sx={{
                    borderRadius: "3px",
                    marginTop: "0.125rem",
                    backgroundColor: isSelected
                      ? Colors.PURPLE_WHITE
                      : Colors.PURPLE_DARK + "50",
                  }}
                />
              )}
            </Grid>
          </Grid>
        </CustomTooltip>
      </Box>
      <Box
        sx={{
          width: "100%",
          marginBottom: "-1px",
        }}
      >
        {isSelected && (
          <LineDivider margin={0} color={Colors.PURPLE_DARK} height={2} />
        )}
      </Box>
    </Button>
  );
};
