import React, { useCallback, useContext, useEffect, useState } from "react";
import { ACCEPT_INVITE } from "../gql-hooks/useInvite";
import LoadingSpinner from "../loading-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { useScopeInvite } from "../gql-hooks/useScopeInvite";
import { useMutationRequest } from "../gql-hooks/queries";
import { CompanyMember } from "../gql/graphql";
import { CognitoUserContext } from "./contexts/cognito-user";
import { useToast } from "../gql-hooks/useToast";
import { CREATE_USER } from "../gql-hooks/useUsers";

export const InviteRoute = ({ children }: { children: React.ReactNode }) => {
  const scopeInvite = useScopeInvite();
  const navigate = useNavigate();
  const [creatingUser, setCreatingUser] = useState(false);
  const { cognitoUser, setCognitoUser } = useContext(CognitoUserContext) ?? {};
  const location = useLocation();
  const { show, toast } = useToast({
    timeout: 5000,
  });
  const [ableToRun, setAbleToRun] = useState(false);
  const { setInput: createUser } = useMutationRequest({
    query: CREATE_USER,
    onSuccess: (data) => {
      if (!cognitoUser || !setCognitoUser) return;
      setCognitoUser({
        ...cognitoUser,
        "custom:id": data?.createUser.user_id,
      });
      setAbleToRun(true);
    },
  });

  const redirect = useCallback(
    (setCompany?: CompanyMember) => {
      if (!cognitoUser) localStorage.clear();
      scopeInvite.clear();
      if (setCompany) {
        localStorage.setItem(
          `companyId:${setCompany.user_id}`,
          setCompany.company_id,
        );
      }
      if (cognitoUser && location.pathname !== "/home") navigate("/home");
      else if (!cognitoUser && location.pathname !== "/signin")
        navigate("/signin");
    },
    [cognitoUser, location.pathname, navigate, scopeInvite],
  );

  useEffect(() => {
    if (!scopeInvite.invite) {
      return;
    }
    if (ableToRun || creatingUser) return;
    if (
      !cognitoUser ||
      !setCognitoUser ||
      scopeInvite.invite.path !== "signup"
    ) {
      setAbleToRun(true);
      return;
    }
    if (cognitoUser.email !== scopeInvite.invite.user_email) {
      show(
        {
          title: "Error",
          description: `Your email does not match the invite, the invite email is ${scopeInvite.invite.user_email}.`,
          type: "Error",
        },
        () => {
          localStorage.clear();
          scopeInvite.save();
          const params = scopeInvite.getParams();
          navigate(`/${scopeInvite.invite!.path}?${params}`);
        },
      );
      return;
    }
    setCreatingUser(true);
    createUser({
      input: {
        first_name: cognitoUser.given_name || "",
        last_name: cognitoUser.family_name || "",
        user_role_id:
          cognitoUser["custom:role_id"] &&
          cognitoUser["custom:role_id"] != "999"
            ? Number(cognitoUser["custom:role_id"])
            : undefined,
        phone_number: cognitoUser.phone_number,
        invitation_code: cognitoUser["custom:invitation_code"]?.length
          ? cognitoUser["custom:invitation_code"]
          : scopeInvite.invite.invitation_code,
      },
    });
  }, [
    ableToRun,
    cognitoUser,
    createUser,
    creatingUser,
    navigate,
    scopeInvite,
    setCognitoUser,
    show,
  ]);
  const { setInput, loading } = useMutationRequest({
    query: ACCEPT_INVITE,
    onSuccess: (data) => {
      show(
        {
          title: "Success",
          description: `You have successfully joined ${scopeInvite.invite?.company_name}.`,
          type: "Success",
        },
        () => {
          redirect(data.acceptInvite);
        },
      );
    },
    onError: (error) => {
      console.error(error);
      redirect();
    },
  });

  useEffect(() => {
    if (loading || !ableToRun || !scopeInvite.invite) {
      return;
    }

    const data = scopeInvite.invite.data;
    const userData = data && {
      ...data,
      user_role_id: data.user_role_id === 999 ? undefined : data.user_role_id,
      phone_number: data.phone_number?.length ? data.phone_number : undefined,
      settings: {
        language: data.language ?? "en",
      },
    };
    if (userData) {
      delete userData.language;
    }

    setInput({
      input: {
        invitation_token: scopeInvite.invite.invitation_token,
        user_data: userData,
      },
    });
    setAbleToRun(false);
  }, [scopeInvite, loading, ableToRun, setInput]);
  if ((scopeInvite.invite && !loading) || loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {children}
      {toast}
    </>
  );
};
