import { ButtonBase, Dialog, styled } from "@mui/material";
import Colors, {
  BorderRadii,
  Spacing,
  border,
} from "../../utils/ColorConstants";
import { TypographyBody, TypographyHeaderMedium } from "../Typography";
import { Link } from "react-router-dom";

export const ReferenceContainer = styled(TypographyBody)`
  padding: 3px ${Spacing.LARGE};
  display: flex;
  gap: ${Spacing.SMALL};
  align-items: center;
  border: ${border};
  border-radius: ${BorderRadii.LARGE};
  svg {
    width: 0.75rem;
    height: 0.75rem;
  }
`;

export const ReferenceByContainer = styled("div")<{ fixedwidth?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${Spacing.LARGE};
  ${({ fixedwidth }) => (fixedwidth ? "min-width: 17rem" : "width: 100%")};
  padding: ${Spacing.MEDIUM};
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.text.secondary};
  :hover {
    text-decoration: underline;
  }
  display: inherit;
  align-items: inherit;
  flex-direction: inherit;
  justify-content: inherit;
  gap: inherit;
  align-content: inherit;
`;

export const ViewMoreButton = styled(ButtonBase)`
  width: 100%;
  justify-content: flex-start;
  gap: ${Spacing.SMALL};
  padding: ${Spacing.MEDIUM};
  svg {
    width: 1rem;
    height: 1rem;
    color: ${Colors.DEFAULT};
  }
  border-radius: ${BorderRadii.MEDIUM};
`;

export const ViewMoreDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: ${BorderRadii.LARGE};
    padding: ${Spacing.LARGE};
    gap: ${Spacing.LARGE};
    width: 24rem;
  }
`;

export const ViewMoreDialogTitle = styled(TypographyHeaderMedium)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
