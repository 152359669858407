import { Avatar, Menu, MenuItem, Typography } from "@mui/material";
import useCompany from "../../guards/contexts/company";
import { useSidebarDesktop } from "../../context/SidebarDesktopContext";
import { Preconnect } from "../utils/preconnect";
import Colors from "../../utils/ColorConstants";

interface CompanyMenuProps {
  menuOpen: boolean;
  onClose?: () => void;
}

function CompanyMenu(props: CompanyMenuProps) {
  const { setCompanyId, companyId } = useCompany();
  const { companies } = useCompany();
  const { isCollapsed } = useSidebarDesktop();

  // const [modalCompany, setModalCompany] = useState(false);

  const handleClose = () => {
    props.onClose && props.onClose();
  };

  function handleCompanyClick(companyId: string) {
    setCompanyId(companyId); // Chamando a função do contexto diretamente
    window.location.href = "/home";
  }

  return (
    <Menu
      id="menu-appbar"
      anchorEl={props.menuOpen ? document.body : undefined}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        width: "100%",
        marginLeft: isCollapsed ? "48px" : "12rem",
        "& .MuiPaper-root": {
          borderRadius: "16px",
          width: "192px",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "0px",
          paddingTop: "8px",
          paddingBottom: "8px",
        },
        "& .MuiMenu-list": {
          padding: "0px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        },
      }}
      open={props.menuOpen}
      onClose={handleClose}
    >
      {Array.from(companies?.values() || []).map((companyValue) => {
        const selected = companyValue?.company_id === companyId;

        return (
          <MenuItem
            key={companyValue.company_id}
            onClick={() => handleCompanyClick(companyValue.company_id)}
            sx={{
              width: "95%",
              borderRadius: "8px",
              backgroundColor: selected ? Colors.PURPLE_WHITE : "transparent",
              gap: "9px",
              ":hover": {
                backgroundColor: Colors.BACKGROUND,
              },
            }}
          >
            <Preconnect url={companyValue.presigned_url} />
            <Avatar
              variant="square"
              sx={{
                width: "20px",
                height: "20px",
                fontSize: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "2px",
                backgroundColor: companyValue?.presigned_url
                  ? Colors.WHITE
                  : Colors.PURPLE_DARK,
              }}
              src={companyValue?.presigned_url || ""}
            >
              {companyValue?.presigned_url ? "" : companyValue?.name?.charAt(0)}
            </Avatar>
            <Typography
              sx={{
                fontWeight: "600",
                font: "14px/19.12px Manrope",
                color: selected ? Colors.HIGHLIGHTED : Colors.DEFAULT,
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                maxWidth: "100%",
              }}
            >
              {companyValue.name}
            </Typography>
          </MenuItem>
        );
      })}
    </Menu>
  );
}

export default CompanyMenu;
