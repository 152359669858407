import React from "react";
import { Role, User } from "../../gql/graphql";

export type UserContextValue = {
  user: User | undefined;
  setUser: React.Dispatch<React.SetStateAction<User | undefined | null>>;
  role: Role | undefined | null;
  setRole: React.Dispatch<React.SetStateAction<Role | undefined | null>>;
  isAdmin: boolean;
};

export const UserContext = React.createContext<UserContextValue | undefined>({
  user: undefined,
  setUser: () => {},
  role: undefined,
  setRole: () => {},
  isAdmin: false,
});

export const useUser = (): UserContextValue => {
  const ctx = React.useContext(UserContext);
  return ctx!;
};

export default useUser;
