import { Box, Stack, Typography, Link } from "@mui/material";
import Colors, {
  border,
  FontSizes,
  FontWeights,
  Spacing,
} from "../../utils/ColorConstants";
import {
  TypographyBody,
  TypographyHeaderMedium,
  TypographyHeaderSmall,
} from "../Typography";

export function NotFound() {
  return (
    <Stack
      direction="column"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <img
        src="logo/logo-negative-dark.png"
        alt="Wrapt Logo"
        style={{
          width: "10rem",
          marginTop: Spacing.XLARGE,
          marginBottom: Spacing.XLARGE,
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: Spacing.XLARGE,
          gap: "1rem",
          textAlign: "center",
          alignItems: "center",
          width: "100%",
          maxWidth: "30rem",
          justifyContent: "center",
          marginBottom: "10rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: Spacing.XLARGE,
            border: border,
            borderRadius: "50%",
            padding: Spacing.XLARGE,
            width: "10rem",
            height: "10rem",
            marginBottom: Spacing.XLARGE,
          }}
        >
          <TypographyHeaderSmall
            sx={{
              fontSize: "2rem",
              textAlign: "center",
            }}
          >
            404
          </TypographyHeaderSmall>
        </Box>
        <TypographyHeaderMedium
          variant="h1"
          sx={{
            fontSize: "3rem",
            marginBottom: Spacing.XLARGE,
            textAlign: "center",
          }}
        >
          Page not found
        </TypographyHeaderMedium>
        <TypographyBody
          variant="body1"
          sx={{
            fontSize: FontSizes.MEDIUM,
            marginBottom: Spacing.XLARGE,
            textAlign: "center",
            lineHeight: "1.5",
          }}
        >
          We couldn't find the page you were looking for. You can return to{" "}
          <Link
            sx={{
              color: Colors.PURPLE_DARK,
              textDecoration: "none",
            }}
            href="/"
          >
            home page
          </Link>
        </TypographyBody>
      </Box>
      <Box
        sx={{
          display: "flex",
          textAlign: "center",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          padding: Spacing.XLARGE,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            fontSize: FontSizes.SMALL,
            fontWeight: FontWeights.BOLD,
            color: Colors.LIGHT3,
          }}
        >
          Copyright © 2024 ·{" "}
          <Link
            sx={{
              fontSize: FontSizes.SMALL,
              fontWeight: FontWeights.BOLD,
              color: Colors.PURPLE_DARK,
              textDecoration: "none",
            }}
            href="/"
          >
            Wrapt.com
          </Link>{" "}
          · All Rights Reserved
        </Typography>
      </Box>
    </Stack>
  );
}
