import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import { ReactNode } from "react";
import { theme } from "../styles/theme";

type ThemeProviderProps = {
  children: ReactNode;
};

export function MuiThemeProvider({ children }: ThemeProviderProps) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
