import { createTheme } from "@mui/material/styles";
import Colors, { BorderRadii } from "../utils/ColorConstants";
export function getStepStyle(index: number, currentStep: number) {
  const isCompleted = index < currentStep;
  const isCurrent = index === currentStep;

  return {
    "& .MuiStepIcon-root": {
      color: isCompleted ? Colors.PURPLE_DARK : Colors.BLUE_GRAY,
      border: isCompleted
        ? undefined
        : `1px solid ${isCurrent ? Colors.PURPLE_DARK : Colors.WHITE}`,
      borderRadius: "50%",
      margin: "4px",
      alignItems: "center",
      fill: isCompleted ? Colors.PURPLE_DARK : isCurrent ? "white" : "white",
      background: isCompleted ? "white" : "white",
    },
    "& .MuiStepIcon-text": {
      fontSize: "14px",
      fontWeight: "600",
      fill: isCompleted
        ? Colors.PURPLE_DARK
        : isCurrent
          ? Colors.PURPLE_DARK
          : Colors.BLUE_GRAY,
    },
    "& .MuiStepLabel-label": {
      color: isCompleted ? Colors.DEFAULT : Colors.BLUE_GRAY,
    },
  };
}
export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.DEFAULT,
      light: Colors.LIGHT3,
      contrastText: Colors.PURPLE_HIGHLIGHTED,
    },
    secondary: {
      main: Colors.BLUE_GRAY,
      light: Colors.BACKGROUND_GRAY,
    },
    text: {
      primary: Colors.DEFAULT,
      secondary: Colors.BLUE_GRAY,
    },
  },
  typography: {
    fontFamily: "Manrope",
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          "line-height": "10px",
          // TODO: refactor to be able to use this
          // "box-sizing": "content-box",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          fontFamily: "Manrope",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: Colors.DEFAULT,
          borderRadius: "0.5rem",
          "&.Mui-selected": {
            backgroundColor: Colors.WHITE,
            color: Colors.PURPLE_DARK,
          },
          ":hover": {
            backgroundColor: Colors.WHITE,
            color: Colors.PURPLE_DARK,
          },
          "&.MuiPaginationItem-previousNext": {},
          "&.Mui-disabled": {
            color: Colors.BACKGROUND_GRAY,
            opacity: 1,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
        },
      },
    },

    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: "Manrope",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          lineHeight: "1",
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          borderRadius: BorderRadii.SMALL,
        },
      },
    },
  },
});
