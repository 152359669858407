import { AnyRecord } from "../gql-hooks";

export type Nullable<T> = {
  [K in keyof T]?: T[K] | null;
};

export const applyUpdate = <T extends AnyRecord>(
  data: T,
  update: Nullable<Omit<T, "__typename">> & {
    unset?: Omit<
      {
        [key in keyof T]?: boolean | null;
      },
      "__typename"
    > | null;
  },
) => {
  const { unset, ...rest } = update;
  const next = { ...data } as AnyRecord;
  for (const [key, value] of Object.entries(rest)) {
    if (key === "__typename") continue;
    if (value == null) continue;
    if (value == data[key]) continue;
    if (!Array.isArray(value) && typeof value === "object") {
      next[key] = {
        ...data[key],
        ...value,
        __typename: undefined,
      };
    } else {
      next[key] = value;
    }
  }

  if (unset) {
    Object.entries(unset)
      .filter(([_k, v]) => !!v)
      .forEach(([k, _v]) => {
        if (k === "__typename") return;
        delete next[k as string];
      });
  }
  return next as T;
};
