import { useMutation, useQuery } from "@apollo/client";
import { graphql } from "../gql";
import { useCallback, useEffect, useState } from "react";
import { Tag } from "../gql/graphql";
import Colors from "../utils/ColorConstants";

const CREATE_TAG = graphql(`
  mutation CreateTag($input: CreateTagInput!, $scope: ScopeInput!) {
    createTag(input: $input, scope: $scope) {
      company_id
      tag_id
      name
      color
      owner_id
      type
      created_at
      updated_at
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
  }
`);

export const LIST_TAGS = graphql(`
  query Tags(
    $scope: ScopeInput!
    $filter: TagFilter
    $pagination: PaginationInput
  ) {
    tags(scope: $scope, filter: $filter, pagination: $pagination) {
      company_id
      tag_id
      name
      color
      owner_id
      type
      created_at
      updated_at
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
  }
`);

export const useTags = (companyId: string, types?: string[]) => {
  const [tags, setTags] = useState<Array<Tag>>();
  const response = useQuery(LIST_TAGS, {
    variables: {
      scope: {
        Company: companyId,
      },
      filter: {
        types: types,
      },
    },
  });

  useEffect(() => {
    if (response.data?.tags) {
      setTags(response.data.tags);
    }
  }, [response.data]);

  return { ...response, tags, refetchTags: response.refetch };
};

export const useCreateTagProject = (company_id: string) => {
  const [createTag] = useMutation(CREATE_TAG);

  return useCallback(
    async (name: string, type?: string) => {
      const response = await createTag({
        variables: {
          input: {
            name: name,
            color: Colors.WHITE,
            type: type,
          },
          scope: {
            Company: company_id,
          },
        },
      });
      if (!response?.data?.createTag) {
        throw new Error("Failed to create tag");
      }
      return response?.data?.createTag;
    },
    [createTag, company_id],
  );
};
