import { For } from "../../../utils/For";
import { useEffect, useState } from "react";
import { END_OPTIONS } from "../contants";
import MenuItem from "../MenuItem";
import { useLocation } from "react-router-dom";

import {
  Avatar,
  Box,
  Divider,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import useCompany from "../../../guards/contexts/company";
import useUser from "../../../guards/contexts/user";
import {
  Archive,
  AssetsIcon,
  HistoryIcon,
  HomeIcon,
  ProjectsIcon,
  SelectIcon,
  SettingsIcon,
  TasksIcon,
} from "../../icons";
import CompanyMenu from "../../CompanyMenu";
import { ContainerCompany } from "../styles";
import { SearchBar } from "../SearchBar";
import Colors from "../../../utils/ColorConstants";
import { Preconnect } from "../../utils/preconnect";
import { Version } from "./styles";
import { signOut } from "aws-amplify/auth";
import { clearLocalStorage } from "../../../utils";
import { useTranslation } from "react-i18next";
import { IconFileLike } from "@tabler/icons-react";
import { TasksBadge } from "../TasksBadge";

const versionNumber = "2.0.1";

// see this: https://docs.aws.amazon.com/amplify/latest/userguide/environment-variables.html
const buildNumber = Number(import.meta.env.VITE_aws_job_id ?? "0");
const branch = import.meta.env.VITE_aws_branch ?? "default";

type MenuProps = {
  isCollapsed: boolean;
};

function Menu({ isCollapsed }: MenuProps) {
  const { t } = useTranslation("main", { keyPrefix: "sidebar" });
  const sidebarMenus = [
    {
      route: "/home",
      icon: <HomeIcon sx={{ width: "16px", height: "16px" }} />,
    },
    {
      route: "/tasks",
      icon: <TasksIcon sx={{ width: "16px", height: "16px" }} />,
      badge: TasksBadge,
    },
    {
      route: "/assets",
      icon: <AssetsIcon sx={{ width: "16px", height: "16px" }} />,
    },
    {
      route: "/project",
      icon: <ProjectsIcon sx={{ width: "16px", height: "16px" }} />,
    },
    {
      route: "/approvals",
      icon: <IconFileLike size="1rem" />,
    },
    {
      route: "/activity",
      icon: <HistoryIcon sx={{ width: "16px", height: "16px" }} />,
    },
    {
      route: "/archive",
      icon: <Archive sx={{ width: "16px", height: "16px" }} />,
    },
  ];

  const profileIndex = sidebarMenus.length + END_OPTIONS.length;
  const settingsIndex = sidebarMenus.length + END_OPTIONS.length + 2;
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(0);
  const { user, role } = useUser();
  const [menuCompany, setMenuCompany] = useState(false);
  const { company, companies } = useCompany();

  const location = useLocation();

  useEffect(() => {
    const storedVersion = localStorage.getItem("version");
    async function logout() {
      await signOut();
      clearLocalStorage();
      window.location.href = "/signin";
    }
    if (storedVersion && storedVersion !== versionNumber) {
      logout();
    } else if (!storedVersion) {
      localStorage.setItem("version", versionNumber);
    }
  }, [location]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const fromPage = queryParams.get("from");
    const path = fromPage ? `/${fromPage}` : location.pathname;
    const menuIndex = sidebarMenus.findIndex(
      (item) => item.route && path.startsWith(item.route),
    );
    if (menuIndex !== -1) {
      setSelectedIndex(menuIndex);
      return;
    }

    if (path.startsWith("/profile")) {
      setSelectedIndex(profileIndex);
    } else if (path.startsWith("/settings")) {
      setSelectedIndex(settingsIndex);
    } else {
      setSelectedIndex(undefined);
    }
  }, [location.pathname, location.search]);

  function renderSelectIcon() {
    if (!isCollapsed && (companies?.size || 0) > 1) {
      return (
        <SelectIcon
          style={{
            width: "16px",
            height: "16px",
            color: Colors.LIGHT3,
          }}
        />
      );
    }
    return null;
  }

  return (
    <Stack
      direction="column"
      width="100%"
      height="100%"
      spacing={1}
      marginTop="1rem"
      alignItems="center"
      display="flex-start"
    >
      <ContainerCompany
        isCollapsed={isCollapsed}
        onClick={() => {
          if ((companies?.size || 0) > 1) setMenuCompany(!menuCompany);
        }}
      >
        {!company ? (
          <>
            <Skeleton
              width={"20px"}
              height="20px"
              variant="rectangular"
              sx={{
                backgroundColor: "gray",
              }}
            />
            {!isCollapsed && (
              <Skeleton
                width="6rem"
                height="0.875rem"
                variant="rounded"
                sx={{
                  backgroundColor: "gray",
                }}
              />
            )}
          </>
        ) : (
          <>
            <Preconnect url={company.presigned_url} />
            <Avatar
              variant="square"
              sx={{
                width: "20px",
                height: "20px",
                fontSize: "0.5rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "2px",
                backgroundColor: company!.presigned_url
                  ? "white"
                  : Colors.PURPLE_DARK,
              }}
              src={company!.presigned_url || ""}
            >
              {company!.presigned_url ? "" : company!.name?.charAt(0)}
            </Avatar>
            {!isCollapsed && (
              <Typography
                sx={{
                  width: "100%",
                  fontSize: "0.875rem",
                  fontWeight: "600",
                  color: Colors.LIGHT3,
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {company?.name || "..."}
              </Typography>
            )}
            {renderSelectIcon()}
          </>
        )}
      </ContainerCompany>
      <Divider
        sx={{
          width: "90%",
          backgroundColor: Colors.DEFAULT,
          opacity: "1",
        }}
      />
      <SearchBar isCollapsed={isCollapsed} t={t} />
      <For each={sidebarMenus}>
        {(item, index) => (
          <MenuItem
            key={`sidebar-desktop-menu-${index}`}
            isCollapsed={isCollapsed}
            isSelected={selectedIndex === index}
            menu={{
              ...item,
              label: t(`menu.${item.route.slice(1)}` as "menu.home"),
            }}
            badge={item.badge?.() ?? undefined}
            onClick={() => setSelectedIndex(index)}
          />
        )}
      </For>
      <Box flexGrow={1} />
      <Divider
        sx={{
          width: "90%",
          backgroundColor: "rgba(115, 126, 148, 1)",
          opacity: "0.2",
        }}
      />
      {role?.name === "Admin" && (
        <MenuItem
          key={`sidebar-desktop-menu-${END_OPTIONS.length + 2}`}
          isCollapsed={isCollapsed}
          isSelected={selectedIndex === settingsIndex}
          menu={{
            icon: <SettingsIcon sx={{ width: "16px", height: "16px" }} />,
            label: t("settings"),
            route: "/settings/company-info",
          }}
          onClick={() => setSelectedIndex(sidebarMenus.length + 2)}
        />
      )}
      <MenuItem
        key={`sidebar-desktop-menu-${END_OPTIONS.length + 1}`}
        isCollapsed={isCollapsed}
        isSelected={selectedIndex === profileIndex}
        menu={{
          icon: !user ? (
            <Skeleton
              width="20px"
              height="20px"
              variant="circular"
              sx={{
                backgroundColor: "gray",
              }}
            />
          ) : (
            <>
              <Preconnect url={user.presigned_url} />

              <Avatar
                sx={{
                  width: "20px",
                  height: "20px",
                  fontSize: "0.5rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundColor: user!.presigned_url
                    ? "transparent"
                    : Colors.PURPLE_DARK,
                }}
                src={user!.presigned_url || ""}
              >
                {user!.presigned_url ? "" : user!.first_name.charAt(0)}
              </Avatar>
            </>
          ),
          label: !user ? (
            <Skeleton
              width="6rem"
              height="0.875rem"
              variant="rounded"
              sx={{
                backgroundColor: "gray",
              }}
            />
          ) : (
            user.first_name.split(" ")[0]
          ),
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setSelectedIndex(sidebarMenus.length + 1);
        }}
      />
      <CompanyMenu
        menuOpen={menuCompany}
        onClose={() => setMenuCompany(!menuCompany)}
      />
      <Version
        children={`${versionNumber} (${buildNumber})-${t(`environment.${branch}` as "environment.main")}`}
      />
    </Stack>
  );
}
export default Menu;
