import { useRef } from "react";
import { SearchIcon } from "../icons";
import { SearchContainer, SearchInputBase } from "./styles";
import { Box, ClickAwayListener } from "@mui/material";
import React from "react";

type SearchInputProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  disabled?: boolean;
  removeBorder?: boolean;
  autoFocus?: boolean;
  onBlur?: () => void;
};

export const SearchInput = React.forwardRef(
  (props: SearchInputProps, inputRef?: React.Ref<HTMLInputElement>) => {
    const ref = useRef<HTMLInputElement>(null);
    return (
      <ClickAwayListener
        onClickAway={(e) => {
          if ((e.target as Node).nodeName === "BODY") {
            return;
          }
          props.onBlur?.();
        }}
      >
        <SearchContainer
          onClick={() => {
            ref.current?.click();
          }}
          sx={{
            border: props.removeBorder ? "none" : undefined,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "flex-start",
              gap: "0.5rem",
              width: "100%",
            }}
          >
            <SearchIcon opacity={props.disabled ? 0.38 : 1} />
            <SearchInputBase
              ref={ref}
              inputRef={inputRef}
              autoFocus={props.autoFocus}
              placeholder={props.placeholder}
              value={props.value}
              onChange={(e) => {
                props.onChange(e.target.value);
              }}
              disabled={props.disabled}
            />
          </Box>
        </SearchContainer>
      </ClickAwayListener>
    );
  },
);
