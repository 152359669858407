import { SvgIcon } from "@mui/material";
import { CustomIconProps } from ".";

export function Archive(props: CustomIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.6667 5.33366C13.0203 5.33366 13.3594 5.19318 13.6095 4.94313C13.8595 4.69309 14 4.35395 14 4.00033C14 3.6467 13.8595 3.30756 13.6095 3.05752C13.3594 2.80747 13.0203 2.66699 12.6667 2.66699H3.33333C2.97971 2.66699 2.64057 2.80747 2.39052 3.05752C2.14048 3.30756 2 3.6467 2 4.00033C2 4.35395 2.14048 4.69309 2.39052 4.94313C2.64057 5.19318 2.97971 5.33366 3.33333 5.33366M12.6667 5.33366H3.33333M12.6667 5.33366V12.0003C12.6667 12.3539 12.5262 12.6931 12.2761 12.9431C12.0261 13.1932 11.687 13.3337 11.3333 13.3337H4.66667C4.31304 13.3337 3.97391 13.1932 3.72386 12.9431C3.47381 12.6931 3.33333 12.3539 3.33333 12.0003V5.33366M6.66667 8.00033H9.33333"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
