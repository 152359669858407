import React from "react";
import { EventType } from "../../gql-hooks/types";
import { Action, ScopeInput, Scopes, Version } from "../../gql/graphql";

export const getObjectIdKeys = (type: EventType) => {
  switch (type) {
    case "task":
      return ["task_id"] as const;
    case "version":
      return ["version_id"] as const;
    case "expression_param":
      return ["task_id", "expression_id", "ord"] as const;
    case "task_field":
      return ["task_id", "custom_field_id"] as const;
    case "task_tag":
      return ["task_id", "tag_id"] as const;
    case "assignment":
      return ["task_id", "team_id", "user_id"] as const;
    case "comment":
      return ["comment_id"] as const;
    case "attachment":
      return ["attachment_id"] as const;
    case "status":
      return ["status_id"] as const;
    case "status_stage":
      return ["status_stage_id"] as const;
    case "team":
      return ["team_id"] as const;
    case "team_member":
      return ["team_id", "user_id"] as const;
    case "company_member":
      return ["user_id", "user_email"] as const;
    case "invitation_token":
      return ["invitation_token_id"] as const;
    case "role":
      return ["role_id"] as const;
    case "role_permission":
      return ["role_id", "permission_name", "permission_action"] as const;
    case "custom_field":
      return ["custom_field_id"] as const;
    case "tag":
      return ["tag_id"] as const;
    case "subscription":
      return ["subscription_id"] as const;
    case "document_analysis":
      return ["document_analysis_id"] as const;
    case "access":
      return ["access_id"] as const;
    default:
      return [] as const;
  }
};

export const getObjectId = (
  type: EventType,
  obj: Record<string, unknown>,
): string => {
  const idKeys = getObjectIdKeys(type);
  return JSON.stringify(
    Object.fromEntries(
      idKeys
        .map((key) => [key, obj[key] ?? null])
        .concat([["event_type", type]]),
    ),
  );
};

export type ObjectChange = {
  objectId: string;
  previous?: Record<string, unknown>;
  after: Record<string, unknown>;
  action: Action;
  type: EventType;
};

export type ChangesDateRange = {
  from?: string;
  to: string;
};
export type VersionRange = {
  firstVersionId?: string;
  lastVersionId: string;
  scope: ScopeInput;
};

export type VersionRangeData = {
  firstVersion?: Version;
  lastVersion: Version;
  fromDate?: Date;
  toDate: Date;
  changes: Array<ObjectChange>;
};

const storageKey = "versionRange";
export const getVersionRangeFromSession = (): VersionRange | undefined => {
  const versionRange = sessionStorage.getItem(storageKey);
  if (versionRange) {
    return JSON.parse(versionRange);
  }
  return undefined;
};
export const setVersionRangeInSession = (
  versionRange: VersionRange | undefined,
) => {
  if (!versionRange) {
    sessionStorage.removeItem(storageKey);
  } else {
    sessionStorage.setItem(storageKey, JSON.stringify(versionRange));
  }
};
export type ChangesProviderProps = {
  checkScope: (scope: Scopes | ScopeInput | null | undefined) => void;
  setVersionRange: (version?: VersionRange) => void;
  loading: boolean;
  versionRange: VersionRange | undefined;
  versionRangeData: VersionRangeData | undefined;
  showChanges: boolean;
  setShowChanges: React.Dispatch<React.SetStateAction<boolean>>;
  getChange: (
    type: EventType,
    object: Record<string, unknown> | null | undefined,
  ) => ObjectChange | undefined;
  listChanges: (type: EventType) => ObjectChange[] | undefined;
  listDeleted: <T>(type: EventType) => T[] | undefined;
};
const EmptyChangesProvider: ChangesProviderProps = {
  checkScope: () => {},
  setVersionRange: () => {},
  loading: false,
  versionRange: undefined,
  versionRangeData: undefined,
  showChanges: false,
  setShowChanges: () => {},
  getChange: () => undefined,
  listChanges: () => undefined,
  listDeleted: () => undefined,
};

export const ChangesContext = React.createContext<
  ChangesProviderProps | undefined
>(undefined);

export const useChanges = () => {
  return React.useContext(ChangesContext) ?? EmptyChangesProvider;
};
