import * as React from "react";

function _Close(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M12 4L4 12M4 4L12 12"
        stroke="#343B48"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const CloseIcon = React.memo(_Close);
export default CloseIcon;
