import { nanoid } from "@reduxjs/toolkit";
import { Fragment, ReactNode } from "react";

type ForProps<T> = {
  each: T[];
  children: (item: T, index: number) => ReactNode;
};

export function For<T>(props: ForProps<T>) {
  const forId = nanoid();

  return (
    <Fragment>
      {props.each.map((item, index) => (
        <Fragment key={`${forId}-${index}`}>
          {props.children(item, index)}
        </Fragment>
      ))}
    </Fragment>
  );
}
