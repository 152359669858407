import { fetchUserAttributes, fetchAuthSession } from "aws-amplify/auth";

export type UserAttributes = {
  email: string;
  email_verified: boolean;
  sub: string;
  "custom:id"?: string;
  identities?: string;
  given_name?: string;
  family_name?: string;
  phone_number?: string;
  "custom:role_id"?: string;
  "custom:invitation_code"?: string;
  "custom:lang_code"?: string;
};

const CLIENT_ID = import.meta.env.VITE_aws_user_pools_web_client_id as string;
const getCognitoId = (): string | undefined =>
  localStorage.getItem(
    `CognitoIdentityServiceProvider.${CLIENT_ID}.LastAuthUser`,
  ) ?? undefined;

export const getLocalUserAttributes = (): UserAttributes | undefined => {
  const userId = getCognitoId();
  if (!userId) return undefined;
  const idToken = localStorage.getItem(
    `CognitoIdentityServiceProvider.${CLIENT_ID}.${userId}.idToken`,
  );
  const body = idToken?.split(".")[1];
  if (!body) return undefined;
  const decoded = JSON.parse(atob(body));
  return decoded as UserAttributes;
};
export const getLocalAccessToken = (): string | undefined => {
  const userId = getCognitoId();
  if (!userId) return undefined;
  const token =
    localStorage.getItem(
      `CognitoIdentityServiceProvider.${CLIENT_ID}.${userId}.accessToken`,
    ) ?? undefined;
  if (!token) return undefined;
  const body = token.split(".")[1];
  const claims = JSON.parse(atob(body));
  const exp = claims.exp;
  const now = Math.floor(Date.now() / 1000);
  if (exp < now) {
    return undefined;
  } else {
    return token;
  }
};

export async function getAccessToken(): Promise<string | undefined> {
  const token = getLocalAccessToken();
  if (!token) {
    return await fetchAuthSession().then((r) =>
      r.tokens?.accessToken.toString(),
    );
  }
  return token;
}

export async function getCognitoUser(
  refetch = false,
): Promise<UserAttributes | undefined> {
  try {
    const attributes = !refetch
      ? getLocalUserAttributes()
      : await fetchUserAttributes();
    if (!attributes) return undefined;
    return attributes as unknown as UserAttributes;
  } catch (err) {
    console.error("Error from checkAuthCognito", err);
  }
  return undefined;
}
