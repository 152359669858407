export const uploadFile = async (
  presigned_url: string,
  file: Blob,
): Promise<void> => {
  const response = await fetch(presigned_url, {
    method: "PUT",
    headers: {
      "Content-Type": file.type,
    },
    body: file,
  });
  if (!response.ok) {
    throw new Error("Failed to upload file");
  }
};

export const getFileUpload = (file?: Blob | null) => {
  let uploaded = false;
  const release = () => {
    uploaded = true;
    window.removeEventListener("beforeunload", () => {});
  };

  return {
    uploadFile: async (presigned_url?: string | null) => {
      if (!presigned_url || !file) return;
      await uploadFile(presigned_url, file!);
      release();
    },
    contentType: file?.type,
    lock: () => {
      window.addEventListener("beforeunload", (e) => {
        if (!uploaded) {
          e.preventDefault();
          e.returnValue = "";
        }
      });
    },
    release,
  };
};
