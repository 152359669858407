import { createContext, useContext } from "react";
import { RecursivePartial } from "../gql-hooks/queries";

export type DragIsActiveContextType = {
  isDragActive: boolean;
};

export const DragIsActiveContext = createContext<
  DragIsActiveContextType | undefined
>(undefined);

export const useDragIsActive = () => {
  const context = useContext(DragIsActiveContext);
  if (!context) {
    throw new Error(
      "useAttachmentsDragIsActive must be used within a AttachmentsDragIsActiveProvider",
    );
  }
  return context!;
};

export type UserBrowserPreferences = {
  isAttachmentExpanded?: boolean;
  collapsed?: {
    [key: string]: boolean;
  };
};

export type UserBrowserPreferencesContextType = {
  preferences: UserBrowserPreferences;
  setPreferences: (
    preferences: RecursivePartial<UserBrowserPreferences>,
  ) => void;
};

export const UserBrowserPreferencesContext = createContext<
  UserBrowserPreferencesContextType | undefined
>(undefined);

export const useUserBrowserPreferences = () => {
  const context = useContext(UserBrowserPreferencesContext);
  if (!context) {
    throw new Error(
      "useUserBrowserPreference must be used within a UserBrowserPreferenceProvider",
    );
  }
  return context!;
};
