import {
  Grid,
  GridProps,
  Tooltip,
  TooltipProps,
  styled,
  tooltipClasses,
} from "@mui/material";
import { ObjectChange } from "../../guards/contexts/changes";
import { AfterValue, PreviousValue } from "./styles";
import { IconArrowNarrowRight } from "@tabler/icons-react";
import Colors from "../../utils/ColorConstants";
import { Action } from "../../gql/graphql";

export type ChangeTooltipBaseProps<T, K> = {
  objKey: K | K[];
  children:
    | React.ReactElement
    | ((
        createDeleteRef: React.RefObject<HTMLElement>,
        updateRef: React.RefObject<HTMLElement>,
      ) => React.ReactElement);
  getDisplayValue?: (
    value: T,
    key: K,
    obj: Record<string, unknown>,
  ) => React.ReactNode;
  getIcon?: (
    value: T,
    key: K,
    obj: Record<string, unknown>,
  ) => React.ReactElement;
};
export type UseChangeTooltipProps<T, K> = {
  actionWhiteList?: Action[];
  objChange: ObjectChange | undefined;
  customComparator?: (previousValue: T, afterValue: T) => boolean;
} & ChangeTooltipBaseProps<T, K>;

export type ChangeTooltipProps<T, K> = {
  createDeleteRef: React.RefObject<HTMLElement>;
  updateRef: React.RefObject<HTMLElement>;
  action: Action;
  previousObj: Record<string, unknown>;
  afterObj: Record<string, unknown>;
  previousValue: T;
  afterValue: T;
  objKey: K;
} & Omit<ChangeTooltipBaseProps<T, K>, "objKey">;

const gridProps: GridProps = {
  alignContent: "center",
  alignItems: "center",
  justifyContent: "flex-start",
  flexWrap: "nowrap",
  width: "auto",
};

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    fontSize: 11,
    color: Colors.DEFAULT,
    fontWeight: 400,
    padding: "0.5rem",
    maxWidth: "30rem",
    minWidth: "0rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    "&::before": {
      border: "1px solid rgba(0, 0, 0, 0.2)",
    },
  },
}));

export const ChangeTooltip = <T, K>({
  getDisplayValue = (v) => v && String(v),
  previousValue,
  afterValue,
  getIcon,
  children,
  objKey,
  previousObj,
  afterObj,
  action,
  createDeleteRef,
  updateRef,
}: ChangeTooltipProps<T, K>) => {
  return (
    <CustomTooltip
      PopperProps={
        typeof children === "function"
          ? {
              anchorEl:
                action === Action.Create || action === Action.Delete
                  ? createDeleteRef.current
                  : updateRef.current ?? createDeleteRef.current,
            }
          : undefined
      }
      title={
        <Grid
          container
          direction="row"
          gap={1}
          flexGrow={1}
          {...gridProps}
          sx={{
            svg: {
              strokeWidth: 2,
            },
          }}
        >
          <Grid container {...gridProps} gap={0.5}>
            {getIcon && (
              <Grid item>{getIcon(previousValue, objKey, previousObj)}</Grid>
            )}
            <Grid item>
              <PreviousValue>
                {getDisplayValue(previousValue, objKey, previousObj) ?? "N/A"}
              </PreviousValue>
            </Grid>
          </Grid>
          <Grid
            item
            sx={{
              strokeWidth: 2,
            }}
          >
            <IconArrowNarrowRight size="1rem" color={Colors.DEFAULT} />
          </Grid>
          <Grid container {...gridProps} gap={0.5}>
            {getIcon && (
              <Grid item>{getIcon(afterValue, objKey, afterObj)}</Grid>
            )}
            <Grid item>
              <AfterValue>
                {getDisplayValue(afterValue, objKey, afterObj) ?? "N/A"}
              </AfterValue>
            </Grid>
          </Grid>
        </Grid>
      }
      arrow
      placement="top"
    >
      {typeof children === "function"
        ? children(createDeleteRef, updateRef)
        : children}
    </CustomTooltip>
  );
};
