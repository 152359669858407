const Colors = {
  BACKGROUND: "#F6F7F9",
  BACKGROUND_GRAY: "#B5C0D2",
  BACKGROUND_DARK: "#1F2531",
  BLUE: "#0072F5",
  BLUE_DARK: "#005BC4",
  BLUE_GRAY: "#8395B4",
  BLUE_WHITE: "#EEF1F6",
  BORDER: "#EEF1F6",
  BORDER_DARK: "#D9DEE8",
  DEFAULT: "#343B48",
  GREEN: "#009B39",
  GREEN_DARK: "#00742B",
  GREEN_DEFAULT: "#00C247",
  GREEN_BACKGROUND: "#F3FCF6",
  HIGHLIGHTED: "#7E00FF",
  LIGHT2: "#697790",
  LIGHT3: "#9CAAC3",
  LIGHT: "#4F5A6C",
  PURPLE_DARK: "#6905FF",
  WHITE: "#FFFFFF",
  PURPLE_HIGHLIGHTED_BG: "#F0E6FF",
  PURPLE_HIGHLIGHTED: "#5404CC",
  PURPLE_WHITE: "#F9F5FF",
  BLUE_LIGHT: "#009DE0",
  ORANGE: "#924E00",
  LIGHT_ORANGE: "#FFFAF5",
  YELLOW: "#F5A400",
  YELLOW_DARK: "#F38200",
  PINK: "#FF2970",
  RED: "#EF001C",
  DESTRUCTIVE_DARK: "#C00016",
  RED_DARK: "#930011",
  RED_LIGHT: "#FF6961",
  RED_BACKGROUND: "#FFF5F6",
  SHADOW: "#0022661A",
} as const;

export const BorderRadii = {
  SMALL: "0.25rem",
  MEDIUM: "0.5rem",
  LARGE: "1rem",
  XLARGE: "1.5rem",
} as const;

export const boxShadow = `4px 4px 16px 4px ${Colors.SHADOW}`;
export const boxShadow2 = `1px 1px 8px 1px ${Colors.SHADOW}`;
export const border = `1px solid ${Colors.BORDER}`;

export const Spacing = {
  SMALL: "0.25rem",
  MEDIUM: "0.5rem",
  LARGE: "0.75rem",
  XLARGE: "1rem",
  XXLARGE: "1.5rem",
} as const;

export const FontSizes = {
  /** 8px */
  XSMALL: "0.5rem",
  /** 14px */
  SMALL: "0.875rem",
  /** 16px */
  MEDIUM: "1rem",
  /** 20px
  LARGE: "1.25rem",
  /** 24px */
  XLARGE: "1.5rem",
} as const;

export const FontWeights = {
  REGULAR: 400,
  BOLD: 600,
} as const;

export const IconSize = {
  SMALL: {
    width: "1rem",
    height: "1rem",
  },
  MEDIUM: {
    width: "1.5rem",
    height: "1.5rem",
  },
  LARGE: {
    width: "2rem",
    height: "2rem",
  },
  XLARGE: {
    width: "2.5rem",
    height: "2.5rem",
  },
} as const;

export default Colors;
