import { createContext, useState, useContext, ReactNode } from "react";

type SidebarDesktopContextProps = {
  isCollapsed: boolean;
  handleToggleSidebar: () => void;
};

type SidebarDesktopProviderProps = {
  children: ReactNode;
};

const SidebarDesktopContext = createContext<SidebarDesktopContextProps>({
  isCollapsed: false,
  handleToggleSidebar: () => {},
});

export function SidebarDesktopProvider({
  children,
}: SidebarDesktopProviderProps) {
  const [isCollapsed, setIsCollapsed] = useState(
    localStorage.getItem("sidebar-desktop") === "true",
  );

  function handleToggleSidebar() {
    setIsCollapsed(!isCollapsed);
    localStorage.setItem("sidebar-desktop", String(!isCollapsed));
  }

  return (
    <SidebarDesktopContext.Provider
      value={{ handleToggleSidebar, isCollapsed }}
    >
      {children}
    </SidebarDesktopContext.Provider>
  );
}

export function useSidebarDesktop() {
  return useContext(SidebarDesktopContext)!;
}
