import { styled } from "@mui/material";

export const ContainerContent = styled("div")<{ maxHeight?: string }>`
  display: flex;
  padding: 0.75rem;
  gap: 0.75rem;
  align-self: stretch;
  overflow: auto;
  height: 100%;
`;
