import React from "react";
import { LinkProps } from "react-router-dom";
import { StyledLink } from "./styles";

const CustomLink = React.forwardRef<
  HTMLAnchorElement,
  Omit<LinkProps, "to"> &
    React.RefAttributes<HTMLAnchorElement> &
    Partial<Pick<LinkProps, "to">>
>((props, ref) => {
  if (!props.to) return props.children as JSX.Element;
  return <StyledLink {...props} ref={ref} to={props.to!} />;
});

export default CustomLink;
