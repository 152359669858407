import React from "react";
import { UserAttributes } from "../../auth/auth-cognito";

export const CognitoUserContext = React.createContext<
  | {
      cognitoUser: UserAttributes;
      setCognitoUser: React.Dispatch<
        React.SetStateAction<UserAttributes | undefined>
      >;
    }
  | undefined
>(undefined);

export const useCognitoUser = () => {
  const user = React.useContext(CognitoUserContext);
  if (!user) {
    throw new Error("useCognitoUser must be used within a CognitoUserProvider");
  }
  return user;
};

export default useCognitoUser;
