import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

export const useKeyPress = ({
  key,
  callback,
  node,
  shiftKey,
  ctrlKey,
  cmdKey,
}: {
  key: string;
  callback: (e: KeyboardEvent) => void;
  node?: GlobalEventHandlers | null;
  shiftKey?: boolean;
  ctrlKey?: boolean;
  cmdKey?: boolean;
}) => {
  const callbackRef = useRef(callback);
  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      if (shiftKey != null && event.shiftKey !== shiftKey) return;
      if (ctrlKey != null && event.ctrlKey !== ctrlKey) return;
      if (cmdKey != null && event.metaKey !== cmdKey) return;
      if (event.key.toUpperCase() === key.toUpperCase()) {
        event.preventDefault();
        callbackRef.current(event);
      }
    },
    [ctrlKey, key, shiftKey, cmdKey],
  );

  useEffect(() => {
    const targetNode = node ?? document;
    targetNode && targetNode.addEventListener("keydown", handleKeyPress);

    return () =>
      targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress, node]);
};
