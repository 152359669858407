import { useMutation } from "@apollo/client";
import { graphql } from "../gql";
import { ScopeInput } from "../gql/graphql";
import { useEffect } from "react";
import { useQueryRequest } from "./queries";

const EXPORT_UPDATED_SUBSCRIPTION = graphql(`
  subscription ExportSubscription($exportId: UUID!, $scope: ScopeInput!) {
    export(export_ids: [$exportId], scope: $scope) {
      company_id
      export_id
      status
      owner_id
      created_at
      updated_at
      presigned_url
    }
  }
`);

const CREATE_EXPORT = graphql(`
  mutation CreateExport($scope: ScopeInput!, $input: [CreateExportInput!]!) {
    createExport(scope: $scope, input: $input) {
      export_id
      status
      created_at
      updated_at
      presigned_url
    }
  }
`);

const GET_EXPORTED_TASKS = graphql(`
  query Export($exportId: UUID!, $scope: ScopeInput!) {
    export(export_id: $exportId, scope: $scope) {
      company_id
      export_id
      status
      owner_id
      created_at
      updated_at
      presigned_url
    }
  }
`);

export const useCreateExport = () => {
  const [createExport] = useMutation(CREATE_EXPORT);
  return { createExport };
};

export const useGetExportedTasks = (
  scope: ScopeInput,
  exportId: string | null,
) => {
  const shouldRun = exportId !== null;
  const response = useQueryRequest({
    query: GET_EXPORTED_TASKS,
    input: {
      scope,
      exportId: exportId || "",
    },
    skip: !shouldRun || !exportId?.length,
  });
  useEffect(() => {
    shouldRun &&
      exportId?.length &&
      response.subscribeToMore({
        document: EXPORT_UPDATED_SUBSCRIPTION,

        variables: {
          exportId: exportId || "",
          scope,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          return {
            export: subscriptionData.data.export,
          };
        },
      });
  }, [shouldRun, exportId, response, scope]);

  return {
    exportResult: shouldRun ? response.value?.export : null,
  };
};
