import { styled } from "@mui/material";
import Colors from "../../utils/ColorConstants";

export const Break = styled("hr")`
  width: 100%;
  border: 1px solid transparent;
  max-height: 2px;
  border-bottom-color: ${Colors.BORDER};
  margin: 0;
`;

export const VerticalBreak = styled("hr")`
  height: 100%;
  border: 1px solid transparent;
  border-right-color: ${Colors.BORDER};
  margin: 0;
`;
