import { Button as ButtonMUI, styled } from "@mui/material";
import Colors from "../../../utils/ColorConstants";

const Button = styled(ButtonMUI)<{ preventShrink?: boolean }>`
  flex-shrink: ${({ preventShrink }) => (preventShrink ? "0" : "unset")};
  display: flex;
  height: 2rem !important;
  min-width: 2rem !important;
  padding: 0.5rem 0.75rem;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  border-radius: 0.5rem;

  white-space: nowrap;

  overflow: hidden;
  text-align: center;

  font-family: Manrope;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: 1px solid transparent;

  svg {
    width: 1rem;
    height: 1rem;
  }
`;

export const PrimaryButton = styled(Button)`
  background: ${Colors.PURPLE_DARK};
  color: ${Colors.WHITE};

  &:hover {
    background: ${Colors.PURPLE_DARK};
  }

  &:focus {
    box-shadow:
      0px 0px 0px 4px #f2e6ff,
      0px 0px 0px 2px ${Colors.WHITE};
  }

  &:disabled {
    color: ${Colors.WHITE} !important;
    background: #d496ff !important;
  }
`;

export const SecondaryButton = styled(Button)`
  background: ${Colors.WHITE};
  min-height: 2rem !important;
  color: ${Colors.DEFAULT};
  border-color: ${Colors.BLUE_WHITE};
  padding: 0.5rem 0.75rem;

  &:hover {
    border-color: #afc1d6;
    background: ${Colors.WHITE} !important;
  }

  &:focus {
    border-color: ${Colors.HIGHLIGHTED};
    box-shadow:
      0px 0px 0px 4px #f2e6ff,
      0px 0px 0px 2px ${Colors.WHITE};
  }

  &:disabled {
    color: ${Colors.BACKGROUND_GRAY} !important;
    background: ${Colors.BACKGROUND} !important;
  }

  svg {
    stroke-width: 2;
    width: 1rem;
    height: 1rem;
  }
`;
