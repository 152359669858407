import { useEffect, useRef, useState } from "react";
import { DragIsActiveContext } from "./contexts";

export const DragIsActiveProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isDragging, setDragging] = useState(false);
  const isFileFromOS = useRef(true);
  const cachedTarget = useRef<EventTarget | null>(null);
  const hasDraggedFileFromBrowserOutsideOfWindow = useRef(false);

  useEffect(() => {
    const handleDragEnter = (e: DragEvent) => {
      cachedTarget.current = e.target;
    };
    const handleDragStart = () => {
      isFileFromOS.current = false;
      hasDraggedFileFromBrowserOutsideOfWindow.current = false;
    };
    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();

      if (
        hasDraggedFileFromBrowserOutsideOfWindow.current &&
        document.hasFocus()
      ) {
        return;
      }
      if (isFileFromOS.current) {
        hasDraggedFileFromBrowserOutsideOfWindow.current = false;
        if (!isDragging) setDragging(true);
        return;
      }
    };
    const handleDragLeave = (e: DragEvent) => {
      if (e.target === cachedTarget.current) {
        if (
          isFileFromOS.current &&
          !hasDraggedFileFromBrowserOutsideOfWindow.current
        ) {
          if (isDragging) setDragging(false);
        } else {
          hasDraggedFileFromBrowserOutsideOfWindow.current = true;
          isFileFromOS.current = true;
        }
      }
    };
    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      isFileFromOS.current = true;
      hasDraggedFileFromBrowserOutsideOfWindow.current = false;

      if (isDragging) setDragging(false);
    };

    window.addEventListener("dragenter", handleDragEnter);
    window.addEventListener("dragstart", handleDragStart);
    window.addEventListener("dragover", handleDragOver);
    window.addEventListener("dragleave", handleDragLeave);
    window.addEventListener("drop", handleDrop);
    return () => {
      window.removeEventListener("dragenter", handleDragEnter);
      window.removeEventListener("dragstart", handleDragStart);
      window.removeEventListener("dragover", handleDragOver);
      window.removeEventListener("dragleave", handleDragLeave);
      window.removeEventListener("drop", handleDrop);
    };
  }, [isDragging]);

  return (
    <DragIsActiveContext.Provider
      value={{ isDragActive: isDragging }}
      children={children}
    />
  );
};
