import { useMutation, useQuery } from "@apollo/client";
import {
  AcceptInviteInput,
  CancelInviteInput,
  CreateInviteInput,
  ScopeInput,
} from "../gql/graphql";
import { graphql } from "../gql";

const CREATE_INVITE = graphql(`
  mutation CreateInvite($input: CreateInviteInput!, $scope: ScopeInput!) {
    createInvite(input: $input, scope: $scope) {
      company_id
      user_id
      user_email
      role_id
      owner_id
      created_at
      updated_at
      ttl
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
  }
`);

const GET_INVITE = graphql(`
  query Invites($scope: ScopeInput!) {
    invites(scope: $scope) {
      company_id
      user_id
      user_email
      role_id
      owner_id
      created_at
      updated_at
      ttl
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
  }
`);

export const ACCEPT_INVITE = graphql(`
  mutation AcceptInvite($input: AcceptInviteInput) {
    acceptInvite(input: $input) {
      company_id
      user_id
      user_email
      role_id
      owner_id
      created_at
      updated_at
      ttl
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
  }
`);

const CANCEL_INVITE = graphql(`
  mutation CancelInvite($input: CancelInviteInput!, $scope: ScopeInput!) {
    cancelInvite(input: $input, scope: $scope) {
      company_id
      user_id
      user_email
      role_id
      owner_id
      created_at
      updated_at
      ttl
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
  }
`);

// function useCreateInvite(input: CreateInviteInput, scope: ScopeInput) {
//   const response = useMutation(CREATE_INVITE, { variables: { input, scope } });

//   return { ...response };
// }

export function useGetInvites(scope: ScopeInput) {
  const response = useQuery(GET_INVITE, {
    variables: {
      scope: scope,
    },
  });

  return { ...response };
}

export function useCreateInvite() {
  const [createInvite] = useMutation(CREATE_INVITE);

  const triggerCreateInvite = async (
    input: CreateInviteInput,
    scope: ScopeInput,
  ) => {
    const response = await createInvite({
      variables: {
        input,
        scope,
      },
    });
    return response;
  };

  return {
    triggerCreateInvite,
  };
}

export function useAcceptInvite() {
  const [acceptInvite, response] = useMutation(ACCEPT_INVITE);

  const triggerAcceptInvite = async (input: AcceptInviteInput) => {
    const response = await acceptInvite({
      variables: {
        input,
      },
    });
    return response;
  };

  return {
    triggerAcceptInvite,
    ...response,
  };
}

export function useCancelInvite() {
  const [cancelInvite] = useMutation(CANCEL_INVITE);

  const triggerCancelInvite = async (
    input: CancelInviteInput,
    scope: ScopeInput,
  ) => {
    const response = await cancelInvite({
      variables: {
        input,
        scope,
      },
    });
    return response;
  };

  return {
    triggerCancelInvite,
  };
}
