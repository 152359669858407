import Colors from "../../utils/ColorConstants";

function UnderConstruction() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: Colors.WHITE,
        height: "100vh",
        gap: "1rem",
        overflow: "hidden",
        padding: "1rem",
      }}
    >
      <img
        src="/logo/logo-negative-dark.png"
        alt="logo-wrapt"
        style={{
          height: "14rem",
        }}
      />
      <img
        src="https://i.imgur.com/qIufhof.png"
        alt="under construction"
        style={{
          width: "21rem",
          height: "21rem",
        }}
      />
    </div>
  );
}

export default UnderConstruction;
