import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ChangesContext,
  VersionRange,
  VersionRangeData,
  getObjectId,
  getVersionRangeFromSession,
  setVersionRangeInSession,
} from "./contexts/changes";
import { EventType } from "../gql-hooks/types";
import { Action, Event, ScopeInput, Scopes, Version } from "../gql/graphql";
import { graphql } from "../gql";
import { useLazyQueryRequest } from "../gql-hooks/queries";

const VERSION_RANGE_DATA = graphql(`
  query VersionRangeData(
    $scope: ScopeInput!
    $input: EventsBetweenVersionsInput!
    $filter: VersionFilterInput
  ) {
    eventsBetweenVersions(scope: $scope, input: $input) {
      event_id
      created_at
      type
      action
      owner_id
      company_id
      previous_event_id
      restored_version_id
      previous
      input
      after
      update_diff
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
    versions(scope: $scope, filter: $filter) {
      company_id
      version_id
      name
      timeline_point
      owner_id
      updated_by
      created_at
      updated_at
      restored_at
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
    }
  }
`);

export function ChangesProvider({ children }: { children: React.ReactNode }) {
  const [versionRange, setVersionRange] = useState<VersionRange | undefined>(
    getVersionRangeFromSession(),
  );
  const [showChanges, setShowChanges] = useState(!!versionRange);
  const [versionRangeData, setVersionRangeData] = useState<VersionRangeData>();

  const { setInput } = useLazyQueryRequest({
    query: VERSION_RANGE_DATA,
    onSuccess: useCallback(
      (data: { eventsBetweenVersions: Event[]; versions: Version[] }) => {
        const [lastVersion, firstVersion] = data.versions as [
          Version,
          Version | undefined,
        ];
        const now = new Date().toISOString();
        const fromDate = firstVersion?.timeline_point
          ? new Date(firstVersion.timeline_point)
          : undefined;
        const toDate = new Date(lastVersion.timeline_point ?? now);

        const changesMap = new Map<string, Event[]>();

        data.eventsBetweenVersions.forEach((event) => {
          const key = getObjectId(event.type as EventType, event.after);
          changesMap.set(key, (changesMap.get(key) ?? []).concat(event));
        });

        setVersionRangeData({
          firstVersion,
          lastVersion,
          fromDate,
          toDate,
          changes: Array.from(changesMap.entries()).map(([key, events]) => {
            // this is because the events are sorted in descending order
            const last = events.at(0)!;
            const first = events.at(-1)!;

            return {
              objectId: key,
              previous: first?.previous ?? first?.after ?? undefined,
              after: last.after,
              action: last.action,
              type: last.type as EventType,
            };
          }),
        });
      },
      [],
    ),
  });

  const setVersionRangeCallback = useCallback((vr?: VersionRange) => {
    let cancel = false;
    setVersionRange((prev) => {
      if (
        vr &&
        prev &&
        vr.firstVersionId === prev.firstVersionId &&
        vr.lastVersionId === prev.lastVersionId
      ) {
        cancel = true;
        return prev;
      }
      return vr;
    });
    if (cancel) return;
    setVersionRangeInSession(vr);
    setVersionRangeData(undefined);
    if (!vr) setShowChanges(false);
  }, []);
  const listChanges = useCallback(
    (type: EventType) => {
      return versionRangeData?.changes.filter((change) => change.type === type);
    },
    [versionRangeData?.changes],
  );

  useEffect(() => {
    if (!versionRange || !showChanges) return;
    setInput({
      scope: versionRange.scope,
      input: {
        first_version_id: versionRange.firstVersionId,
        last_version_id: versionRange.lastVersionId,
      },
      filter: {
        version_ids: [
          ...(versionRange.firstVersionId ? [versionRange.firstVersionId] : []),
          versionRange.lastVersionId,
        ],
      },
    });
  }, [setInput, showChanges, versionRange]);

  return (
    <ChangesContext.Provider
      value={{
        showChanges,
        setShowChanges,
        loading: useMemo(
          () => !!versionRange && !versionRangeData,
          [versionRange, versionRangeData],
        ),
        setVersionRange: setVersionRangeCallback,
        checkScope: useCallback(
          (scope: Scopes | ScopeInput | null | undefined) => {
            if (!versionRange || !scope) return;
            for (const key of Object.keys(
              versionRange.scope,
            ) as (keyof ScopeInput)[]) {
              if (
                versionRange.scope[key] &&
                scope[key] !== versionRange.scope[key]
              )
                return setVersionRangeCallback();
            }
          },
          [setVersionRangeCallback, versionRange],
        ),
        versionRange,
        versionRangeData,
        listChanges,
        listDeleted: useCallback(
          <T,>(type: EventType) =>
            listChanges(type)
              ?.filter((e) => e.action === Action.Delete)
              .map((e) => e.after as T),
          [listChanges],
        ),
        getChange: useCallback(
          (
            type: EventType,
            obj: Record<string, unknown> | null | undefined,
          ) => {
            if (!obj) return;
            const objectId = getObjectId(type, obj);
            return versionRangeData?.changes?.find(
              (change) => change.objectId === objectId,
            );
          },
          [versionRangeData?.changes],
        ),
      }}
    >
      {children}
    </ChangesContext.Provider>
  );
}

export default ChangesProvider;
