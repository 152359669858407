import { useIsMac } from "../../gql-hooks/useIsMac";
import { useKeyPress } from "../../gql-hooks/useKeyPress";
import { KeyBind, KeyBindProps } from "./key-bind";

export const KeyBindWrapper = ({
  callback,
  ...props
}: {
  callback: () => void;
} & KeyBindProps) => {
  const isMac = useIsMac();

  const modifiedProps = {
    ...props,
    ctrlKey: isMac ? false : props.ctrlKey,
    cmdKey: !!isMac,
    onClick: callback,
  };

  useKeyPress({
    key: props.keyLetter.toLowerCase(),
    callback: callback,
    ctrlKey: modifiedProps.ctrlKey,
    cmdKey: modifiedProps.cmdKey,
  });

  return <KeyBind {...modifiedProps} />;
};
