import { useCallback, useState } from "react";
import {
  UserBrowserPreferences,
  UserBrowserPreferencesContext,
} from "./contexts";
import { RecursivePartial, recursiveMerge } from "../gql-hooks/queries";
const USER_BROWSER_PREFERENCE_LOCAL_STORAGE_KEY = "userBrowserPreference";

export const UserBrowserPreferenceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [userBrowserPreferences, setUserBrowserPreferences] =
    useState<UserBrowserPreferences>(
      JSON.parse(
        localStorage.getItem(USER_BROWSER_PREFERENCE_LOCAL_STORAGE_KEY) || "{}",
      ),
    );
  const setPreferences = useCallback(
    (preference: RecursivePartial<UserBrowserPreferences>) => {
      setUserBrowserPreferences((prev) => {
        const updatedPreference = recursiveMerge<
          UserBrowserPreferences,
          UserBrowserPreferences,
          RecursivePartial<UserBrowserPreferences>
        >(prev, preference);
        localStorage.setItem(
          USER_BROWSER_PREFERENCE_LOCAL_STORAGE_KEY,
          JSON.stringify(updatedPreference),
        );
        return updatedPreference;
      });
    },
    [],
  );
  return (
    <UserBrowserPreferencesContext.Provider
      value={{
        preferences: userBrowserPreferences,
        setPreferences,
      }}
      children={children}
    />
  );
};
