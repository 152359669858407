import { Box } from "@mui/material";
import Colors from "../../../../utils/ColorConstants";

export const LineDivider = ({
  margin = 0.75,
  color = Colors.BLUE_WHITE,
  zIndex,
  height = 1,
}: {
  margin?: number;
  color?: string;
  zIndex?: undefined;
  height?: number;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: color,
        borderRadius: "1px",
        marginLeft: `-${margin}rem`,
        marginRight: `-${margin}rem`,
        width: `calc(100% + ${2 * margin}rem)`,
        minHeight: `${height}px`,
        zIndex,
      }}
    />
  );
};
