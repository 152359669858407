import { SvgIcon } from "@mui/material";
import { CustomIconProps } from "..";

export function Notification(props: CustomIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66797 3.33333C6.66797 2.97971 6.80844 2.64057 7.05849 2.39052C7.30854 2.14048 7.64768 2 8.0013 2C8.35492 2 8.69406 2.14048 8.94411 2.39052C9.19416 2.64057 9.33464 2.97971 9.33463 3.33333C10.1002 3.69535 10.7529 4.25888 11.2227 4.96353C11.6924 5.66818 11.9616 6.48738 12.0013 7.33333V9.33333C12.0515 9.7478 12.1983 10.1447 12.4298 10.4921C12.6614 10.8395 12.9713 11.1276 13.3346 11.3333H2.66797C3.03126 11.1276 3.34117 10.8395 3.57276 10.4921C3.80435 10.1447 3.95113 9.7478 4.0013 9.33333V7.33333C4.04101 6.48738 4.31017 5.66818 4.77994 4.96353C5.2497 4.25888 5.90236 3.69535 6.66797 3.33333Z"
          fill="#F5FAFF"
        />
        <path
          d="M6.0013 11.3333V12C6.0013 12.5304 6.21202 13.0391 6.58709 13.4142C6.96216 13.7893 7.47087 14 8.0013 14C8.53173 14 9.04044 13.7893 9.41552 13.4142C9.79059 13.0391 10.0013 12.5304 10.0013 12V11.3333"
          fill="#F5FAFF"
        />
        <path
          d="M6.0013 11.3333V12C6.0013 12.5304 6.21202 13.0391 6.58709 13.4142C6.96216 13.7893 7.47087 14 8.0013 14C8.53173 14 9.04044 13.7893 9.41552 13.4142C9.79059 13.0391 10.0013 12.5304 10.0013 12V11.3333M6.66797 3.33333C6.66797 2.97971 6.80844 2.64057 7.05849 2.39052C7.30854 2.14048 7.64768 2 8.0013 2C8.35492 2 8.69406 2.14048 8.94411 2.39052C9.19416 2.64057 9.33464 2.97971 9.33464 3.33333C10.1002 3.69535 10.7529 4.25888 11.2227 4.96353C11.6924 5.66818 11.9616 6.48738 12.0013 7.33333V9.33333C12.0515 9.7478 12.1983 10.1447 12.4298 10.4921C12.6614 10.8395 12.9713 11.1276 13.3346 11.3333H2.66797C3.03126 11.1276 3.34117 10.8395 3.57276 10.4921C3.80435 10.1447 3.95113 9.7478 4.0013 9.33333V7.33333C4.04101 6.48738 4.31017 5.66818 4.77994 4.96353C5.2497 4.25888 5.90236 3.69535 6.66797 3.33333Z"
          stroke="currentColor"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
