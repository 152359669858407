export const Preconnect = (props: { url: string | null | undefined }) => {
  return (
    props.url && (
      <link
        rel="preload"
        as="image"
        href={props.url}
        style={{
          display: "none",
        }}
      />
    )
  );
};
