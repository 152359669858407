import { useCallback, useEffect, useRef, useState } from "react";
import { graphql } from "../gql";
import { ImageSize, UpdateUserInput, User } from "../gql/graphql";
import { useLazyQuery, useMutation } from "@apollo/client";

export const LIST_USERS = graphql(`
  query listUsers($userIds: [UUID!]!, $imageSize: ImageSize) {
    users(user_ids: $userIds, image_size: $imageSize) {
      user_id
      email
      first_name
      last_name
      user_role_id
      created_at
      updated_at
      has_profile_picture
      put_presigned_url
      presigned_url
      settings {
        timezone_offset_in_minutes
        language
        reminders {
          tasks_time_before_deadline_in_days
          attachment_approvals_frequency_in_days
        }
      }
    }
  }
`);

export const CREATE_USER = graphql(`
  mutation CreateUser($input: CreatesUserInput!) {
    createUser(input: $input) {
      user_id
      email
      first_name
      last_name
      user_role_id
      created_at
      updated_at
      has_profile_picture
      presigned_url
      put_presigned_url
      phone_number
      settings {
        timezone_offset_in_minutes
        language
        reminders {
          tasks_time_before_deadline_in_days
          attachment_approvals_frequency_in_days
        }
      }
    }
  }
`);

export const UPDATE_USER = graphql(`
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user_id
      email
      first_name
      last_name
      user_role_id
      phone_number
      created_at
      updated_at
      has_profile_picture
      presigned_url
      put_presigned_url
      settings {
        timezone_offset_in_minutes
        language
        reminders {
          tasks_time_before_deadline_in_days
          attachment_approvals_frequency_in_days
        }
      }
    }
  }
`);

export function useUsers() {
  const abortController = useRef(new AbortController());
  const [users, setUsers] = useState<Array<User> | undefined>(undefined);

  const [loadUsers, response] = useLazyQuery(LIST_USERS, {
    context: {
      fetchOptions: {
        signal: abortController.current.signal,
      },
    },
  });

  useEffect(() => {
    if (response.data) {
      setUsers(response.data.users);
    }
  }, [response.data]);

  return {
    ...response,
    loadUsers: useCallback(
      async (userIds?: Array<string>) => {
        if (!userIds) return;
        if (!userIds.length) {
          setUsers([]);
          return;
        }
        return await loadUsers({
          variables: {
            userIds,
            imageSize: ImageSize.S32,
          },
        });
      },
      [loadUsers],
    ),
    abort: useCallback(() => {
      abortController.current.abort();
      abortController.current = new AbortController();
    }, []),
    users,
  };
}

export function useUpdateUser() {
  const [updateUserMutation] = useMutation(UPDATE_USER);

  const triggerUpdateUser = async (input: UpdateUserInput) => {
    try {
      const response = await updateUserMutation({
        variables: {
          input: input,
        },
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    triggerUpdateUser,
  };
}

export function useUpdateProfileImage() {
  const [updateProfileImage] = useMutation(UPDATE_USER);

  return { updateProfileImage };
}

// export function useCreateUser() {
//   const [createAccessMutation] = useMutation(CREATE_USER);

//   const triggerCreateAccess = async (input: CreatesUserInput) => {
//     try {
//       const response = await createAccessMutation({
//         variables: {
//           input: input,
//         },
//       });
//       return response;
//     } catch (error) {
//       console.error(error);
//       throw error;
//     }
//   };

//   return {
//     triggerCreateAccess,
//   };
// }
