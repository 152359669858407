import {
  ApolloError,
  ApolloQueryResult,
  FetchMoreQueryOptions,
  SubscribeToMoreOptions,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import { documents } from "../gql";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
/**
 * Represents a generic object.
 **/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyObj = { [key: string]: any };

/**
 * Is the type of the documents object in src/gql/index.ts
 * This type maps the GraphQL codegen output to a TypeScript type.
 **/
export type Documents = typeof documents;

/**
 * Is all the possible values of the Documents type.
 * Represents the return type of the graphql function in src/gql/index.ts
 **/
export type DocumentValue = (typeof documents)[keyof typeof documents];

/**
 * Is the name of all the queries and mutations declared.
 * e.g.
 * ```graphql
 * query Test {
 *   test {
 *     id
 *   }
 * }
 * ```
 * The name of the query is "Test"
 **/
export type QueryName<T extends DocumentValue> =
  T extends TypedDocumentNode<
    infer O,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >
    ? Exclude<keyof O & string, "__typename">
    : never;

/**
 * Is the output type of the all queries and mutations declared.
 **/
export type QueryOutput<T extends DocumentValue> =
  T extends TypedDocumentNode<
    infer O,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >
    ? O
    : never;

/**
 * Is the input type of the all queries and mutations declared.
 **/
export type QueryInput<T extends DocumentValue> =
  T extends TypedDocumentNode<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any,
    infer I
  >
    ? I
    : never;

/**
 * This type extracts the output type of a query or mutation that returns a array of a object.
 **/
export type ArrayDocumentValue<T extends DocumentValue> =
  QueryOutput<T> extends {
    [key in QueryName<T>]: Array<infer O>;
  }
    ? O
    : never;

/**
 * Is all the queries and mutations that return a array of a object.
 **/
export type ArrayDocuments = {
  [K in keyof Documents]: QueryOutput<Documents[K]> extends {
    [key in QueryName<Documents[K]>]: Array<AnyObj>;
  }
    ? Documents[K]
    : never;
}[keyof Documents];

/**
 * Is all the keys of a object that is inside a array that is hashable.
 * Hashable means that the key is a string or a number.
 **/
export type ArrayHashableKeys<T extends ArrayDocuments> = Exclude<
  {
    [K in keyof ArrayDocumentValue<T>]: ArrayDocumentValue<T>[K] extends
      | string
      | number
      ? K
      : never;
  }[keyof ArrayDocumentValue<T>],
  undefined | null
>;

/**
 * Extracts the keys of a object that extends a specific type.
 * e.g.
 * ```typescript
 * type A = {
 *  a: string;
 *  b: number;
 *  c: string;
 * };
 *
 * // B is "a" | "c"
 * type B = KeysOfType<A, string>;
 * ```
 **/
export type KeysOfType<T, SelectedType> = {
  [key in keyof T]: SelectedType extends T[key] ? key : never;
}[keyof T];

type OptionalUndefined<T> = Partial<Pick<T, KeysOfType<T, undefined>>>;

/**
 * Removes all the keys of a object that their values are undefined.
 * e.g.
 * ```typescript
 * type A = {
 *   a: string;
 *   b?: number;
 *   c?: string;
 * };
 *
 * // B is { a: string }
 * type B = OmitUndefined<A>;
 * ```
 **/
export type OmitUndefined<T> = Omit<T, KeysOfType<T, undefined>>;
export type AddUndefined<T> = {
  [K in keyof T]: T[K] | undefined;
};

/**
 * Makes all the keys of a object that their values are undefined optional.
 * e.g.
 * ```typescript
 * type A = {
 *    a: string;
 *    b: number | undefined;
 *    c: string | undefined;
 *  };
 *  // B is { a: string; b?: number | undefined; c?: string | undefined; }
 *  type B = MakeUndefinedOptional<A>;
 * ```
 **/
export type MakeUndefinedOptional<T> = OptionalUndefined<T> & OmitUndefined<T>;
export type MakeOptionalUndefined<T> = OmitUndefined<T> &
  AddUndefined<Required<Pick<T, KeysOfType<T, undefined>>>>;

/**
 * Recursively extracts the complement of a object by the other.
 * the complement of a object is all the keys that are in the first object but not in the second.
 * e.g.
 * ```typescript
 * type A = {
 *   a: string;
 *   b: number;
 *   c: {
 *     d: string;
 *     e: number;
 *   };
 * };
 *
 * type B = {
 *   a: string;
 *   c: {
 *     d: string;
 *   };
 * };
 *
 * // C is { b: number; c: { e: number; } }
 * type C = Complement<A, B>;
 * ```
 **/
export type Complement<A extends AnyObj, B extends AnyObj | unknown> = {
  [K in Exclude<keyof A, keyof B>]: A[K];
} & {
  [K in keyof A & keyof B as A[K] extends AnyObj
    ? B[K] extends AnyObj
      ? B[K] extends A[K]
        ? never
        : K
      : never
    : never]: A[K] extends AnyObj
    ? B[K] extends AnyObj
      ? MakeUndefinedOptional<Complement<A[K], B[K]>>
      : never
    : never;
};

/**
 * Recursively makes all the keys of a object optional.
 * e.g.
 * ```typescript
 * type A = {
 *   a: string;
 *   b: {
 *     c: string;
 *   };
 * };
 *
 * // B is { a?: string; b?: { c?: string; } }
 * type B = RecursivePartial<A>;
 **/
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

export type UseQueryOptions<T extends DocumentValue> = {
  query: T;
  input?: QueryInput<T>;
  /**
   * If true, the query will not be executed.
   **/
  skip?: boolean;
};

export type UseQueryOutput<T extends DocumentValue> = {
  loading: boolean;
  refetch: (input?: QueryInput<T>) => void;
  error?: ApolloError;
  value: QueryOutput<T> | undefined;
  setValue: React.Dispatch<React.SetStateAction<QueryOutput<T> | undefined>>;
  subscribeToMore: (
    options: SubscribeToMoreOptions<
      QueryOutput<T>,
      QueryInput<T>,
      QueryOutput<T>
    >,
  ) => () => void;
  fetchMore(
    fetchMoreOptions: FetchMoreQueryOptions<QueryInput<T>, QueryOutput<T>> & {
      updateQuery?: (
        previousQueryResult: QueryOutput<T>,
        options: {
          fetchMoreResult: QueryOutput<T>;
          variables: QueryInput<T>;
        },
      ) => QueryOutput<T>;
    },
  ): Promise<ApolloQueryResult<QueryOutput<T>>>;
  abort: () => void;
};

export type UseLazyQueryOptions<T extends DocumentValue> = {
  query: T;
  onSuccess?: (value: QueryOutput<T>) => void;
  onError?: (err: ApolloError) => void;
};

export type UseLazyQueryOutput<T extends DocumentValue> = UseQueryOutput<T> & {
  input: QueryInput<T> | undefined;
  setInput: React.Dispatch<React.SetStateAction<QueryInput<T> | undefined>>;
};

export type UseMutationOptions<
  T extends DocumentValue,
  I1 extends RecursivePartial<QueryInput<T>> | Record<string, never> = Record<
    string,
    never
  >,
> = {
  query: T;
  /**
   * The base input is a object that is merged with the input of the mutation.
   * This is useful when you want to set a default value for a input or you already have some values.
   **/
  baseInput?: I1;
  onSuccess?: (value: QueryOutput<T>) => void;
  onError?: (err: ApolloError) => void;
};

/**
 * Recursively merges two objects.
 * e.g.
 * ```typescript
 * const a = {
 *   a: "a",
 *   b: {
 *     c: "c",
 *   },
 * };
 * const b = {
 *  a: "b",
 *  b: {
 *    e: "e",
 *  },
 * };
 * // c is { a: "b", b: { c: "c", e: "e" } }
 * const c = recursiveMerge(a, b);
 **/
export const recursiveMerge = <
  T extends AnyObj,
  I1 extends RecursivePartial<T>,
  I2 extends Complement<T, I1>,
>(
  a: I1,
  b: I2,
): T => {
  const keys = Object.keys(b) as (keyof I2)[];
  return keys.reduce((acc, key) => {
    const value = b[key];
    if (value == null) return acc;
    if (typeof value === "object" && !Array.isArray(value)) {
      return {
        ...acc,
        [key]: recursiveMerge(a[key as keyof typeof a] ?? {}, value),
      };
    }
    return {
      ...acc,
      [key]: value,
    };
  }, a) as unknown as T;
};

/**
 * Recursively removes the __typename key from a object.
 * e.g.
 * ```typescript
 * const a = {
 *  __typename: "Test",
 *    a: "a",
 *    b: {
 *      __typename: "Test",
 *      c: "c",
 *    },
 *  };
 *  // b is { a: "a", b: { c: "c" } }
 *  const b = recursiveRemoveTypename(a);
 **/
export function recursiveRemoveTypename<T extends AnyObj>(obj: T): T {
  return (
    obj &&
    (Object.fromEntries(
      Object.entries(obj)
        .map(([key, value]) => {
          if (key === "__typename") return [];
          if (typeof value === "object" && !Array.isArray(value)) {
            return [key, recursiveRemoveTypename(value)];
          }
          return [key, value];
        })
        .filter((v) => v.length > 0),
    ) as T)
  );
}

/**
 * Is used to make a query request.
 **/
export function useQueryRequest<T extends DocumentValue>(
  opts: UseQueryOptions<T>,
): UseQueryOutput<T> {
  const abortController = useRef(new AbortController());
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const [value, setValue] = useState<QueryOutput<T>>();
  const [customLoading, setCustomLoading] = useState(false);
  const skip = useMemo(
    () => opts.skip || opts.input == null,
    [opts.input, opts.skip],
  );
  const { data, error, refetch, loading, subscribeToMore, fetchMore } =
    useQuery<QueryOutput<T>, QueryInput<T>>(opts.query, {
      variables: opts.input,
      skip,
      defaultOptions: {
        fetchPolicy: "cache-and-network",
      },
      context: {
        fetchOptions: {
          signal: abortController.current.signal,
        },
      },
    });
  useEffect(() => {
    if (skip) {
      setValue((prev) => {
        if (prev == null) return prev;
        return undefined;
      });
    }
    if (!data) return;

    setValue(data);
    setCustomLoading(false);
  }, [data, refetch, skip]);

  return {
    loading: useMemo(
      () => customLoading || loading || value == null,
      [customLoading, loading, value],
    ),
    refetch: useCallback(
      (inp) => {
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          refetch(inp).then((res) => {
            if (!res.data) return undefined;
            setValue(res.data);
          });
        }, 50);
      },
      [refetch],
    ),
    abort: useCallback(() => {
      abortController.current.abort();
      abortController.current = new AbortController();
    }, []),
    subscribeToMore,
    fetchMore,
    value,
    setValue,
    error,
  };
}

/**
 * Is used to make a lazy query request.
 * A lazy query request is a query that is only executed when the input changes.
 **/
export function useLazyQueryRequest<T extends DocumentValue>(
  opts: UseLazyQueryOptions<T>,
): UseLazyQueryOutput<T> {
  const abortController = useRef(new AbortController());
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const [value, setValue] = useState<QueryOutput<T>>();
  const [customLoading, setCustomLoading] = useState(false);
  const [input, setInput] = useState<QueryInput<T>>();
  const [
    execute,
    { data, error, refetch, loading, subscribeToMore, fetchMore },
  ] = useLazyQuery<QueryOutput<T>, QueryInput<T>>(opts.query, {
    defaultOptions: {
      fetchPolicy: "cache-and-network",
    },
    context: {
      fetchOptions: {
        signal: abortController.current.signal,
      },
    },
  });

  useEffect(() => {
    if (!data) return;
    setValue(data);
  }, [data]);

  useEffect(() => {
    if (!input) return;
    setCustomLoading(true);
    const timeout = setTimeout(async () => {
      return await execute({ variables: input })
        .then((res) => {
          if (!res.data) return undefined;
          opts.onSuccess?.(res.data);
          return res.data;
        })
        .catch((err) => {
          opts.onError?.(err);
        })
        .finally(() => {
          setCustomLoading(false);
        });
    }, 50);
    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, input]);

  return {
    loading: useMemo(
      () => customLoading || loading || (value == null && input != null),
      [customLoading, input, loading, value],
    ),
    refetch: useCallback(
      (inp) => {
        setValue(undefined);
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
          refetch(inp).then((res) => {
            if (!res.data) return undefined;
            setValue(res.data);
          });
        }, 50);
      },
      [refetch],
    ),
    input,
    abort: useCallback(() => {
      abortController.current.abort();
      abortController.current = new AbortController();
    }, []),
    setInput,
    value,
    setValue,
    subscribeToMore,
    fetchMore,
    error,
  };
}

function useCommonMapQueryRequest<
  T extends ArrayDocuments,
  K extends ArrayHashableKeys<T>,
  QN extends QueryName<T>,
>(
  output: UseQueryOutput<T>,
  opts: {
    queryName: QN;
    key: K;
  },
): Omit<UseQueryOutput<T>, "value"> & {
  value: Map<ArrayDocumentValue<T>[K], ArrayDocumentValue<T>> | undefined;
} {
  const value = useMemo(() => {
    if (!output.value) return undefined;
    return new Map(
      (output.value[opts.queryName] as ArrayDocumentValue<T>[]).map((v) => [
        v[opts.key],
        v,
      ]),
    );
  }, [opts.key, opts.queryName, output.value]);
  return {
    ...output,
    loading: (output.value && value == null) || output.loading,
    value,
  };
}

/**
 * Is used to make a query request that returns a array of a object.
 * The array is converted to a Map where the key is the value of the key property.
 **/
export function useMapQueryRequest<
  T extends ArrayDocuments,
  K extends ArrayHashableKeys<T>,
  QN extends QueryName<T>,
>(
  opts: {
    queryName: QN;
    key: K;
  } & UseQueryOptions<T>,
): Omit<UseQueryOutput<T>, "value"> & {
  value: Map<ArrayDocumentValue<T>[K], ArrayDocumentValue<T>> | undefined;
} {
  return useCommonMapQueryRequest(useQueryRequest(opts), opts);
}

/**
 * Is used to make a lazy query request that returns a array of a object.
 * The array is converted to a Map where the key is the value of the key property.
 * A lazy query request is a query that is only executed when the input changes.
 **/
export function useLazyMapQueryRequest<
  T extends ArrayDocuments,
  K extends ArrayHashableKeys<T>,
  QN extends QueryName<T>,
>(
  opts: {
    queryName: QN;
    key: K;
  } & UseLazyQueryOptions<T>,
): Omit<UseLazyQueryOutput<T>, "value"> & {
  value: Map<ArrayDocumentValue<T>[K], ArrayDocumentValue<T>> | undefined;
} {
  const { input, setInput, ...query } = useLazyQueryRequest(opts);
  return {
    ...useCommonMapQueryRequest(query, opts),
    input,
    setInput,
  };
}

/**
 * Used to make a mutation request.
 * You can use this hook in two ways:
 * 1. You can use the run function to execute the mutation.
 * 2. You can use the input and setInput to set the input of the mutation.
 **/
export function useMutationRequest<
  T extends DocumentValue,
  I2 extends Complement<QueryInput<T>, I1>,
  // eslint-disable-next-line @typescript-eslint/ban-types
  I1 extends RecursivePartial<QueryInput<T>> = {},
>({
  query,
  baseInput,
  onSuccess,
  onError,
}: UseMutationOptions<T, I1>): {
  loading: boolean;
  loadingRef: React.MutableRefObject<boolean>;
  input: I2 | undefined;
  setInput: React.Dispatch<React.SetStateAction<I2 | undefined>>;
  value: QueryOutput<T> | undefined;
  run: (input: I2) => Promise<QueryOutput<T> | undefined>;
  error: ApolloError | undefined;
} {
  const [run, { data, loading, error }] = useMutation<
    QueryOutput<T>,
    QueryInput<T>
  >(query);
  const [input, setInput] = useState<I2 | undefined>(undefined);
  const [customLoading, setCustomLoading] = useState(false);
  const loadingRef = useRef(false);
  useEffect(() => {
    window.addEventListener("beforeunload", (e) => {
      if (loadingRef.current) {
        e.preventDefault();
        e.returnValue = "";
      }
    });
    return () => {
      window.removeEventListener("beforeunload", () => {});
    };
  }, []);

  const customRun = useCallback(
    async (input: I2) => {
      let isLoading = false;
      setCustomLoading((prev) => {
        isLoading = prev;
        if (prev) return prev;
        return true;
      });
      if (isLoading) return;
      loadingRef.current = true;
      return await run({
        variables: recursiveRemoveTypename(
          recursiveMerge<QueryInput<T>, I1, I2>(baseInput ?? ({} as I1), input),
        ),
      })
        .then((res) => {
          if (!res.data) return undefined;
          const v = res.data;
          onSuccess?.(v);
          return v;
        })
        .catch((err) => {
          onError?.(err);
          return undefined;
        })
        .finally(() => {
          setInput(undefined);
          setCustomLoading(false);
          loadingRef.current = false;
        });
    },
    [run, baseInput, onSuccess, onError],
  );

  useEffect(() => {
    if (!customLoading || !data) return;
    setCustomLoading(false);
  }, [customLoading, data]);

  return {
    loading: customLoading || loading,
    loadingRef,
    input,
    error,
    setInput: useCallback(
      (action) => {
        let value = action;
        if (typeof action === "function") {
          setInput((input) => {
            if (input == null) return undefined;
            value = action(input);
            return value;
          });
        } else {
          setInput(action);
        }
        if (value) customRun(value as I2);
      },
      [customRun],
    ),
    value: data ?? undefined,
    run: customRun,
  };
}
