import { Root } from "@radix-ui/react-avatar";

import { LoadingProfileImage, ProfileFallback, ProfileImage } from "./styles";
import { Preconnect } from "../utils/preconnect";
import { User } from "../../gql/graphql";
import Colors from "../../utils/ColorConstants";

type ProfileProps = {
  src?: string;
  name?: string;
  user?: User;
  style?: React.CSSProperties;
};

const PROFILE_COLORS = [
  Colors.PURPLE_DARK,
  Colors.BLUE,
  Colors.BLUE_LIGHT,
  Colors.GREEN,
  Colors.YELLOW,
  Colors.ORANGE,
  Colors.RED,
  Colors.PINK,
];

export function Profile(props: ProfileProps) {
  const name =
    props.name ??
    (props.user
      ? `${props.user.first_name} ${props.user.last_name}`
      : undefined);
  const src = props.src ?? props.user?.presigned_url;
  const nameAsNumber = name
    ?.split("")
    .reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const color = nameAsNumber
    ? PROFILE_COLORS[nameAsNumber % PROFILE_COLORS.length]
    : undefined;

  return (
    <Root>
      {!name ? (
        <LoadingProfileImage variant="circular" />
      ) : (
        <>
          <Preconnect url={src} />

          <ProfileImage src={src ?? undefined} style={props.style} />
          <ProfileFallback
            style={{ color, background: `${color}30`, ...props.style }}
          >
            {src ? <></> : name?.charAt(0)?.toUpperCase() ?? ""}
          </ProfileFallback>
        </>
      )}
    </Root>
  );
}
