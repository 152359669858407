import { styled } from "@mui/material";
import { border } from "../../utils/ColorConstants";

export const Container = styled("div")`
  height: 100vh;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.5rem;
  background: #0f1116;
  transition: 0.1s all ease-in-out;
`;

interface ContainerCompanyProps {
  isCollapsed: boolean;
}

export const ContainerCompany = styled("div")<ContainerCompanyProps>(
  ({ isCollapsed }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: isCollapsed ? "center" : "flex-start",
    // paddingLeft: ""9px,
    padding: "4px",
    gap: "0.5rem",
    width: "100%",
    height: "2rem",
    overflow: "hidden",
    cursor: "pointer",
    borderRadius: "8px",
    "&:hover": {
      color: "blue",
      border: border,
    },
  }),
);
