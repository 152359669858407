import { SvgIcon } from "@mui/material";
import { CustomIconProps } from ".";

export function AssetsIcon(props: CustomIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="assembly">
          <g id="Vector">
            <path
              d="M13.25 4.18038C13.4791 4.31068 13.6694 4.49969 13.8012 4.72795C13.933 4.9562 14.0016 5.21546 14 5.47904V10.335C14 10.8744 13.7047 11.3717 13.228 11.6337L8.728 14.4804C8.5049 14.6029 8.25451 14.6671 8 14.6671C7.74549 14.6671 7.4951 14.6029 7.272 14.4804L2.772 11.6337C2.53878 11.5063 2.34408 11.3185 2.20827 11.09C2.07247 10.8616 2.00053 10.6008 2 10.335V5.47838C2 4.93904 2.29533 4.44238 2.772 4.18038L7.272 1.52704C7.50169 1.4004 7.75971 1.33398 8.022 1.33398C8.28429 1.33398 8.54231 1.4004 8.772 1.52704L13.272 4.18038H13.25Z"
              stroke="currentColor"
              style={{
                stroke: "currentColor",
              }}
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.3333 6.28173C10.5413 6.40173 10.6687 6.62507 10.6667 6.86573V9.0504C10.6667 9.29307 10.5353 9.51707 10.3233 9.63507L8.32333 10.9164C8.2244 10.9713 8.11313 11.0001 8 11.0001C7.88687 11.0001 7.7756 10.9713 7.67667 10.9164L5.67667 9.63507C5.57248 9.57728 5.48567 9.49263 5.42529 9.38993C5.36491 9.28722 5.33316 9.17021 5.33333 9.05107V6.86573C5.33333 6.62307 5.46467 6.39907 5.676 6.28107L7.676 5.08773C7.88333 4.97173 8.136 4.97173 8.34267 5.08773L10.3427 6.28107H10.3333V6.28173Z"
              stroke="currentColor"
              style={{
                stroke: "currentColor",
              }}
              stroke-width="1.25"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}
