import { Box, IconButton, Typography } from "@mui/material";
import { useControlKey } from "../../gql-hooks/useIsMac";
import Colors from "../../utils/ColorConstants";
import {
  IconArrowDown,
  IconArrowUp,
  IconCornerDownLeft,
  TablerIconsProps,
} from "@tabler/icons-react";

export type KeyBindStyleType = "light" | "dark";

const KEY_BIND_STYLE: Record<
  KeyBindStyleType,
  { color: string; background: string }
> = {
  dark: {
    color: Colors.LIGHT3,
    background: Colors.DEFAULT,
  },
  light: {
    color: Colors.LIGHT,
    background: Colors.BLUE_WHITE,
  },
};

const ShiftKey = ({ color }: { color: string }) => {
  return (
    <Typography
      sx={{
        color,
        fontSize: "10px",
        fontWeight: 700,
        letterSpacing: "0.1em",
        lineHeight: "1rem",
        textAlign: "center",
      }}
    >
      SHIFT
    </Typography>
  );
};

const SingleKeyBind = ({
  children,
  background,
  color,
  onClick,
}: {
  children: React.ReactNode;
  background: string;
  color: string;
  onClick?: (e: React.MouseEvent) => void;
}) => {
  return (
    <IconButton
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick?.(e);
      }}
      sx={{
        background,
        color,
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center",
        padding: "0.25rem",
        borderRadius: "0.25rem",
        fontWeight: 700,
        fontSize: "10px",
        textAlign: "center",
        lineHeight: "1rem",
        height: "1rem",
        minWidth: "1rem",
        svg: {
          color,
          stroke: color,
          strokeWidth: 2,
        },
      }}
    >
      {children}
    </IconButton>
  );
};

const KEY_ICONS: Record<string, (props: TablerIconsProps) => JSX.Element> = {
  ENTER: IconCornerDownLeft,
  ARROWDOWN: IconArrowDown,
  ARROWUP: IconArrowUp,
};

export type KeyBindProps = {
  keyLetter: string;
  styleType?: KeyBindStyleType;
  ctrlKey?: boolean;
  shiftKey?: boolean;
  onClick?: (e: React.MouseEvent) => void;
};

export const KeyBind = (props: KeyBindProps) => {
  const type = props.styleType ?? "light";
  const { color, background } = KEY_BIND_STYLE[type];
  const { iconControl } = useControlKey(color);
  const key = props.keyLetter.toUpperCase();
  const KeyIcon = key in KEY_ICONS ? KEY_ICONS[key] : null;
  const keyProps = {
    color,
    background,
    onClick: props.onClick,
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        alignContent: "center",
        gap: "0.25rem",
      }}
    >
      {props.ctrlKey && (
        <SingleKeyBind {...keyProps}>{iconControl}</SingleKeyBind>
      )}
      {props.shiftKey && (
        <SingleKeyBind {...keyProps}>
          <ShiftKey color={color} />
        </SingleKeyBind>
      )}
      <SingleKeyBind {...keyProps}>
        {KeyIcon ? (
          <KeyIcon
            style={{
              position: "absolute",
              height: "0.75rem",
              width: "0.75rem",
              marginLeft: "-1px",
              marginRight: "-1px",
            }}
          />
        ) : (
          key
        )}
      </SingleKeyBind>
    </Box>
  );
};
