import { Skeleton, styled } from "@mui/material";
import Colors, { border } from "../../../utils/ColorConstants";

export const Container = styled("div")<{ removeBorder?: boolean }>`
  display: flex;
  padding: 0rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 0rem;
  align-self: stretch;
  border-radius: 1rem;
  border: ${({ removeBorder }) => (removeBorder ? "none" : border)};
  background: ${Colors.WHITE};
  outline: none;
`;

export const ContainerLoading = styled(Skeleton)`
  width: 100%;
  height: 100%;
  transform: none;
`;
