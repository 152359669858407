import Colors from "../../utils/ColorConstants";
import { Avatar } from "@mui/material";
import { useImage } from "../../gql-hooks/useImage";
import { IconPhotoFilled } from "@tabler/icons-react";
import { Preconnect } from "../utils/preconnect";

export function AssetImage({
  src,
  radius = "0.5rem",
  avatarName,
  isAvatar,
}: {
  src: string | undefined | null;
  radius?: string;
  avatarName?: string;
  isAvatar?: boolean;
}) {
  const { loadingThumbnail, thumbnail } = useImage({
    presigned_url: src,
  });
  const style = {
    minWidth: "1rem",
    minHeight: "1rem",
    width: "1rem",
    height: "1rem",
    borderRadius: radius,
    fontSize: "10px",
    fontWeight: 600,
    color: "black",
  };
  return loadingThumbnail || (!loadingThumbnail && !thumbnail) ? (
    <IconPhotoFilled
      size="1rem"
      color={Colors.BLUE_GRAY}
      style={{
        borderRadius: isAvatar ? "50%" : radius,
      }}
    />
  ) : isAvatar ? (
    <>
      <Preconnect url={thumbnail} />

      <Avatar
        src={thumbnail}
        sx={{
          ...style,
          borderRadius: "50%",
        }}
      >
        {thumbnail ? "" : avatarName?.charAt(0)}
      </Avatar>
    </>
  ) : (
    <>
      <Preconnect url={thumbnail} />

      <img style={style} src={thumbnail!} />
    </>
  );
}
