import { styled } from "@mui/material";

export const ApplicationLayoutContainer = styled("div")`
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;

  position: relative;
`;

export const ApplicationLayoutContent = styled("div")`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  transition: 0.1s all;
`;
