import { Box, Skeleton } from "@mui/material";
import Colors, { Spacing } from "../../utils/ColorConstants";
import { getTaskIcon, mountTaskPath } from "../../utils/task";
import { Scopes, TaskType } from "../../gql/graphql";
import { useMemo } from "react";
import { TypographyBody, TypographySemiBold } from "../Typography";
import { CustomTooltip } from "../ChangeTooltip";
import CustomLink from "../ReferenceButtonContent/link";
import { TFunction } from "i18next";

export const TaskParent = (props: {
  parentTask?: {
    name: React.ReactNode;
    description: React.ReactNode;
    type: TaskType;
    scopes: Scopes;
  } | null;
  loading?: boolean;
  hideIn?: boolean;
  clickable?: boolean;
  maxWidth?: string;
  t: TFunction<"main", "task">;
}) => {
  const { t } = props;
  const TaskIcon = getTaskIcon(props.parentTask?.type);
  const path = useMemo(
    () => mountTaskPath(props.parentTask?.scopes),
    [props.parentTask],
  );
  if (!props.parentTask) return null;
  if (props.loading) return <Skeleton />;
  return (
    <Box
      sx={{
        display: "flex",
        gap: Spacing.SMALL,
        alignItems: "center",
      }}
    >
      {!props.hideIn && <TypographyBody children={t("in")} />}
      <CustomTooltip
        title={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            <TypographySemiBold children={props.parentTask.name} />
            <TypographyBody children={props.parentTask.description} />
          </Box>
        }
        placement="top"
        arrow
      >
        <CustomLink to={path}>
          <Box
            sx={{
              maxWidth: props.maxWidth ?? "6.25rem",
              display: "flex",
              flexDirection: "row",
              gap: Spacing.SMALL,
              alignItems: "center",
            }}
          >
            {TaskIcon && (
              <TaskIcon
                style={{
                  width: "16px",
                  minWidth: "16px",
                  height: "16px",
                  color: Colors.LIGHT2,
                }}
              />
            )}
            <TypographyBody sizelimit={props.maxWidth ?? "5rem"}>
              {props.parentTask.name}
            </TypographyBody>
          </Box>
        </CustomLink>
      </CustomTooltip>
    </Box>
  );
};
