import { graphql } from "../gql";
import { ScopeInput, Scopes, Task, TaskType } from "../gql/graphql";
import { useCallback, useEffect, useMemo, useState } from "react";
import { scopesToScopeInput } from "../utils/task";
import { useLazyQueryRequest, useQueryRequest } from "./queries";

export const LIST_PARENT_TASK = graphql(`
  query TaskParents($scope: ScopeInput!, $scopes: [ScopeInput!]!) {
    taskParents(scope: $scope, scopes: $scopes) {
      company_id
      task_id
      parent_task_id
      parent_type
      process_parent_id
      reference_of
      owner_id
      created_at
      updated_at
      deleted_at
      type
      name
      description
      due_date
      start_date
      status_stage_id
      priority
      code_number
      has_thumbnail
      fields
      scopes {
        Owner
        Company
        Project
        SKU
        Part
        Task
      }
      put_presigned_url
      presigned_url
    }
  }
`);
export const useScopeTasks = (scope: ScopeInput) => {
  const { value, refetch } = useQueryRequest({
    query: LIST_PARENT_TASK,
    input: { scope, scopes: [scope] },
  });
  const [response, setResponse] = useState<{
    loading: boolean;
    refetch: () => void;
    project?: Task;
    sku?: Task;
    part?: Task;
    referenceOf?: Task;
  }>({
    loading: true,
    refetch,
  });

  useEffect(() => {
    if (!value) {
      setResponse({
        loading: true,
        refetch,
      });
      return;
    }
    const bl = [scope.Project, scope.SKU, scope.Part].filter(
      (s) => s,
    ) as string[];
    setResponse({
      loading: false,
      refetch,
      project: value.taskParents.find(
        (task) =>
          task.type === TaskType.Project && task.task_id === scope.Project,
      ),
      sku: value.taskParents.find(
        (task) => task.type === TaskType.Sku && task.task_id === scope.SKU,
      ),
      part: value.taskParents.find(
        (task) => task.type === TaskType.Part && task.task_id === scope.Part,
      ),
      referenceOf: value.taskParents.find((task) => !bl.includes(task.task_id)),
    });
  }, [refetch, value]);
  return { response, setResponse };
};

export const useTaskParent = () => {
  const { value, setInput, ...response } = useLazyQueryRequest({
    query: LIST_PARENT_TASK,
  });

  return {
    listTaskParent: useCallback(
      async (scope: ScopeInput, scopes: (ScopeInput | Scopes)[]) => {
        return setInput({
          scope,
          scopes: scopes.map(scopesToScopeInput) as ScopeInput[],
        });
      },
      [setInput],
    ),
    tasks: useMemo(() => value?.taskParents, [value]),
    ...response,
  };
};
