// ExportContext.tsx
import { createContext, useContext, useState } from "react";

type ExportContextType = {
  exportId: string | null;
  setExportId: (id: string | null) => void;
};

const ExportContext = createContext<ExportContextType>({
  exportId: null,
  setExportId: () => {},
});

export const useExportContext = () => useContext(ExportContext);

export const ExportProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [exportId, setExportId] = useState<string | null>(null);

  return (
    <ExportContext.Provider value={{ exportId, setExportId }}>
      {children}
    </ExportContext.Provider>
  );
};
