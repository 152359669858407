import { TFunction } from "i18next";
import { ScopeInput, Scopes, TaskStatsType, TaskType } from "../gql/graphql";
import {
  IconCube,
  IconFolder,
  IconPuzzle,
  IconChevronUp,
  IconChevronDown,
  TablerIconsProps,
} from "@tabler/icons-react";

export const getOpenIcon = (open: boolean, openIconProps?: TablerIconsProps) =>
  open ? (
    <IconChevronUp {...openIconProps} />
  ) : (
    <IconChevronDown {...openIconProps} />
  );

export type TablerIcon = (props: TablerIconsProps) => JSX.Element;

export function getTaskIcon(type: TaskType): TablerIcon;
export function getTaskIcon(
  type: TaskType | undefined | null,
): TablerIcon | null;
export function getTaskIcon(
  type: TaskType | undefined | null,
): TablerIcon | null {
  switch (type) {
    case TaskType.Project:
      return IconFolder;
    case TaskType.Sku:
      return IconCube;
    case TaskType.Part:
      return IconPuzzle;
    default:
      return null;
  }
}

export function getTypeFromScope(
  scope: Scopes | ScopeInput,
): TaskType | undefined;
export function getTypeFromScope(
  scope: Scopes | ScopeInput | undefined,
): TaskType | undefined;
export function getTypeFromScope(
  scope: Scopes | ScopeInput | undefined,
): TaskType | undefined {
  if (!scope) return scope;
  if (scope.Task) {
    if (scope.Part) return TaskType.PartTask;
    if (scope.SKU) return TaskType.SkuTask;
    if (scope.Project) return TaskType.ProjectTask;
  }
  if (scope.Part) return TaskType.Part;
  if (scope.SKU) return TaskType.Sku;
  if (scope.Project) return TaskType.Project;
  return undefined;
}

export function getTaskTypeName(
  t: TFunction<"main", "task">,
  type: TaskType | TaskStatsType,
): string;
export function getTaskTypeName(
  t: TFunction<"main", "task">,
  type: TaskType | TaskStatsType | undefined | null,
): string | undefined;
export function getTaskTypeName(
  t: TFunction<"main", "task">,
  type: TaskType | TaskStatsType | undefined | null,
): string | undefined {
  if (!type) return undefined;
  switch (type) {
    case TaskType.Project:
      return t("type.project");
    case TaskType.Sku:
      return t("type.sku");
    case TaskType.Part:
      return t("type.part");
    default:
      return t("type.task");
  }
}

export type RowType = Exclude<
  TaskType,
  | TaskType.CompanyTask
  | TaskType.ProjectTask
  | TaskType.SkuTask
  | TaskType.PartTask
>;

export function rowTypeToTaskStatsType(type: RowType): TaskStatsType;
export function rowTypeToTaskStatsType(
  type: RowType | undefined,
): TaskStatsType | undefined;
export function rowTypeToTaskStatsType(
  type: RowType | undefined,
): TaskStatsType | undefined {
  if (!type) return undefined;
  switch (type) {
    case TaskType.Project:
      return TaskStatsType.Project;
    case TaskType.Sku:
      return TaskStatsType.Sku;
    case TaskType.Part:
      return TaskStatsType.Part;
    default:
      return TaskStatsType.Task;
  }
}

export const nextRowType = (
  type: TaskType | RowType | undefined,
): RowType | undefined => {
  switch (type) {
    case TaskType.Project:
      return TaskType.Sku;
    case TaskType.Sku:
      return TaskType.Part;
    default:
      return undefined;
  }
};

export const getTaskIdFromScope = (
  scope: Scopes | ScopeInput,
): string | undefined => {
  if (scope.Task) return scope.Task;
  if (scope.Part) return scope.Part;
  if (scope.SKU) return scope.SKU;
  if (scope.Project) return scope.Project;
  return undefined;
};
export const getParentTaskIdFromScope = (
  scope: Scopes | ScopeInput,
): string | undefined => {
  if (scope.Task && scope.Part) return scope.Part;
  if (scope.Task && scope.SKU) return scope.SKU;
  if (scope.Task && scope.Project) return scope.Project;
  if (scope.Part) return scope.SKU!;
  if (scope.SKU) return scope.Project!;
  return undefined;
};
export const getParentScopeFromScope = (
  scope: Scopes | ScopeInput,
): ScopeInput => {
  if (scope.Part)
    return { Company: scope.Company, Project: scope.Project, SKU: scope.SKU };
  if (scope.SKU) return { Company: scope.Company, Project: scope.Project };
  return { Company: scope.Company };
};

export const beforeRowType = (
  type: TaskType | RowType | undefined,
): RowType | undefined => {
  switch (type) {
    case TaskType.Sku:
      return TaskType.Project;
    case TaskType.Part:
      return TaskType.Sku;
    default:
      return undefined;
  }
};
export function mountTaskPath(
  scope: Scopes | ScopeInput,
  ignore?: Array<keyof ScopeInput>,
): string;
export function mountTaskPath(
  scope: Scopes | ScopeInput | undefined,
  ignore?: Array<keyof ScopeInput>,
): string | undefined;
export function mountTaskPath(
  scope: Scopes | ScopeInput | undefined | null,
  ignore?: Array<keyof ScopeInput>,
): string | undefined | null {
  if (!scope) return scope;
  let path = `/project/${scope.Project}`;
  if (scope.SKU && !ignore?.includes("SKU")) {
    path = `/project/${scope.Project}/${scope.SKU}`;
  }
  if (scope.Part && !ignore?.includes("Part")) {
    path = `/project/${scope.Project}/${scope.SKU}/${scope.Part}`;
  }
  return path;
}

export const scopesToTaskType = (scopes: Scopes | ScopeInput): RowType => {
  if (scopes.Part) return TaskType.Part;
  if (scopes.SKU) return TaskType.Sku;
  if (scopes.Project) return TaskType.Project;
  throw new Error("Invalid scope");
};
export const scopesToTaskTypeOpt = (
  scopes: Scopes | ScopeInput | null | undefined,
): RowType | null => {
  if (!scopes) return null;
  try {
    return scopesToTaskType(scopes);
  } catch {
    return null;
  }
};
export function scopeEquals(
  scope1: Scopes | ScopeInput | null | undefined,
  scope2: Scopes | ScopeInput | null | undefined,
): boolean {
  if (!scope1 || !scope2) return false;
  for (const key of ["Company", "Project", "SKU", "Part", "Task"] as const) {
    if (scope1[key] != scope2[key]) return false;
  }
  return true;
}

export function scopesToScopeInput(scopes: Scopes | ScopeInput): ScopeInput;
export function scopesToScopeInput(
  scopes: Scopes | ScopeInput | null,
): ScopeInput | null;
export function scopesToScopeInput(
  scopes: Scopes | ScopeInput | undefined,
): ScopeInput | undefined;
export function scopesToScopeInput(
  scopes: Scopes | ScopeInput | undefined | null,
): ScopeInput | undefined | null {
  if (!scopes) return scopes;
  return {
    Company: scopes.Company,
    Project: scopes.Project,
    SKU: scopes.SKU,
    Part: scopes.Part,
    Task: scopes.Task,
  };
}
