import { styled } from "@mui/material";
import { InputBase } from "@mui/material";
import Colors, { border } from "../../utils/ColorConstants";

export const SearchContainer = styled("div")<{ disabled?: boolean }>`
  display: flex;
  height: 2rem;
  max-height: 2rem;
  padding: 0.5rem 0.5rem 0.5rem 0.75rem;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  align-self: stretch;
  justify-content: space-between;
  cursor: text;
  flex: 1 1 0;

  border-radius: 0.5rem;
  border: ${border};
  background: ${Colors.WHITE};

  svg {
    width: 1rem;
    height: 1rem;
    color: #617994;
    flex-shrink: 1;
  }
`;

export const SearchInputBase = styled(InputBase)`
  width: 100%;
  color: #617994;

  font-family: Manrope;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
