import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ApplicationLayout } from "./components/layouts/ApplicationLayout";
import { NotFound } from "./components/404";
import PrivateRoute from "./guards/private-route";
import UnderConstruction from "./components/UnderConstruction";
import { ComposeGuards } from "./guards/util";
import CompanyRoute from "./guards/company-route";
import { InviteRoute } from "./guards/invite-route";
import ChangesProvider from "./guards/changes-context";
import React from "react";
import { importOrReload } from "./utils/lazy-module";
import { DragIsActiveProvider } from "./context/DragIsActive";
import { UserBrowserPreferenceProvider } from "./context/UserBrowserPreference";

const lazyGetPage = <F extends () => Promise<{ default: React.ComponentType }>>(
  getPage: F,
) => {
  return async () => {
    return await importOrReload(getPage).then((module) => {
      return {
        Component: module.default as React.ComponentType,
      };
    });
  };
};

const authenticatedRoutes = createBrowserRouter([
  {
    element: (
      <ComposeGuards
        guards={[
          PrivateRoute,
          UserBrowserPreferenceProvider,
          DragIsActiveProvider,
          InviteRoute,
          CompanyRoute,
        ]}
      >
        <ApplicationLayout />
      </ComposeGuards>
    ),
    children: [
      {
        path: "/home",
        index: true,
        lazy: lazyGetPage(() => import("./pages/Home")),
      },
      {
        path: "/project",
        lazy: lazyGetPage(() => import("./pages/Projects")),
      },
      {
        path: "/approvals",
        lazy: lazyGetPage(() => import("./pages/Approvals")),
      },
      {
        path: "/assets",
        lazy: lazyGetPage(() => import("./pages/Assets")),
      },
      {
        path: "/project/:projectId/manage-team",
        lazy: lazyGetPage(() => import("./components/manage-project-team")),
      },
      {
        path: "/skus",
        element: <UnderConstruction />,
      },

      {
        path: "/parts",
        element: <UnderConstruction />,
      },
      {
        path: "/project/:projectId/:skuId?/:partId?",
        async lazy() {
          const module = await importOrReload(
            () => import("./pages/Specific-Task"),
          );
          const SpecificTask = module.default;
          return {
            Component: () => (
              <ChangesProvider>
                <SpecificTask />
              </ChangesProvider>
            ),
          };
        },
      },
      {
        path: "/templates",
        element: <UnderConstruction />,
      },
      {
        path: "/tasks",
        lazy: lazyGetPage(() => import("./pages/Tasks")),
      },
      {
        path: "/activity",
        lazy: lazyGetPage(() => import("./pages/Activity")),
      },
      {
        path: "/archive",
        lazy: lazyGetPage(() => import("./pages/Archive")),
      },
      {
        path: "/settings",
        lazy: lazyGetPage(() => import("./pages/Settings")),
      },
      {
        path: "/settings/company-info",
        lazy: lazyGetPage(() => import("./pages/Company-Info")),
      },
      {
        path: "/settings/company-items",
        lazy: lazyGetPage(() => import("./pages/Company-Items")),
      },
      {
        path: "/settings/people",
        lazy: lazyGetPage(() => import("./pages/People")),
      },
      {
        path: "/profile",
        lazy: lazyGetPage(() => import("./pages/Profile")),
      },
      {
        path: "/settings/change-access",
        lazy: lazyGetPage(() => import("./pages/Change-Access")),
      },
    ],
  },
  {
    path: "/new-company",
    async lazy() {
      const module = await importOrReload(() => import("./pages/New-Company"));
      const NewCompany = module.default;
      return {
        Component: () => (
          <PrivateRoute>
            <NewCompany />
          </PrivateRoute>
        ),
      };
    },
  },
  {
    path: "/signin",
    lazy: lazyGetPage(() => import("./pages/Sign-in")),
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "/signUp",
    lazy: lazyGetPage(() => import("./pages/SignUp")),
  },
  {
    path: "/",
    lazy: lazyGetPage(() => import("./pages/Sign-in")),
  },
  {
    path: "/forgot-password",
    lazy: lazyGetPage(() => import("./pages/Forgot-Password")),
  },
  {
    path: "/invitation-code",
    async lazy() {
      const module = await importOrReload(
        () => import("./pages/InvitationCode"),
      );
      const InvitationCode = module.default;
      return {
        Component: () => (
          <PrivateRoute withoutUser>
            <InvitationCode />
          </PrivateRoute>
        ),
      };
    },
  },
  {
    path: "/reset-password",
    element: <UnderConstruction />,
  },
  {
    path: "/send-approval",
    lazy: lazyGetPage(() => import("./pages/SendApproval")),
  },
  {
    path: "/alpha-testing-agreement",
    element: (
      <object
        data="documents/agreement.pdf"
        type="application/pdf"
        width="100%"
        height="100%"
      />
    ),
  },
]);

export function AuthenticatedRoutes() {
  return <RouterProvider router={authenticatedRoutes} />;
}
