import { styled } from "@mui/material";

export const TasksBadgeContainer = styled("div")`
  display: flex;
  padding: 0.25rem 0.5rem;
  align-items: center;
  gap: 0.5rem;
`;

export const TasksBadgeItem = styled("div")`
  display: flex;
  height: 1.25rem;
  padding: 0.5rem 0.375rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  border-radius: var(--rounding-radius-s, 0.25rem);
  color: white;
`;
