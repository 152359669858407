import { styled } from "@mui/material";
import Colors from "../../utils/ColorConstants";

export const ToastText = styled("span")`
  overflow: hidden;
  color: ${Colors.DEFAULT};

  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
