import { styled } from "@mui/material";
import Colors from "../../utils/ColorConstants";

export const PageTitle = styled("div")`
  span {
    color: ${Colors.DEFAULT};
    font-size: 2rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.25rem; /* 62.5% */
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
