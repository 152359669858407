import { useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { Role } from "../utils/getRoleAndIcon";

export type InvitePath = "signup" | "signin";
export type ScopeInvite = {
  invitation_token: string;
  user_email: string;
  role_name: Role;
  company_name: string;
  path: InvitePath;
  is_social: boolean;
  invitation_code: string;
  data?: {
    password: string;
    first_name: string;
    last_name: string;
    user_role_id?: number;
    phone_number?: string;
    language?: string;
  };
};

export function useScopeInvite(options?: { autoSave?: boolean }) {
  const { autoSave = false } = options ?? {};
  const location = useLocation();
  const query = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  );
  const localInvite = JSON.parse(
    localStorage.getItem("scope_invite") || "null",
  ) as ScopeInvite | null;
  const invite = useMemo<ScopeInvite | null>(() => {
    if (!query?.get("invitation_token") && !localInvite) return null;
    if (localInvite) return localInvite;

    const inv = {
      invitation_token: query.get("invitation_token")!,
      user_email: atob(query.get("user_email")!),
      role_name: query.get("role_name")! as Role,
      company_name: query.get("company_name")!,
      path: query.get("path")! as InvitePath,
      is_social: query.get("is_social") === "true",
      invitation_code: atob(query.get("invitation_code")!),
    };
    if (autoSave) localStorage.setItem("scope_invite", JSON.stringify(inv));
    return inv;
  }, [autoSave, localInvite, query]);

  return {
    params: query,
    invite,
    save: useCallback(
      (data?: ScopeInvite["data"]) => {
        if (invite) {
          localStorage.setItem(
            "scope_invite",
            JSON.stringify({ ...invite, data: data ?? invite.data }),
          );
        }
      },
      [invite],
    ),
    clear: () => localStorage.removeItem("scope_invite"),
    getParams: () => {
      if (!invite) return undefined;
      const params = {
        ...invite,
        is_social: invite.is_social.toString(),
      };
      delete params.data;
      return new URLSearchParams(params as Omit<typeof params, "data">);
    },
  };
}
