import { AutocompleteGetTagProps } from "@mui/material";
import { useMemo } from "react";
import { Profile } from "../Profile";
import { Hashtag } from "../icons/hashtag";
import { IconX } from "@tabler/icons-react";

export type TagType = "assignee" | "tag" | "other";

export type TagOpt = {
  label: string;
  icon?: React.ReactNode;
};
export interface TagProps extends Partial<ReturnType<AutocompleteGetTagProps>> {
  opt: TagOpt;
  tagType?: TagType;
  shouldDisableDelete?: boolean;
  src?: string;
}
export function Tag(props: TagProps) {
  const {
    opt: { label, icon },
    onDelete,
    ...other
  } = props;
  const prefix = useMemo(() => {
    switch (props.tagType) {
      case "assignee":
        return (
          <Profile
            name={label}
            src={props.src}
            style={{ marginRight: "0.25rem" }}
          />
        );
      case "tag":
        return <Hashtag />;
      default:
        return null;
    }
  }, [label, props.src, props.tagType]);
  return (
    <div
      {...other}
      style={{
        columnGap: "0.25rem",
      }}
    >
      {prefix}
      {icon ?? null}

      <span>{label}</span>
      {!other.shouldDisableDelete && onDelete && (
        <IconX cursor="pointer" onClick={onDelete} />
      )}
    </div>
  );
}
