import { styled } from "@mui/material";
import Colors from "../../../utils/ColorConstants";

export const ContainerEmptyItems = styled("div")`
  display: flex;
  padding: 0rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  span {
    color: ${Colors.BLUE_GRAY};

    text-align: center;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    strong {
      color: ${Colors.BLUE_GRAY};

      text-align: center;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`;
