import { Container } from "./styles";
import { ToastItem } from "../ToastItem";

type ToastProps = {
  title: string;
  description: string;
  type: "Notification" | "Information" | "Warning" | "Error" | "Success";
};

const toastItemMappedTypes = {
  Notification: "notification",
  Information: "info",
  Warning: "warning",
  Error: "error",
  Success: "success",
} as const;

function Toast({ title, description, type }: ToastProps) {
  return (
    <Container>
      <ToastItem
        title={title}
        description={description}
        type={toastItemMappedTypes[type]}
      />
    </Container>
  );
}

export default Toast;
