import React, { ComponentProps } from "react";

import { signOut } from "aws-amplify/auth";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import {
  Box,
  ListItemIcon,
  Menu as MenuMUI,
  MenuItem as MenuItemMUI,
  Stack,
  Typography,
} from "@mui/material";

import { SidebarMenu } from "../";
import { useSidebarDesktop } from "../../../context/SidebarDesktopContext";
import { clearLocalStorage } from "../../../utils";
import Colors, { border } from "../../../utils/ColorConstants";
import { For } from "../../../utils/For";
import { Match } from "../../../utils/Match";
import { UserCircled } from "../../icons";
import { MenuItemButton, SubMenuItemButton } from "./styles";
import { IconDots } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

type MenuItemProps = {
  isCollapsed: boolean;
  isSelected?: boolean;
  menu: SidebarMenu;
  badge?: JSX.Element;
} & ComponentProps<typeof MenuItemButton>;
function MenuItem({
  isSelected = false,
  isCollapsed,
  menu,
  badge,
}: MenuItemProps) {
  const location = useLocation();
  const [menuUser, setMenuUser] = React.useState(false);

  return (
    <Stack direction="column" width="100%">
      <Stack
        direction="row"
        height="2.5rem"
        width={isCollapsed ? "40px" : "100%"}
        alignItems="center"
        justifyContent="center"
      >
        <Match when={isSelected}>
          <Box
            height="1.3rem"
            width="0.2rem"
            bgcolor="white"
            position="absolute"
            left={0}
            sx={{
              borderBottomRightRadius: "0.25rem",
              borderTopRightRadius: "0.25rem",
            }}
          />{" "}
        </Match>
        <Match when={!!menu.route}>
          <MenuItemButton
            type="button"
            sx={{
              width: "100%",
              height: "2rem",
              paddingLeft: isCollapsed ? undefined : "1rem",
              justifyContent: isCollapsed ? "center" : "flex-start",
              alignItems: isCollapsed ? "center" : undefined,
              background: isSelected ? Colors.BACKGROUND_DARK : undefined,
              padding: 0,
              ":hover": {
                color: "white",
                background: isSelected ? Colors.BACKGROUND_DARK : undefined,
                border: border,
              },
            }}
          >
            <NavLink
              reloadDocument={false}
              to={menu.route!}
              style={{
                height: "100%",
                width: "100%",
                textDecoration: "none",
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                height="100%"
                width="100%"
                columnGap="9px"
                sx={{
                  justifyContent: isCollapsed ? "center" : "flex-start",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: isCollapsed ? "center" : "flex-start",
                    width: "100%",
                    gap: "9px",
                  }}
                >
                  <Typography
                    color={isSelected ? "white" : Colors.LIGHT3}
                    sx={{
                      display: "flex",
                      flexWrap: "nowrap",
                      textSizeAdjust: "none",
                      paddingLeft: isCollapsed ? "0px" : "9px",
                    }}
                  >
                    {menu.icon}
                  </Typography>

                  <Match when={!isCollapsed}>
                    <Typography
                      fontWeight={600}
                      fontSize="14px"
                      textTransform="none"
                      color={isSelected ? "white" : Colors.LIGHT3}
                    >
                      {menu.label}
                    </Typography>
                  </Match>
                </div>
                {badge ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    {badge}
                  </div>
                ) : null}
              </Stack>
            </NavLink>
          </MenuItemButton>
        </Match>
        <Match when={!menu.route}>
          <MenuItemButton
            type="button"
            onClick={() => setMenuUser(!menuUser)}
            sx={{
              width: isCollapsed ? "100%" : "100%",
              height: "2rem",
              paddingLeft: isCollapsed ? undefined : "1rem",
              justifyContent: isCollapsed ? "flex-start" : "center",
              alignItems: "center",
              background: isSelected ? Colors.BACKGROUND_DARK : undefined,
              padding: 0,
              ":hover": {
                color: isSelected ? "white" : "white",
                background: isSelected ? Colors.BACKGROUND_DARK : undefined,
                border: border,
              },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              height="100%"
              width="100%"
              columnGap="9px"
              sx={{
                justifyContent: isCollapsed ? "flex-start" : "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: !isCollapsed ? "flex-start" : "center",
                  width: "100%",
                  paddingLeft: isCollapsed ? "0px" : "9px",
                  gap: "9px",
                }}
              >
                {menu.icon}

                <Match when={!isCollapsed}>
                  <Typography
                    fontWeight={600}
                    fontSize="14px"
                    textTransform="none"
                    color={isSelected ? "white" : Colors.LIGHT3}
                  >
                    {menu?.label}
                  </Typography>
                </Match>
              </div>
              {badge ? badge : null}
              <Match when={!isCollapsed}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <IconDots color={isSelected ? "white" : Colors.LIGHT3} />
                </div>
              </Match>
            </Stack>
          </MenuItemButton>
        </Match>
      </Stack>
      {!menu.route && isSelected && menu.submenus && (
        <Match when={isSelected && !isCollapsed && !!menu.submenus}>
          <Stack marginLeft="1.5rem" borderRadius="8px">
            <For each={menu.submenus!}>
              {(item, index) => (
                <SubMenuItemButton
                  key={`sidebar-desktop-submenu-${index}`}
                  sx={{
                    ":hover": {
                      color: "white",
                      border: border,
                    },
                  }}
                >
                  <NavLink
                    reloadDocument={false}
                    to={item.route}
                    style={{
                      height: "100%",
                      width: "100%",
                      textDecoration: "none",
                    }}
                  >
                    <Stack
                      direction="row"
                      alignItems="center"
                      height="100%"
                      width="100%"
                      columnGap="9px"
                    >
                      <Typography
                        color={
                          location.pathname === item.route
                            ? "white"
                            : Colors.LIGHT3
                        }
                        sx={{
                          display: "flex",
                          flexWrap: "nowrap",
                          textSizeAdjust: "none",
                        }}
                      >
                        {item.icon}
                      </Typography>
                      <Typography
                        fontWeight={600}
                        fontSize="14px"
                        textTransform="none"
                        color={
                          location.pathname === item.route
                            ? "white"
                            : Colors.LIGHT3
                        }
                      >
                        {item.label}
                      </Typography>
                    </Stack>
                  </NavLink>
                </SubMenuItemButton>
              )}
            </For>
          </Stack>
        </Match>
      )}

      <UserMenu menuOpen={menuUser} onClose={() => setMenuUser(!menuUser)} />
    </Stack>
  );
}

export default MenuItem;

interface UserMenuProps {
  menuOpen: boolean;
  onClose?: () => void;
}

function UserMenu(props: UserMenuProps) {
  const { t } = useTranslation("main", {
    keyPrefix: "account",
  });
  const navigate = useNavigate();
  const { isCollapsed } = useSidebarDesktop();

  const handleClose = () => {
    props.onClose && props.onClose();
  };

  async function handleSignOut() {
    await signOut();
    clearLocalStorage();
    navigate("/signin");
  }

  return (
    <MenuMUI
      id="menu-appbar"
      anchorEl={props.menuOpen ? document.body : undefined}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        marginLeft: isCollapsed ? "48px" : "12rem",
        width: "100%",
        "& .MuiPaper-root": {
          width: "272px",
          height: "auto",
          borderRadius: "16px",
          boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          padding: "0px",
          paddingTop: "8px",
          paddingBottom: "8px",
        },
        "& .MuiMenu-list": {
          padding: "0px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
        },
      }}
      open={props.menuOpen}
      onClose={handleClose}
    >
      <MenuItemMUI
        onClick={() => {
          navigate("/profile");
          props.onClose && props.onClose();
        }}
        sx={{
          width: "95%",
          ":hover": {
            backgroundColor: Colors.BACKGROUND,
            borderRadius: "8px",
          },
        }}
      >
        <ListItemIcon>
          <UserCircled
            style={{
              color: Colors.DEFAULT,
              width: "16px",
              height: "16px",
            }}
          />
        </ListItemIcon>
        <Typography
          sx={{
            fontWeight: "600",
            font: "14px/19.12px Manrope",
            color: Colors.DEFAULT,
          }}
        >
          {t("account")}
        </Typography>
      </MenuItemMUI>
      <MenuItemMUI
        onClick={handleSignOut}
        sx={{
          width: "95%",
          ":hover": {
            backgroundColor: Colors.BACKGROUND,
            borderRadius: "8px",
          },
        }}
      >
        <ListItemIcon>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.33333 5.33268V3.99935C9.33333 3.64573 9.19286 3.30659 8.94281 3.05654C8.69276 2.80649 8.35362 2.66602 8 2.66602H3.33333C2.97971 2.66602 2.64057 2.80649 2.39052 3.05654C2.14048 3.30659 2 3.64573 2 3.99935V11.9993C2 12.353 2.14048 12.6921 2.39052 12.9422C2.64057 13.1922 2.97971 13.3327 3.33333 13.3327H8C8.35362 13.3327 8.69276 13.1922 8.94281 12.9422C9.19286 12.6921 9.33333 12.353 9.33333 11.9993V10.666M6 7.99935H14M14 7.99935L12 5.99935M14 7.99935L12 9.99935"
              stroke={Colors.RED}
              strokeWidth="1.25"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </ListItemIcon>
        <Typography
          sx={{
            fontWeight: "600",
            font: "14px/19.12px Manrope",
            color: Colors.RED,
          }}
        >
          {t("logOut")}
        </Typography>
      </MenuItemMUI>
    </MenuMUI>
  );
}
