import { Button, styled } from "@mui/material";

export const MenuItemButton = styled(Button)`
  background: transparent;
  min-width: 0;
  border-radius: 0.5rem;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 9px;
  color: rgba(15, 16, 22, 1);
`;

export const SubMenuItemButton = styled(Button)`
  background: transparent;
  min-width: 0;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 9px;
  color: rgba(15, 16, 22, 1);
`;
