import React from "react";
import {
  Company,
  CompanyInfo,
  CompanyMember,
  CustomField,
  Role,
  StatusStage,
  Tag,
  UpdateCompanyInfoInput,
  UpdateCompanyInput,
} from "../../gql/graphql";
import { CognitoUserContext } from "./cognito-user";
import { graphql } from "../../gql";

export type CompanyFirstContextValue = {
  companyId: string;
  setCompanyId: (companyId: string | undefined) => void;
  companies?: Map<string, Company>;
  company?: Company;
  setCompany: (input: UpdateCompanyInput) => void;
  updateCompanyImage: (file: File) => void;
  isUpdatingCompanyImage: boolean;
};

export const GET_GLOBAL_TASK_STATS = graphql(`
  query GlobalTasksStats(
    $scope: ScopeInput!
    $filters: TaskFilterInput
    $type: TaskStatsType!
  ) {
    globalTasksStats(scope: $scope, filters: $filters, type: $type) {
      count
      overdue_count
    }
  }
`);
export type CompanySecondContextValue = {
  items: {
    statusStages?: Map<string, StatusStage>;
    statusStagesByName?: Map<string, StatusStage>;
    statusStagesOpenIds?: Array<string>;
    customFields?: Map<string, CustomField>;
    roles?: Map<string, Role>;
    rolesByName?: Map<string, Role>;
    tags?: Map<string, Tag>;
    companyInfo?: CompanyInfo | null;
    companyMembers?: Map<string, CompanyMember>;
  };
  setCompanyInfo: (input: UpdateCompanyInfoInput) => void;
  refetchItems: () => Promise<void>;
  globalTasksStats?: {
    count: number;
    overdue_count: number;
  };
  refetchGlobalTasksStats: () => void;
};

export const CompanyFirstContext = React.createContext<
  CompanyFirstContextValue | undefined
>(undefined);

export const CompanySecondContext = React.createContext<
  CompanySecondContextValue | undefined
>(undefined);

export const useCompanyStaticId = () => {
  const user = React.useContext(CognitoUserContext);
  const userId = user?.cognitoUser["custom:id"];
  const key = userId ? `companyId:${userId}` : undefined;
  const [companyId, setCompanyIdState] = React.useState<string | undefined>(
    localStorage.getItem(key || "") || undefined,
  );
  const setCompanyId = React.useCallback(
    (companyId: string | undefined) => {
      if (!key) return;
      setCompanyIdState(companyId);
      if (!companyId) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, companyId);
      }
    },
    [key],
  );
  return {
    companyId,
    setCompanyId,
    user,
  };
};

export const useCompany = (): CompanyFirstContextValue => {
  const company = React.useContext(CompanyFirstContext);
  if (!company) {
    throw new Error("useCompany must be used within a CompanyProvider");
  }
  return company;
};

export const useCompanyItems = (): CompanySecondContextValue => {
  const company = React.useContext(CompanySecondContext);
  if (!company) {
    throw new Error("useCompany must be used within a CompanyProvider");
  }
  return company;
};

export default useCompany;
