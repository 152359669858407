import { IconCommand } from "@tabler/icons-react";
import { useMemo } from "react";
import Colors from "../utils/ColorConstants";
import { Box, Typography } from "@mui/material";

export const useIsMac = () => {
  const isMac = useMemo(
    () => navigator.userAgent.indexOf("Mac OS X") != -1,
    [],
  );
  return isMac;
};

export const useControlKey = (color: string = Colors.LIGHT) => {
  const isMac = useIsMac();
  const iconControl = useMemo(
    () =>
      isMac ? (
        <Box
          sx={{
            width: "0.5rem",
            height: "0.75rem",
            svg: {
              strokeWidth: 2,
            },
          }}
        >
          <IconCommand
            style={{
              marginLeft: "-0.125rem",
            }}
            size="12px"
            color={color}
          />
        </Box>
      ) : (
        <Typography
          sx={{
            color,
            fontSize: "10px",
            fontWeight: 700,
            letterSpacing: "0.1em",
            lineHeight: "1rem",
            textAlign: "center",
          }}
        >
          CTRL
        </Typography>
      ),
    [color, isMac],
  );

  return { iconControl };
};
