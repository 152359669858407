import i18next from "i18next";
import en from "./translations/en.json";
import pt from "./translations/pt.json";
import es from "./translations/es.json";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const i18resources = {
  en: {
    main: en,
  },
  pt: {
    main: pt,
  },
  es: {
    main: es,
  },
} as const;

i18next
  .use(initReactI18next)
  .use(XHR)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },
    fallbackLng: "en",
    supportedLngs: ["en", "es"],
    defaultNS: "main",
    resources: i18resources,
  });

const i18n = i18next;
export default i18n;
