import SidaberV2 from "../SidebarV2";
import { ApplicationLayoutContainer, ApplicationLayoutContent } from "./styles";
import { Outlet } from "react-router-dom";

export function ApplicationLayout() {
  return (
    <ApplicationLayoutContainer>
      <ApplicationLayoutContent>
        <SidaberV2 />
        <div
          style={{
            width: "100%",
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <Outlet />{" "}
        </div>
      </ApplicationLayoutContent>
    </ApplicationLayoutContainer>
  );
}
