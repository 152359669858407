import { Skeleton } from "@mui/material";
import { useSidebarDesktop } from "../../../context/SidebarDesktopContext";
import { useCompanyItems } from "../../../guards/contexts/company";
import { TasksBadgeContainer, TasksBadgeItem } from "./styles";

export const TasksBadge = () => {
  const { globalTasksStats } = useCompanyItems();
  const { isCollapsed } = useSidebarDesktop();

  if (isCollapsed) return null;

  return (
    <TasksBadgeContainer>
      {
        <TasksBadgeItem
          style={{
            background: "#181E26",
          }}
        >
          {globalTasksStats ? (
            globalTasksStats.count
          ) : (
            <Skeleton width="0.5rem" />
          )}
        </TasksBadgeItem>
      }
      {
        <TasksBadgeItem
          style={{
            background: "#FF006D",
          }}
        >
          {globalTasksStats ? (
            globalTasksStats.overdue_count
          ) : (
            <Skeleton width="0.5rem" />
          )}
        </TasksBadgeItem>
      }
    </TasksBadgeContainer>
  );
};
