import { graphql } from "../../../../gql";

export const SEARCH_QUERY = graphql(`
  query Search(
    $filter: SearchFilter!
    $scope: ScopeInput!
    $imageSize: ImageSize
  ) {
    searchInCompany(filter: $filter, scope: $scope, image_size: $imageSize) {
      project_children {
        company_id
        task_id
        parent_task_id
        parent_type
        owner_id
        created_at
        updated_at
        deleted_at
        type
        name
        description
        due_date
        start_date
        status_stage_id
        priority
        code_number
        has_thumbnail
        fields
        archived_at
        scopes {
          Owner
          Company
          Project
          SKU
          Part
          Task
        }
        presigned_url
        put_presigned_url
      }
      sku_children {
        company_id
        task_id
        parent_task_id
        parent_type
        owner_id
        created_at
        updated_at
        deleted_at
        type
        name
        description
        due_date
        start_date
        status_stage_id
        priority
        code_number
        has_thumbnail
        fields
        archived_at
        scopes {
          Owner
          Company
          Project
          SKU
          Part
          Task
        }
        presigned_url
        put_presigned_url
      }
      users {
        user_id
        email
        first_name
        last_name
        user_role_id
        phone_number
        created_at
        updated_at
        has_profile_picture
        presigned_url
        put_presigned_url
      }
      parts {
        company_id
        task_id
        parent_task_id
        parent_type
        owner_id
        created_at
        updated_at
        deleted_at
        type
        name
        description
        due_date
        start_date
        status_stage_id
        priority
        code_number
        has_thumbnail
        fields
        archived_at
        scopes {
          Owner
          Company
          Project
          SKU
          Part
          Task
        }
        presigned_url
        put_presigned_url
      }
      projects {
        company_id
        task_id
        parent_task_id
        parent_type
        owner_id
        created_at
        updated_at
        deleted_at
        type
        name
        description
        due_date
        start_date
        status_stage_id
        priority
        code_number
        has_thumbnail
        fields
        archived_at
        scopes {
          Owner
          Company
          Project
          SKU
          Part
          Task
        }
        presigned_url
        put_presigned_url
      }
      skus {
        company_id
        task_id
        parent_task_id
        parent_type
        owner_id
        created_at
        updated_at
        deleted_at
        type
        name
        description
        due_date
        start_date
        status_stage_id
        priority
        code_number
        has_thumbnail
        fields
        archived_at
        scopes {
          Owner
          Company
          Project
          SKU
          Part
          Task
        }
        presigned_url
        put_presigned_url
      }
      tasks {
        company_id
        task_id
        parent_task_id
        parent_type
        owner_id
        created_at
        updated_at
        deleted_at
        type
        name
        description
        due_date
        start_date
        status_stage_id
        priority
        code_number
        has_thumbnail
        fields
        archived_at
        scopes {
          Owner
          Company
          Project
          SKU
          Part
          Task
        }
        presigned_url
        put_presigned_url
      }
    }
  }
`);
