import { SvgIcon } from "@mui/material";
import { CustomIconProps } from ".";

export function HistoryIcon(props: CustomIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="history">
          <path
            id="Vector"
            d="M8 5.33333V8L9.33333 9.33333M2.0332 7.33338C2.1826 5.86675 2.86671 4.50645 3.95503 3.51202C5.04334 2.51759 6.45967 1.95864 7.93379 1.9418C9.40791 1.92496 10.8366 2.45142 11.9474 3.42074C13.0581 4.39005 13.7731 5.73436 13.956 7.1972C14.1388 8.66003 13.7767 10.139 12.9388 11.3519C12.1008 12.5648 10.8456 13.4267 9.41272 13.7733C7.97981 14.1198 6.46948 13.9267 5.16987 13.2308C3.87026 12.5348 2.87235 11.3848 2.36654 10M2.0332 13.3334V10H5.36654"
            stroke="currentColor"
            style={{
              stroke: "currentColor",
              //   stroke: "color(currentColor),stroke-opacity:1",
            }}
          />
        </g>
      </svg>
    </SvgIcon>
  );
}
