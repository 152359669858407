import { styled } from "@mui/material";

export const ContainerScrollable = styled("div")<{
  direction: "row" | "row-reverse" | "column" | "column-reverse";
}>`
  display: flex;
  flex-direction: ${(props) => props.direction};
  align-items: flex-start;
  flex: 1 0 0;
  overflow: auto;
  padding: 0 !important;
  margin-right: -0.4rem;
`;
