import { SvgIcon } from "@mui/material";
import { CustomIconProps } from "..";

export function Warning(props: CustomIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M6.82635 2.63789L1.21169 12.0112C1.096 12.2117 1.03481 12.4389 1.03418 12.6704C1.03356 12.9019 1.09353 13.1294 1.20813 13.3305C1.32273 13.5316 1.48797 13.6992 1.68742 13.8166C1.88687 13.9341 2.11358 13.9972 2.34502 13.9999H13.575C13.8065 13.9972 14.0332 13.9341 14.2326 13.8166C14.4321 13.6992 14.5973 13.5316 14.7119 13.3305C14.8265 13.1294 14.8865 12.9019 14.8859 12.6704C14.8852 12.4389 14.824 12.2117 14.7084 12.0112L9.09302 2.63789C8.97478 2.44322 8.80839 2.28228 8.60989 2.1706C8.41138 2.05891 8.18745 2.00024 7.95969 2.00024C7.73192 2.00024 7.50799 2.05891 7.30949 2.1706C7.11098 2.28228 6.94459 2.44322 6.82635 2.63789Z"
          fill="#FFFAF5"
        />
        <path d="M7.99967 5.99992V8.66659V5.99992Z" fill="#FFFAF5" />
        <path d="M7.99967 11.3333H8.00634H7.99967Z" fill="#FFFAF5" />
        <path
          d="M7.99967 5.99992V8.66659M7.99967 11.3333H8.00634M6.82635 2.63789L1.21169 12.0112C1.096 12.2117 1.03481 12.4389 1.03418 12.6704C1.03356 12.9019 1.09353 13.1294 1.20813 13.3305C1.32273 13.5316 1.48797 13.6992 1.68742 13.8166C1.88687 13.9341 2.11358 13.9972 2.34502 13.9999H13.575C13.8065 13.9972 14.0332 13.9341 14.2326 13.8166C14.4321 13.6992 14.5973 13.5316 14.7119 13.3305C14.8265 13.1294 14.8865 12.9019 14.8859 12.6704C14.8852 12.4389 14.824 12.2117 14.7084 12.0112L9.09302 2.63789C8.97478 2.44322 8.80839 2.28228 8.60989 2.1706C8.41138 2.05891 8.18745 2.00024 7.95969 2.00024C7.73192 2.00024 7.50799 2.05891 7.30949 2.1706C7.11098 2.28228 6.94459 2.44322 6.82635 2.63789Z"
          stroke="#C26800"
          stroke-width="1.25"
          strokeLinecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
