import LogRocket from "logrocket";
import { useEffect } from "react";
import useUser from "../guards/contexts/user";
import useCompany from "../guards/contexts/company";

export const useLogRocket = () => {
  const { user } = useUser();
  const { company } = useCompany();

  useEffect(() => {
    if (user && company) {
      LogRocket.identify(user.user_id, {
        name: `${user.first_name} ${user.last_name}`,
        company_id: company.company_id,
        company: company.name,
      });
    }
  }, [company, user]);
};
