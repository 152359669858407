import { styled } from "@mui/material";

export const ContainerTitleRow = styled("div")`
  display: flex;
  height: 3.5rem;
  min-height: 56px;
  padding: 0.75rem;
  align-items: center;
  gap: 1rem;
  align-self: stretch;
`;
